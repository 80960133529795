import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  ItemsCenterJustifyBtw,
  PageTitle,
} from "../style";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import {
  changeStudentLevel,
  getStudentsLevel,
} from "../../../Apis/Private/students";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { levelOptions } from "../Hostels/util";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import { toast } from "react-toastify";

const ChangeStudentLevel = () => {
  const {
    matricNumber,
    gettingStudentLevel,
    studentCurrentLevel,
    studentCurrentSeesion,
    studentLevel,
    changingStudentLevel,
  } = useAppSelector(selectStudents);
  const { sessions } = useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleGetStudentLevel = () => {
    if (matricNumber) {
      getStudentsLevel(matricNumber);
    } else {
      toast.error("Please enter the matric number");
    }
  };
  const handleChangeStudentLevel = () => {
    changeStudentLevel(
      studentLevel?.studentId,
      studentCurrentSeesion || studentLevel?.sessionId,
      studentCurrentLevel || studentLevel?.levelId
    );
  };
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  return (
    <>
      {" "}
      <PageTitle>Set Student Level</PageTitle>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                alignText="left"
                placeholder="Enter Student Reg. No./Matric. No."
                name="matricNumber"
                value={matricNumber}
                onChangeHandler={handleOnChange}
              />
            </ItemsCenterJustifyBtw>{" "}
            <BigButton
              buttonText="Fetch Student"
              loading={gettingStudentLevel}
              handleClick={handleGetStudentLevel}
            />
          </Form>
          {studentLevel && (
            <Form>
              <ItemsCenterJustifyBtw>
                <TextCenteredInput
                  alignText="left"
                  placeholder="Student Name"
                  // name="studentId"
                  value={
                    studentLevel?.student?.person?.firstName +
                    " " +
                    studentLevel?.student?.person?.lastName +
                    " " +
                    studentLevel?.student?.person?.otherName
                  }
                  onChangeHandler={handleOnChange}
                />
                <UnderlinedSelector
                  title={"Student Current Level"}
                  dropdownItems={levelOptions}
                  handleDropdownSelect={handleSelect}
                  name="studentCurrentLevel"
                  value={studentCurrentLevel || studentLevel?.levelId}
                />
                <UnderlinedSelector
                  dropdownItems={sessionFilter}
                  handleDropdownSelect={handleSelect}
                  name="studentCurrentSeesion"
                  value={studentCurrentSeesion || studentLevel?.sessionId}
                  title={"Student Current Session"}
                />
              </ItemsCenterJustifyBtw>
              <BigButton
                buttonText="Update"
                loading={changingStudentLevel}
                handleClick={handleChangeStudentLevel}
              />
            </Form>
          )}
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default ChangeStudentLevel;
