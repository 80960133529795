import { filter } from "../../Pages/PrivatePages/util";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../Redux/features/studentsSlice";
import { selectUtil } from "../../Redux/features/utilsSlice";
import CustomTable2 from "../CustomTable2";
import { StudentRows, courseRegCols } from "./utils";

const CourseRegTable = () => {
  const { allStudents, gettingAllStudents } = useAppSelector(selectStudents);
  const { sessions } = useAppSelector(selectUtil);

  const sessionOptions = filter(sessions, "sessionName", "sessionId");

  const dispatch = useAppDispatch();
  const rows = StudentRows(allStudents, sessionOptions);

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: courseRegCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingAllStudents}
      noRecord={allStudents?.records?.length === 0 || !allStudents}
      page={allStudents?.currentPage}
      pageCount={allStudents?.totalRecords}
      pageSize={allStudents?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default CourseRegTable;
