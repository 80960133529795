import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import { selectUtil } from "../../Redux/features/utilsSlice";
import CustomTable2 from "../CustomTable2";
import { hostelSessionsCols, hostelSessionsRows } from "./utils";

const HostelSessionTable = () => {
  const { gettingHostelBySession, hostelsBySession, hostelStatus } =
    useAppSelector(selectHostel);
  const { departments } = useAppSelector(selectUtil);
  const dispatch = useAppDispatch();
  const rows = hostelSessionsRows(hostelsBySession, departments);

  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: hostelSessionsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingHostelBySession}
      noRecord={hostelsBySession?.length === 0}
      page={hostelsBySession?.currentPage}
      pageCount={hostelsBySession?.totalRecords}
      pageSize={hostelsBySession?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default HostelSessionTable;
