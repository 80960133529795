import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import {
  resetPaymentParams,
  setAllPayment,
  setAllStudentsPayment,
  setConfirmingPayment,
  setCreatingFeeMapping,
  setCreatingFeePayment,
  setDownloadablePayment,
  setFeeMappings,
  setFeePaymentSummary,
  setGettingAllPayment,
  setGettingAllStudentsPayment,
  setGettingDownloadablePayment,
  setGettingFeeMappings,
  setGettingFeePaymentSummary,
  setGettingPaymentById,
  setGettingPaymentFees,
  setGettingPaymentSummary,
  setGettingPaymentSummaryChart,
  setGettingStudentInfo,
  setGettingStudentsPaymentInASemester,
  setGettingStudentsPaymentInASession,
  setPaymentById,
  setPaymentFees,
  setPaymentSummary,
  setPaymentSummaryChart,
  setStudentInfo,
  setStudentsPaymentInASemester,
  setStudentsPaymentInASession,
  setUpdatingFeePayment,
  setUploadingOfflinePayment,
} from "../../Redux/features/paymentSlice";

export const getStudentsPaymentsInASession = async (
  personId: number,
  SessionId: number
) => {
  store.dispatch(setGettingStudentsPaymentInASession(true));
  const params: any = {
    personId,
    SessionId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetStudentPaymentInASession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingStudentsPaymentInASession(false));
      store.dispatch(setStudentsPaymentInASession(data?.response));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsPaymentInASession(false));
    });
};

export const getStudentsPaymentsInASemester = async (
  personId: number,
  SessionId: number,
  SemesterId: number
) => {
  store.dispatch(setGettingStudentsPaymentInASemester(false));

  const params: any = {
    personId,
    SessionId,
    SemesterId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetStudentPaymentInASemester", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingStudentsPaymentInASemester(false));
      store.dispatch(setStudentsPaymentInASemester(data?.response));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsPaymentInASemester(false));
    });
};

export const getPayments = async (
  SessionId: number | null,
  SemesterId: number | null,
  FeeScheduleId: number | null,
  PaymentMeans: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  LevelId: number | null,
  ReportName: string | null,
  StartDate: string | null,
  EndDate: string | null,
  GenderId: number | null,
  AsPdf: boolean,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingAllPayment(true));
  const params: any = {
    SessionId,
    SemesterId,
    FeeScheduleId,
    PaymentMeans,
    FacultyId,
    DepartmentId,
    LevelId,
    ReportName,
    StartDate,
    EndDate,
    GenderId,
    AsPdf,
    PageSize,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPayments", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingAllPayment(false));
      store.dispatch(setAllPayment(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllPayment(false));
    });
};

export const getPaymentsDownloadable = async (
  SessionId: number,
  SemesterId: number,
  FeeScheduledId: number,
  PaymentMeans: number,
  FacultyId: number,
  DepartmentId: number,
  LevelId: number,
  ReportName: string,
  StartDate: string,
  EndDate: string,
  GenderId: number,
  AsPdf: boolean,
  PageSize: number
) => {
  store.dispatch(setGettingDownloadablePayment(true));
  const params: any = {
    SessionId,
    SemesterId,
    FeeScheduledId,
    PaymentMeans,
    FacultyId,
    DepartmentId,
    LevelId,
    ReportName,
    StartDate,
    EndDate,
    GenderId,
    AsPdf,
    PageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPayments/downloadable", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setDownloadablePayment(data?.response));
      store.dispatch(setGettingDownloadablePayment(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingDownloadablePayment(false));
    });
};

export const getFeePaymentsSummary = async (
  SessionId: number,
  SemesterId: number,
  FeeScheduledId: number,
  PaymentMeans: number
) => {
  store.dispatch(setGettingFeePaymentSummary(true));
  const params: any = {
    SessionId,
    SemesterId,
    FeeScheduledId,
    PaymentMeans,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetFeePaymentSummary", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setFeePaymentSummary(data?.response));
      store.dispatch(setGettingFeePaymentSummary(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getPaymentsSummary = async (
  SessionId: number | null,
  SemesterId: number | null
) => {
  store.dispatch(setGettingPaymentSummary(true));
  const params: any = {
    SessionId,
    SemesterId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPaymentSummary", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentSummary(data));
      store.dispatch(setGettingPaymentSummary(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentSummary(false));
    });
};

export const getFeePaymentSummary = async (
  SessionId: number | null,
  SemesterId: number | null,
  FeeScheduleId: number | null,
  PaymentMeansId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  LevelId: number | null,
  ReportName: string | null,
  StartDate: string | null,
  EndDate: string | null,
  GenderId: number | null,
  AsPdf: boolean,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingPaymentSummary(true));
  const params: any = {
    SessionId,
    SemesterId,
    FeeScheduleId,
    PaymentMeansId,
    FacultyId,
    DepartmentId,
    LevelId,
    ReportName,
    StartDate,
    EndDate,
    GenderId,
    AsPdf,
    PageSize,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetFeePaymentSummary", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentSummary(data));
      store.dispatch(setGettingPaymentSummary(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentSummary(false));
    });
};

export const getPaymentsSummaryChart = async (
  SessionId: number,
  SemesterId: number
) => {
  store.dispatch(setGettingPaymentSummaryChart(true));
  const params: any = {
    SessionId,
    SemesterId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPaymentSummaryChart", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentSummaryChart(data?.response));
      store.dispatch(setGettingPaymentSummaryChart(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentSummaryChart(false));
    });
};

export const getPaymentById = async (PaymentId: number) => {
  store.dispatch(setGettingPaymentById(true));
  await api
    .get(`/Reports/GetPaymentById/${PaymentId}`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentById(data));
      store.dispatch(setGettingPaymentById(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentById(false));
    });
};

export const getAllStudentsPayment = async (personId: number) => {
  store.dispatch(setGettingAllStudentsPayment(true));
  await api
    .get(`/Reports/GetAllStudentPayment/${personId}`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllStudentsPayment(data));
      store.dispatch(setGettingAllStudentsPayment(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllStudentsPayment(false));
    });
};

export const confirmPayment = async (
  reference: string | null,
  gateway: number | null
) => {
  store.dispatch(setConfirmingPayment(true));
  const params = {
    reference,
    gateway,
  };
  await api
    .get("/Payments/UpdatePayment", { params })
    .then((res) => {
      store.dispatch(setConfirmingPayment(false));
      toast.success(res?.data?.message);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingPayment(false));
    });
};

export const getStudentsInformation = async (
  regNumber: string | null,
  sessionId: number | null,
  semesterId: number | null,
  feeScheduleId: number | null
) => {
  const params: any = {
    regNumber,
    sessionId,
    feeScheduleId,
    semesterId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingStudentInfo(true));
  await api
    .get(`/Payments/GetStudentInformation`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentInfo(data));
      store.dispatch(setGettingStudentInfo(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentInfo(false));
    });
};

export const uploadOfflinePayment = async (
  feeDetailsId: number | null,
  personId: number | null,
  bankId: number | null,
  tellerNumber: string | null,
  accountNo: string | null,
  description: string | null
) => {
  const body: any = {
    feeDetailsId,
    personId,
    bankId,
    tellerNumber,
    description,
    accountNo,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  store.dispatch(setUploadingOfflinePayment(true));
  await api
    .post(`/Payments/OfflineBank`, body)
    .then((res) => {
      store.dispatch(setUploadingOfflinePayment(false));
      toast.success("payment uploaded successfully");
      store.dispatch(resetPaymentParams())
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUploadingOfflinePayment(false));
    });
};

export const getPaymentFees = async () => {
  store.dispatch(setGettingPaymentFees(true));
  await api
    .get(`/Payments/Fee`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentFees(data));
      store.dispatch(setGettingPaymentFees(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentFees(false));
    });
};

export const createPaymentFee = async (
  feeScheduleName: string | null,
  canPayInstallment: boolean | null
) => {
  const body: any = {
    feeScheduleName,
    canPayInstallment,
  };
  store.dispatch(setCreatingFeePayment(true));
  await api
    .post(`/Payments/Fee/Add`, body)
    .then((res) => {
      store.dispatch(setCreatingFeePayment(false));
      toast.success("Fee added successfully");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingFeePayment(false));
    });
};

export const editPaymentFee = async (
  id: number,
  feeScheduleName: string | null,
  canPayInstallment: boolean | null
) => {
  const body: any = {
    id,
    feeScheduleName,
    canPayInstallment,
  };
  store.dispatch(setUpdatingFeePayment(true));
  await api
    .patch(`/Payments/Fee/Edit`, body)
    .then((res) => {
      store.dispatch(setUpdatingFeePayment(false));
      toast.success("Fee edited successfully");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingFeePayment(false));
    });
};

export const getFeeMappings = async (
  SessionId: number | null,
  SemesterId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  LevelId: number | null,
  SearchPrams: string | null,
  PageNumber: number | null,
  PageSize: number | null
) => {
  const params: any = {
    SessionId,
    SemesterId,
    FacultyId,
    DepartmentId,
    LevelId,
    SearchPrams,
    PageNumber,
    PageSize,
  };
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  store.dispatch(setGettingFeeMappings(true));
  await api
    .get(`/Payments/Fee/Mappings`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setFeeMappings(data));
      store.dispatch(setGettingFeeMappings(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingFeeMappings(false));
    });
};

export const createFeeMappings = async (
  feeScheduleId: number | null,
  paymentInstallmentId: number | null,
  programmeId: number | null,
  departmentIds: any[] | null,
  levelId: number | null,
  sessionId: number | null,
  genderId: number | null,
  semesterId: number | null,
  admisionType: number | null,
  amount: number | null
) => {
  const body: any = {
    feeInfo: {
      feeScheduleId,
      paymentInstallmentId,
      programmeId,
      departments: departmentIds?.map((p) => p.value),
      levelId,
      sessionId,
      genderId,
      semesterId,
      admisionType,
    },
    amount,
  };
  store.dispatch(setCreatingFeeMapping(true));
  await api
    .post(`/Payments/Fee/Mappings`, body)
    .then(() => {
      store.dispatch(setCreatingFeeMapping(false));
      toast.success("Fee mapping created successfully");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingFeeMapping(false));
    });
};

export const getPaymentsDownload = async (
  ReportName: string | null,
  SessionId: number | null,
  SemesterId: number | null,
  FeeScheduleId: number | null,
  PaymentMeans: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  LevelId: number | null,
  GenderId: number | null,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingDownloadablePayment(true));
  const params: any = {
    ReportName,
    DepartmentId,
    FacultyId,
    PageSize,
    SessionId,
    PageNumber,
    FeeScheduleId,
    LevelId,
    SemesterId,
    PaymentMeans,
    GenderId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPayments/downloadable", { params })
    .then((res) => {
      toast.success("Report generated");
      store.dispatch(setGettingDownloadablePayment(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingDownloadablePayment(false));
    });
};
