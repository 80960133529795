import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import navReducer from "../features/navSlice";
import userReducer from "../features/userSlice";
import studentsReducer from "../features/studentsSlice";
import paymentReducer from "../features/paymentSlice";
import hostelReducer from "../features/hostelSlice";
import reportReducer from "../features/reportSlice";
import utilsReducer from "../features/utilsSlice";
import applicationFormReducer from "../features/Application/ApplicationFormSlice";
import applicationUtilReducer from "../features/Application/utilSlice";
import admissionReducer from "../features/admissionSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Root reducer
const rootReducer = combineReducers({
  applicationForm: applicationFormReducer,
  nav: navReducer,
  user: userReducer,
  students: studentsReducer,
  payment: paymentReducer,
  hostel: hostelReducer,
  report: reportReducer,
  utils: utilsReducer,
  applicationUtil: applicationUtilReducer,
  admissions: admissionReducer,
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store configuration
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // If you need to handle non-serializable actions or state
    }),
});

// Persistor configuration
export const persistor = persistStore(store);

// Type definitions
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
