import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface utilState {
  gettingState: boolean;
  allState: object | null;
  allCountries: object | null;
  gettingLGA: boolean;
  allLGA: object | null;
  allReligion: object | null;
  allSubjects: object | null;
  allAdmissionType: object | null;
  allSubjectGrades: object | null;
  allExamTypes: object | null;
  allExamYears: object | null;
  departments: any;
  tribes: object | null;
  admissionTypes: object | null;
  faculties: any;

  // params states
  countryId: number | null;
}

const initialState: utilState = {
  gettingState: false,
  allState: null,
  allCountries: null,
  gettingLGA: false,
  allLGA: null,
  allReligion: null,
  allSubjects: null,
  allAdmissionType: null,
  allSubjectGrades: null,
  allExamTypes: null,
  allExamYears: null,
  departments: null,
  tribes: null,
  admissionTypes: null,
  faculties: null,

  // params states
  countryId: null,
};

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setGettingState: (state, action) => {
      state.gettingState = action.payload;
    },
    setGettingLGA: (state, action) => {
      state.gettingLGA = action.payload;
    },
    setExamYears: (state, action) => {
      state.allExamYears = action.payload;
    },
    setExamTypes: (state, action) => {
      state.allExamTypes = action.payload;
    },
    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setAllStates: (state, action) => {
      state.allState = action.payload;
    },
    setAllLGA: (state, action) => {
      state.allLGA = action.payload;
    },
    setAllReligion: (state, action) => {
      state.allReligion = action.payload;
    },
    setAllSubjects: (state, action) => {
      state.allSubjects = action.payload;
    },
    setAllAdmissionType: (state, action) => {
      state.allAdmissionType = action.payload;
    },
    setAllSubjectGrade: (state, action) => {
      state.allSubjectGrades = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setTribes: (state, action) => {
      state.tribes = action.payload;
    },
    setFaculties: (state, action) => {
      state.faculties = action.payload;
    },
    getUtilDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingLGA,
  setAllLGA,
  setGettingState,
  getUtilDetails,
  setAllStates,
  setAllAdmissionType,
  setAllReligion,
  setAllSubjectGrade,
  setAllSubjects,
  setExamTypes,
  setExamYears,
  setDepartments,
  setTribes,
  setFaculties,
  setAllCountries,
} = utilSlice.actions;
export const selectApplicationUtils = (state: RootState) => state.applicationUtil;

export default utilSlice.reducer;
