import { FiMoreVertical } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Dropdown, Popover, Whisper, Button } from "rsuite";
import styled from "styled-components";

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:hover {
    background: none;
  }
  svg {
    font-size: 16px;
    color: var(--clr-black);
  }
`;

const PopoverContainer = styled(Popover)`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--clr-white-100);
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(2px);

  .rs-dropdown-item {
    color: var(--clr-black);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: 16px;
  }
`;

const renderSpeaker = (
  { onClose, left, top, className, ...rest }: any,
  ref: any,
  studentId?: string
) => {
  return (
    <PopoverContainer
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu>
        <Dropdown.Item eventKey={1}>
          <Link
            to={`/admission/admitted-student/${studentId}`}
            style={{ color: `#000` }}
          >
            View
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </PopoverContainer>
  );
};

interface StudentMoreProps {
  studentId?: string;
}

const AdmittedStudentsMoreDropdown = ({ studentId }: StudentMoreProps) => {
  return (
    <Whisper
      placement="bottomStart"
      trigger="click"
      speaker={renderSpeaker(``, ``, studentId)}
    >
      <ButtonContainer>
        <span>
          <FiMoreVertical />
        </span>
      </ButtonContainer>
    </Whisper>
  );
};

export default AdmittedStudentsMoreDropdown;
