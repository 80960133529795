import React from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { CiUser } from "react-icons/ci";
import { BsCalendar2Date } from "react-icons/bs";
import { LuAtSign } from "react-icons/lu";
import { filter } from "./utils";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { sendApplicationForm } from "../../../Apis/Application/ApplicationForm";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import { IoLocationSharp } from "react-icons/io5";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";

const ApplicationForm = () => {
  const {
    firstName,
    lastName,
    otherName,
    emailAddress,
    contactAddress,
    state,
    localGovernment,
    mobileNumber,
    dateOfBirth,
    sendingApplicationForm,
    country,
  } = useAppSelector(selectapplicationForm);
  const { allState, allLGA, allCountries } = useAppSelector(
    selectApplicationUtils
  );
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };
  const countryFilter = filter(allCountries, "countryName", "countryId");
  const stateFilter = filter(allState, "stateName", "stateId");
  const localGovernmentFilter = filter(
    allLGA,
    "localGovernmentName",
    "localGovernmentId"
  );

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleProceed = () => {
    if (
      firstName &&
      lastName &&
      otherName &&
      contactAddress &&
      emailAddress &&
      mobileNumber &&
      dateOfBirth &&
      state &&
      localGovernment
    ) {
      sendApplicationForm(
        firstName,
        lastName,
        otherName,
        contactAddress,
        emailAddress,
        mobileNumber,
        dateOfBirth,
        state,
        localGovernment,
        country
      );
    } else {
      toast.info("fill all inputs");
    }
  };

  return (
    <AuthPageLayout authText="Godfrey Okoye University Admissions">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="FirstName"
            icon={<CiUser />}
            name="firstName"
            handleChange={handleOnChange}
            value={firstName}
          />
          <TextInputWithIcon
            type="text"
            placeholder="LastName"
            icon={<CiUser />}
            name="lastName"
            handleChange={handleOnChange}
            value={lastName}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Other Name"
            icon={<CiUser />}
            name="otherName"
            handleChange={handleOnChange}
            value={otherName}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="email"
            placeholder="Email Address"
            icon={<LuAtSign />}
            name="emailAddress"
            handleChange={handleOnChange}
            value={emailAddress}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Contact Address"
            icon={<IoLocationSharp />}
            name="contactAddress"
            handleChange={handleOnChange}
            value={contactAddress}
            characterLimit={48}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Phone Number"
            name="mobileNumber"
            handleChange={handleOnChange}
            value={mobileNumber}
            characterLimit={14}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <CustomRegularDropdown
            dropdownItems={countryFilter}
            title={"Country"}
            name="country"
            handleDropdownSelect={handleSelect}
            value={country}
          />
          <CustomRegularDropdown
            dropdownItems={stateFilter}
            title={"State"}
            name="state"
            handleDropdownSelect={handleSelect}
            value={state}
          />
          <CustomRegularDropdown
            dropdownItems={localGovernmentFilter}
            title={"Local Government"}
            name="localGovernment"
            handleDropdownSelect={handleSelect}
            value={localGovernment}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw>
          <TextInputWithIcon
            type="date"
            placeholder="date of birth"
            icon={<BsCalendar2Date />}
            name="dateOfBirth"
            handleChange={handleOnChange}
            value={dateOfBirth}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleProceed}
          loading={sendingApplicationForm}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default ApplicationForm;
