import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface HostelState {
  gettingStudentsHostelAllocation: boolean;
  studentsHostelAllocation: object | null | any;
  gettingHostelAllocation: boolean;
  hostelAllocation: object | null | any;
  checkingHostelValidity: boolean;
  hostelValidityData: object | null | any;
  gettingStudentsHostelBooking: boolean;
  studentsHostelBooking: object | null | any;
  gettingStudentsHostelBookingSummary: boolean;
  studentsHostelBookingSummary: object | null;
  // new hostels
  gettingHostels: boolean;
  hostels: object | null | any;
  creatingHostel: boolean;
  editingHostel: boolean;
  gettingHostelBySession: boolean;
  hostelsBySession: object | null | any;
  settingHostelSession: boolean;
  settingHostelSessionStatus: boolean;
  gettingHostelBlocks: boolean;
  hostelBlocks: object | null | any;
  gettingRoomBunks: boolean;
  roomBunks: object | null | any;
  creatingHostelBlock: boolean;
  gettingBunkSettings: boolean;
  updatingBunkSettings: boolean;
  bunkSettings: object | null | any;

  gettingGeneratedReportById: boolean;
  generatedReportById: object | null;
  gettingStudentsHostelBookingDownloadable: boolean;
  studentsHostelBookingDownloadable: object | null;
  assigningBunkToStudent: boolean;

  // hostel form details
  HostelId: number | null;
  SemesterId: number | null;
  BlockId: number | null;
  LevelId: number | null;
  StartDate: string | null;
  EndDate: string | null;
  GenderId: number | null;
  SessionId: number | null;
  FacultyId: number | null;
  DepartmentId: number | null;
  PageSize: number;
  PageNumber: number;
  StudentType: number | null;
  SearchPrams: string | null;
  roomNumber: number | null;
  BunkId: number | null;

  numberOfBlocks: number | null;
  hostelName: string;
  hostelNumberOfBlocks: number | null;
  hostelGenderId: number | null;

  status: number | null;
  session: number | null;
  hostelId: number | null;
  semester: number | null;
  department: number | null;
  level: any;

  hostelBlockId: number | null;
  blockNumberOfRooms: number | null;
  blockStatus: number | null;

  // editing states;
  hostelToEdit: number;
  newBunkId: number | null;
  newMatricNummber: string | null;
  hostelStatus: number | null;
  bunkStatus: number | null;
  numberOfBunkRooms: number | null;

  // bunkSettings State
  bunkId: number | null;
  bunkType: number | null;
  bunkNumber: string | null;

  // table states
  clickedButtonIndex: number | null;
  clickedButtonName: string | null;
}

const initialState: HostelState = {
  gettingStudentsHostelAllocation: false,
  studentsHostelAllocation: null,
  gettingHostelAllocation: false,
  hostelAllocation: null,
  checkingHostelValidity: false,
  hostelValidityData: null,
  gettingStudentsHostelBooking: false,
  studentsHostelBooking: null,
  gettingStudentsHostelBookingSummary: false,
  studentsHostelBookingSummary: null,
  gettingGeneratedReportById: false,
  generatedReportById: null,
  gettingStudentsHostelBookingDownloadable: false,
  studentsHostelBookingDownloadable: null,

  // new hostel
  gettingHostels: false,
  hostels: null,
  creatingHostel: false,
  editingHostel: false,
  gettingHostelBySession: false,
  hostelsBySession: null,
  gettingHostelBlocks: false,
  hostelBlocks: null,
  gettingRoomBunks: false,
  roomBunks: null,
  settingHostelSession: false,
  settingHostelSessionStatus: false,
  creatingHostelBlock: false,
  gettingBunkSettings: false,
  updatingBunkSettings: false,
  bunkSettings: null,

  assigningBunkToStudent: false,

  // hostel form details
  HostelId: null,
  SemesterId: null,
  BlockId: null,
  LevelId: null,
  StartDate: null,
  EndDate: null,
  GenderId: null,
  SessionId: null,
  FacultyId: null,
  DepartmentId: null,
  PageSize: 30,
  PageNumber: 1,
  StudentType: null,
  SearchPrams: null,
  roomNumber: null,
  BunkId: null,

  numberOfBlocks: null,
  hostelName: "",
  hostelNumberOfBlocks: 1,
  hostelGenderId: 1,

  status: null,
  session: null,
  hostelId: null,
  semester: null,
  department: null,
  level: [],

  hostelBlockId: null,
  blockNumberOfRooms: null,
  blockStatus: null,

  // editing states;
  hostelToEdit: 0,
  newBunkId: null,
  newMatricNummber: null,
  hostelStatus: null,
  bunkStatus: null,
  numberOfBunkRooms: null,

  // bunksettings states
  bunkId: null,
  bunkType: null,
  bunkNumber: null,

  // table states
  clickedButtonIndex: null,
  clickedButtonName: "",
};

export const HostelSlice = createSlice({
  name: "Hostel",
  initialState,
  reducers: {
    setGettingStudentsHostelAllocation: (state, action) => {
      state.gettingStudentsHostelAllocation = action.payload;
    },
    setStudentsHostelAllocation: (state, action) => {
      state.studentsHostelAllocation = action.payload;
    },
    setCheckingHostelValidity: (state, action) => {
      state.checkingHostelValidity = action.payload;
    },
    setHostelValidityData: (state, action) => {
      state.hostelValidityData = action.payload;
    },
    setGettingHostelAllocation: (state, action) => {
      state.gettingHostelAllocation = action.payload;
    },
    setHostelAllocation: (state, action) => {
      state.hostelAllocation = action.payload;
    },
    setGettingStudentsHostelBooking: (state, action) => {
      state.gettingStudentsHostelBooking = action.payload;
    },
    setStudentsHostelBooking: (state, action) => {
      state.studentsHostelBooking = action.payload;
    },
    setGettingStudentsHostelBookingSummary: (state, action) => {
      state.gettingStudentsHostelBookingSummary = action.payload;
    },
    setStudentsHostelBookingSummary: (state, action) => {
      state.studentsHostelBookingSummary = action.payload;
    },
    setGettingGeneratedReportById: (state, action) => {
      state.gettingGeneratedReportById = action.payload;
    },
    setGeneratedReportById: (state, action) => {
      state.generatedReportById = action.payload;
    },
    setGettingStudentsHostelBookingDownloadable: (state, action) => {
      state.gettingStudentsHostelBookingDownloadable = action.payload;
    },
    setCreatingHostel: (state, action) => {
      state.creatingHostel = action.payload;
    },
    setEditingHostel: (state, action) => {
      state.editingHostel = action.payload;
    },
    setSettingHostelSession: (state, action) => {
      state.settingHostelSession = action.payload;
    },
    setSettingHostelSessionStatus: (state, action) => {
      state.settingHostelSessionStatus = action.payload;
    },
    setCreatingHostelBlock: (state, action) => {
      state.creatingHostelBlock = action.payload;
    },
    setGettingHostelBySession: (state, action) => {
      state.gettingHostelBySession = action.payload;
    },
    setHostelBySession: (state, action) => {
      state.hostelsBySession = action.payload;
    },
    setGettingHostels: (state, action) => {
      state.gettingHostels = action.payload;
    },
    setHostels: (state, action) => {
      state.hostels = action.payload;
    },
    setGettingHostelBlocks: (state, action) => {
      state.gettingHostelBlocks = action.payload;
    },
    setHostelBlocks: (state, action) => {
      state.hostelBlocks = action.payload;
    },
    setGettingRoomBunks: (state, action) => {
      state.gettingRoomBunks = action.payload;
    },
    setGettingBunkSettings: (state, action) => {
      state.gettingBunkSettings = action.payload;
    },
    setUpdatingBunkSettings: (state, action) => {
      state.updatingBunkSettings = action.payload;
    },
    setBunkSettings: (state, action) => {
      state.bunkSettings = action.payload;
    },
    setAssigningBunkToStudent: (state, action) => {
      state.assigningBunkToStudent = action.payload;
    },
    setRoomBunks: (state, action) => {
      state.roomBunks = action.payload;
    },
    setStudentsHostelBookingDownloadable: (state, action) => {
      state.studentsHostelBookingDownloadable = action.payload;
    },
    setClickedButtonIndex: (state, action) => {
      state.clickedButtonIndex = action.payload;
    },
    setClickedButtonName: (state, action) => {
      state.clickedButtonName = action.payload;
    },
    resetHostelParams: (state) => {
      // hostel form details
      state.HostelId = null;
      state.SemesterId = null;
      state.BlockId = null;
      state.LevelId = null;
      state.StartDate = null;
      state.EndDate = null;
      state.GenderId = null;
      state.SessionId = null;
      state.FacultyId = null;
      state.DepartmentId = null;
      state.PageSize = 30;
      state.PageNumber = 1;
      state.StudentType = null;
      state.SearchPrams = null;

      state.numberOfBlocks = null;
      state.hostelName = "";
      state.hostelNumberOfBlocks = 1;
      state.hostelGenderId = 1;

      state.status = null;
      state.session = null;
      state.hostelId = null;
      state.semester = null;
      state.department = null;
      state.level = [];

      state.hostelBlockId = null;
      state.blockNumberOfRooms = null;
      state.blockStatus = null;
      state.hostelBlocks = null;

      // editing states;
      state.hostelToEdit = 0;
      state.newBunkId = null;
      state.newMatricNummber = null;
      state.hostelStatus = null;
      state.bunkStatus = null;
      state.numberOfBunkRooms = null;
      state.roomBunks = null;
      state.bunkSettings = null;
      state.studentsHostelAllocation = null;
      state.SearchPrams = "";
    },
    getHostelsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGeneratedReportById,
  setGettingGeneratedReportById,
  setGettingHostelAllocation,
  setGettingStudentsHostelAllocation,
  setGettingStudentsHostelBooking,
  setGettingStudentsHostelBookingDownloadable,
  setGettingStudentsHostelBookingSummary,
  setHostelAllocation,
  setStudentsHostelAllocation,
  setStudentsHostelBooking,
  setStudentsHostelBookingDownloadable,
  setStudentsHostelBookingSummary,
  getHostelsFormDetails,
  setCreatingHostel,
  setEditingHostel,
  setGettingHostelBySession,
  setHostelBySession,
  setSettingHostelSessionStatus,
  setGettingHostelBlocks,
  setHostelBlocks,
  setSettingHostelSession,
  setCreatingHostelBlock,
  setGettingRoomBunks,
  setRoomBunks,
  setGettingBunkSettings,
  setUpdatingBunkSettings,
  setGettingHostels,
  setHostels,
  setAssigningBunkToStudent,
  resetHostelParams,
  setBunkSettings,
  setClickedButtonIndex,
  setClickedButtonName,
  setCheckingHostelValidity,
  setHostelValidityData,
} = HostelSlice.actions;
export const selectHostel = (state: RootState) => state.hostel;

export default HostelSlice.reducer;
