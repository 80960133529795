import React, { FC } from "react";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components";

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
`;

// const DropdownContainer = styled(MultiSelect)`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   align-self: stretch;
//   background: var(--clr-white-100);
//   outline: none;
//   border: none;
//   padding: 10px 0;
//   text-align: left;
//   transition: all 0.5s ease;
//   width: 100%;
//   min-width: 300px;

//   &:focus {
//     border-color: var(--clr-black);
//     color: var(--clr-black);
//   }

//   &:hover {
//     background: none;
//   }
// `;

interface DropdownProps {
  dropdownItems?: any;
  title?: any;
  handleDropdownSelect?: any;
  name?: string;
  value?: any;
}

const MultiSelector: FC<DropdownProps> = ({
  dropdownItems,
  title,
  handleDropdownSelect,
  name,
  value,
}) => {
  return (
    <SelectorContainer>
      {name && <Label>{name}:</Label>}
      <MultiSelect
        options={dropdownItems}
        value={value}
        onChange={(value: any) => handleDropdownSelect(name, value)}
        labelledBy={title}
      />
    </SelectorContainer>
  );
};

export default MultiSelector;
