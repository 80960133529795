import { styled } from "styled-components";
import { Pagination, Table } from "rsuite";
import { Link } from "react-router-dom";
const { HeaderCell, Cell } = Table;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  rs-table-header-row-wrapper,
  .rs-table-row rs-table-row-header {
    background: none;
  }
`;

export const MainTable = styled(Table)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--clr-white-200);
  background: rgba(255, 255, 255, 0.4);
  //   overflow-x: auto;
`;

export const Header = styled(HeaderCell)`
  width: 100%;
  color: var(--clr-black);
  text-transform: capitalize;
  font-size: 12px;
  font-weight: var(--fw-bold);

  background: none;
`;

export const DataCell = styled(Cell)`
  color: var(--clr-black);
  font-size: 11px;
  font-weight: var(--fw-very-bold);
  padding: 12px;

  svg {
    color: var(--clr-grey-200);
    margin-right: 10px;
  }
`;

// custom table styles
export const TableContainer2 = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid var(--clr-white-200);
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
`;
export const TableTitle = styled.div``;
export const Table2 = styled.table`
  width: 100%;
  background: none;

  tr,
  thead,
  tbody {
    border: none;
  }
`;
export const TableHeader = styled.thead`
  background: none;
  font-size: 20px;
  font-weight: 600;
  color: #000;

  tr {
    background: none;
    box-shadow: none;
  }
`;
export const TableHead = styled.th`
  padding: 10px;
  text-align: left;
  color: var(--clr-black);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: var(--fw-very-bold);
  background: rgba(0, 0, 0, 0.05);
`;
export const TableRow = styled.tr`
  text-align: left;
  gap: 40px;
  border-radius: 2px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  margin-bottom: 0px;

  &:hover {
    // background: transparent !important;
    opacity: 0.5;
  }
`;
export const TableBody = styled.tbody`
  //   border: none;
`;
export const TableData = styled.td`
  color: var(--clr-black);
  font-size: 11px;
  font-weight: var(--fw-very-bold);
  padding: 10px;

  svg {
    color: var(--clr-grey-200);
    margin-right: 10px;
  }
`;

export const PaginationContainer = styled(Pagination)`
  color: var(--MWB-Black, var(--Black, #000));
  font-size: 12px;
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
  width: 100%;

  .rs-pagination-btn.rs-pagination-btn-active {
    background: var(--clr-primary-900);
    display: flex;
    width: 23px;
    height: 23px;
    flex-direction: column;
    justify-content: center;
    border: none;
    border-radius: 2px;
    color: var(--MWB-White, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: normal;

    &:hover {
      border: none;
      border-color: var(--clr-primary-900);
    }
  }
`;

export const NavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--clr-black);
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--clr-black);
  }
`;
