import React, { useState } from "react";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  PageTitle,
} from "../style";
import InnerNavTabs from "../../../Components/Nav/InnerNavTabs";
import { TabList } from "./utils";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getAccountDetails,
  selectUser,
} from "../../../Redux/features/userSlice";
import { adminResetPassword } from "../../../Apis/Auth/Account";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import api from "../../../Apis/baseUri";
import { toast } from "react-toastify";

const Security = () => {
  const [currentForm, setCurrentForm] = useState(1);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const { password, newPassword, resettingPassword, currentUser } =
    useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAccountDetails({ name, value }));
  };

  const handleOtp = async () => {
    setLoading(true);
    await api
      .get("/Account/LogIn2FA", {
        params: { userName: currentUser?.username },
      })
      .then(() => {
        toast.success("OTP sent to your email");
        setLoading(false);
        setCurrentForm(2);
      })
      .catch((err: any) => {
        if (err?.response) toast.error(err?.response?.data);
        if (err?.message === "Network Error") toast.error(err?.message);
      });
  };

  const handleSubmit = () => {
    if (password && newPassword && otp) {
      adminResetPassword(
        currentUser?.username,
        password,
        newPassword,
        otp
      ).then(() => {
        setCurrentForm(1);
      });
    }
  };

  return (
    <>
      <PageTitle>Change Password</PageTitle>
      <InnerNavTabs tabList={TabList} />
      <FormSectionContainer>
        {currentForm === 1 && (
          <FormCardContainer>
            <Form>
              <TextCenteredInput
                alignText="left"
                placeholder="Username"
                name="username"
                onChangeHandler={handleOnChange}
                value={currentUser?.username}
              />
            </Form>
            <BigButton
              buttonText="Send OTP"
              disabled={loading}
              handleClick={handleOtp}
              loading={loading}
            />
          </FormCardContainer>
        )}
        {currentForm === 2 && (
          <FormCardContainer>
            <Form>
              <TextCenteredInput
                alignText="left"
                placeholder="Username"
                name="username"
                onChangeHandler={handleOnChange}
                value={currentUser?.username}
              />
              <TextCenteredInput
                placeholder="Current Password"
                alignText="left"
                name="password"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="New Password"
                alignText="left"
                name="newPassword"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="OTP"
                alignText="left"
                onChangeHandler={(e: any) => setOtp(e.target.value)}
              />
            </Form>
            <BigButton
              buttonText="Change Password"
              disabled={resettingPassword}
              handleClick={handleSubmit}
              loading={resettingPassword}
            />
          </FormCardContainer>
        )}
      </FormSectionContainer>
    </>
  );
};

export default Security;
