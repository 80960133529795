import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import CustomTable2 from "../CustomTable2";
import { PaymentsCols, paymentRows } from "./utils";

const SingleStudentsPayments = () => {
  const { allStudentsPayment, gettingAllStudentsPayment } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();
  const rows = paymentRows(allStudentsPayment);

  const handlePagination = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageSize", value }));
  };
  return (
    <CustomTable2
      data={{ rows, columns: PaymentsCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingAllStudentsPayment}
      noRecord={allStudentsPayment?.records?.length === 0}
      page={allStudentsPayment?.currentPage}
      pageCount={allStudentsPayment?.totalRecords}
      pageSize={allStudentsPayment?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default SingleStudentsPayments;
