import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import { getPaymentById } from "../../../Apis/Private/payment";
import {  useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";

const PaymentInvoice = () => {
  const { Id } = useParams();
  const { paymentById } = useAppSelector(selectPayment);

  // const {}

  useEffect(() => {
    getPaymentById(Number(Id));
  }, [Id]);

  return (
    <>
      <PageTitle>Payment Details</PageTitle>
      <NavigationLink to={`/Payments`} className="flex-centered-items">
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <LongTransparentCard>
        {/* <img
          src="/assets/images/svg/user-1.svg"
          alt=""
          style={{ width: `200px` }}
        /> */}
        <LongWhiteCard>
          <ItemsCenterJustifyBtw>
            <div
              style={{ display: " flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Name:</span>
                <span>
                  {paymentById?.lastName} {paymentById?.firstName}
                </span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Invoice No:</span>
                <span>{paymentById?.invoiceNumber}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Payment Gateway:
                </span>
                <span>{paymentById?.paymentMeans}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Fee:</span>
                <span>{paymentById?.feeScheduleName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Amount:</span>
                <span>₦ {paymentById?.amount}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Date:</span>
                <span>
                  {moment(paymentById?.transactionDate).format("Do MMM YYYY")}
                </span>
              </div>
            </div>
            <div
              style={{ display: " flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Faculty Name:</span>
                <span>{paymentById?.facultyName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Department:</span>
                <span>{paymentById?.departmentName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Level:</span>
                <span>{paymentById?.levelName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Session:</span>
                <span>{paymentById?.sessionName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Teller Number:
                </span>
                <span>{paymentById?.tellerNoOrReceiptId}</span>
              </div>
              {/* <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Date:</span>
                <span>
                  {moment(paymentById?.transactionDate).format("Do MMM YYYY")}
                </span>
              </div> */}
            </div>
          </ItemsCenterJustifyBtw>
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default PaymentInvoice;
