import { HeaderContainer, HeaderRight, HeaderTop } from "./style";
import { useNavigate } from "react-router-dom";
import SmallButton from "../../Form/Buttons/SmallButton";

const SpecialHeader = () => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <HeaderTop>
        <img
          src="/assets/images/PNG/logo.png"
          alt=""
          onClick={() => navigate("/")}
        />
        <HeaderRight className="">
          <SmallButton buttonText="Login" handleClick={() => navigate("/")} />
        </HeaderRight>
      </HeaderTop>
    </HeaderContainer>
  );
};

export default SpecialHeader;
