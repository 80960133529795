import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import BunkSettingsFilter from "../../../Components/Filters/BunkSettingsFilter";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import {
  getBunkSettings,
  getHostelBlocks,
  getRoomBunks,
} from "../../../Apis/Private/hostel";
import BunkSettingsTable from "../../../Tables/AllHostels/BunkSettingsTable";
import { toast } from "react-toastify";

const BunkSettings = () => {
  const { HostelId, BlockId, SessionId, SemesterId, BunkId } =
    useAppSelector(selectHostel);

  const handleGettingBunkSettings = () => {
    if (BunkId && HostelId && BlockId && SemesterId) {
      getBunkSettings(BunkId, SessionId, SemesterId);
    }else{
      toast.info("All fields must be selected");
    }
  };

  useEffect(() => {
    getRoomBunks(BlockId, HostelId);
    getHostelBlocks(HostelId);
  }, [HostelId, BlockId]);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Bunk Settings</PageTitle>
        <BunkSettingsFilter filterFunction={handleGettingBunkSettings} />
      </ItemsCenterJustifyBtw>
      <BunkSettingsTable />
    </>
  );
};

export default BunkSettings;
