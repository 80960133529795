import { FC } from "react";
import styled from "styled-components";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { handleActiveModal, selectNav } from "../../Redux/features/navSlice";

const ModalContainer = styled(Drawer)`
  overflow-y: auto;
`;

const Close = styled.div`
  text-align: align;
  font-size: 14px;
  border: 4px solid #3a0ca3;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 15px;
  color: #3a0ca3;
  font-weight: 600;
  align-self: flex-end;
`;

const OverFlowContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  width: auto;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

interface IModal {
  content: any;
  id: string;
}

const CustomModal: FC<IModal> = ({ content, id }) => {
  const { activeModal } = useAppSelector(selectNav);
  const dispatch = useAppDispatch();
  return (
    <ModalContainer
      open={id === activeModal ? true : false}
      onClose={() => {
        dispatch(handleActiveModal(""));
      }}
      direction={
        window?.document.documentElement.clientWidth < 800 ? "bottom" : "right"
      }
      style={{
        height:
          window?.document.documentElement.clientWidth < 800 ? "80%" : "100%",
        width:
          window?.document.documentElement.clientWidth < 800 ? "100%" : "auto",
        zIndex: "1000",
        overflowY: "scroll",
      }}
      overlayClassName="overlay"
      className="drawar"
    >
      <OverFlowContainer>
        <Content>
          <Close
            onClick={() => {
              dispatch(handleActiveModal(""));
            }}
          >
            X
          </Close>
          {content}
        </Content>
      </OverFlowContainer>
    </ModalContainer>
  );
};
export default CustomModal;
