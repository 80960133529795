import { toast } from "react-toastify";
import {
  clearApplicationForm,
  setConfirmingPayment,
  setSendingApplicationForm,
  setStudentsApplicationDetails,
} from "./../../Redux/features/Application/ApplicationFormSlice";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import { formatDate, removeEmptyValues } from "../../helpers";

export const sendApplicationForm = async (
  firstName: string,
  lastName: string,
  otherName: string,
  contactAddress: string,
  emailAddress: string,
  mobilePhone: string,
  dateOfBirth: string,
  stateId: string,
  localGovernmentId: string,
  countryId: number | null
) => {
  store.dispatch(setSendingApplicationForm(true));
  const body = {
    firstName,
    lastName,
    otherName,
    contactAddress,
    emailAddress,
    mobilePhone,
    dateOfBirth,
    stateId,
    localGovernmentId,
    countryId,
  };
  await api
    .post("/Payments/CreateNewStudent", body)
    .then((res) => {
      const { data } = res;
      localStorage.setItem("newStudentDetails", JSON.stringify(data?.data));
      window.location.href = `/continue-application/${data?.data?.personId}`;
      store.dispatch(setSendingApplicationForm(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingApplicationForm(false));
    });
};

export const completeUndergraduateStudent = async (
  firstname?: string | null,
  lastname?: string | null,
  othername?: string | null,
  fullname?: string | null,
  dateOfBirth?: string | null,
  disabilityId?: number | null,
  emailAddress?: string | null,
  genderId?: number | null,
  contactAddress?: string | null,
  mobilePhone?: string | null,
  stateId?: string | null,
  localGovernmentId?: string | null,
  homeAddress?: string | null,
  countryId?: number | null,
  religionId?: number | null,
  admissionTypeId?: number | null,
  parentName?: string | null,
  parentNumber?: string | null,
  nextOfKin?: object | null,
  jambRecord?: {
    regNumber: string;
    score: number;
    year: number;
    firstSubjectId: number;
    firstSubjectScore: number;
    secondSubjectId: number;
    seconeSubjectScore: number;
    thirdSubjectId: number;
    thirdSubjectScore: number;
    forthSubjectId: number;
    forthSubjectScore: number;
  },
  olevelData?: {
    firstsubjectArray?: object[];
    secondSubjectArray?: [];
    firstexamNumber: string;
    firstexamType: number;
    firstexamYear: number;
    // secondexamNumber: string;
    // secondexamType: number;
    // secondexamYear: number;
  },
  hobbies?: string | null,
  previousEducation?: {
    institution: string | null;
    certificate_obtained: string | null;
    course_of_study: string | null;
    class_of_certificate: string | null;
    date_of_graduation: string | null;
    cgpa: string | null;
  },
  personId?: number | null,
  departmentId?: number | null,
  admissionModeId?: number | null
) => {
  store.dispatch(setSendingApplicationForm(true));
  const body: any = {
    firstname,
    lastname,
    othername,
    fullname,
    dateOfBirth: formatDate(dateOfBirth),
    disabilityId,
    emailAddress,
    genderId,
    contactAddress,
    mobilePhone,
    stateId,
    localGovernmentId,
    homeAddress,
    countryId,
    religionId,
    admissionTypeId,
    parentName,
    parentNumber,
    nextOfKin,
    jambRecord,
    olevelData,
    personId,
    previousEducation,
    hobbies,
    departmentId,
    admissionModeId: 2,
  };
  const filtered = removeEmptyValues(body);
  await api
    .post("/Payments/CompleteUndergraduateStudentAdmin", filtered)
    .then(() => {
      store.dispatch(setSendingApplicationForm(false));
      toast.success("student registered successfully");
      store.dispatch(clearApplicationForm());
      setTimeout(() => {
        window.location.replace("/application");
      }, 2000);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingApplicationForm(false));
    });
};

export const confirmPayment = async (reference: any) => {
  store.dispatch(setConfirmingPayment(true));
  const params = {
    reference,
  };
  await api
    .get("/Payments/ComfirmEtranzactCredoPayment", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsApplicationDetails(data?.data));
      localStorage.setItem("studentInfo", JSON.stringify(data?.data));
      store.dispatch(setConfirmingPayment(false));
      toast.success("Payment Confirmed");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingPayment(true));
    });
};

export const getStudentApplicationInfo = async (personId: any) => {
  store.dispatch(setConfirmingPayment(true));
  await api
    .get(`/Payments/GetStudentInfo/${personId}/continuo`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsApplicationDetails(data?.data));
      store.dispatch(setConfirmingPayment(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingPayment(true));
    });
};
