/* eslint-disable react-hooks/exhaustive-deps */
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { IoMdAdd } from "react-icons/io";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import { toast } from "react-toastify";
import {
  createFeeMappings,
  getFeeMappings,
} from "../../../Apis/Private/payment";
import FeeMappingsTable from "../../../Tables/AllPayments/FeeMappingsTable";
import {
  genderOptions,
  levelOptions,
  programmeOptions,
  statusOptions,
} from "../Hostels/util";
import {
  getPaymentsFormDetails,
  selectPayment,
  resetPaymentParams,
} from "../../../Redux/features/paymentSlice";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import FilterSection from "../../../Components/Filters/SettingsFilterSection";
import MultiSelector from "../../../Components/Form/Dropdowns/MultiSelector";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";

const FeeMapping = () => {
  const [createHostelSession, setCreateHostelSession] = useState(false);
  const {
    feeScheduleId,
    paymentInstallmentId,
    programmeId,
    departmentIds,
    levelId,
    sessionId,
    genderId,
    semesterId,
    admisionType,
    creatingFeeMapping,
    amount,
    SessionId,
    SemesterId,
    FacultyId,
    DepartmentId,
    LevelId,
    SearchPrams,
    PageNumber,
    PageSize,
  } = useAppSelector(selectPayment);
  // console.log(departmentIds,departmentId)
  const { departments, sessions, semesters, feeSchedule, admissionTypes } =
    useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );
  const admissionTypeFilter = filter(admissionTypes, "name", "id");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };
  
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleFeemappingCreation = () => {
    if (feeScheduleId && programmeId && sessionId && amount) {
      createFeeMappings(
        feeScheduleId,
        paymentInstallmentId,
        programmeId,
        // departmentId,
        departmentIds,
        levelId,
        sessionId,
        genderId,
        semesterId,
        admisionType,
        amount
      );
    } else {
      toast.error("Session and program type must be selected");
    }
  };

  const handleGetFeeMappings = () => {
    // if (SessionId) {
    getFeeMappings(
      SessionId,
      SemesterId,
      FacultyId,
      DepartmentId,
      LevelId,
      SearchPrams,
      PageNumber,
      PageSize
    );
    // }
  };

  useEffect(() => {
    if (SessionId || SearchPrams) {
      getFeeMappings(
        SessionId,
        SemesterId,
        FacultyId,
        DepartmentId,
        LevelId,
        SearchPrams,
        PageNumber,
        PageSize
      );
    }
  }, [SearchPrams, PageNumber, PageSize]);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Fee Mapping</PageTitle>
        {!createHostelSession && (
          <SmallButton
            buttonText="Add"
            leftIcon={<IoMdAdd />}
            handleClick={() => {
              setCreateHostelSession(true);
            }}
          />
        )}
      </ItemsCenterJustifyBtw>

      {createHostelSession && (
        <>
        <ItemsCenterJustifyBtw className="justify-end">
            <MultiSelector
              title={"Department"}
              name="departmentIds"
              value={departmentIds}
              handleDropdownSelect={handleSelect}
              dropdownItems={departmentFilter}
            />
           
          </ItemsCenterJustifyBtw>
          <ItemsCenterJustifyBtw className="justify-end">
            {/* <UnderlinedSelector
              title={"Department"}
              name="departmentId"
              value={departmentId}
              handleDropdownSelect={handleSelect}
              dropdownItems={departmentFilter}
            /> */}
            <MultiSelector
              title={"Department"}
              name="departmentIds"
              value={departmentIds}
              handleDropdownSelect={handleSelect}
              dropdownItems={departmentFilter}
            />
            <UnderlinedSelector
              title={"Session"}
              name="sessionId"
              value={sessionId}
              handleDropdownSelect={handleSelect}
              dropdownItems={sessionFilter}
            />
            <UnderlinedSelector
              title={"Semester"}
              name="semesterId"
              value={semesterId}
              handleDropdownSelect={handleSelect}
              dropdownItems={semesterFilter}
            />
            <UnderlinedSelector
              title={"Fee Schedule"}
              name="feeScheduleId"
              value={feeScheduleId}
              handleDropdownSelect={handleSelect}
              dropdownItems={feeScheduleFilter}
            />
            <UnderlinedSelector
              title={"Level"}
              name="levelId"
              value={levelId}
              handleDropdownSelect={handleSelect}
              dropdownItems={levelOptions}
            />
          </ItemsCenterJustifyBtw>
          <ItemsCenterJustifyBtw className="justify-end">
            <UnderlinedSelector
              title={"Gender"}
              name="genderId"
              value={genderId}
              handleDropdownSelect={handleSelect}
              dropdownItems={genderOptions}
            />
            <UnderlinedSelector
              title={"Pay Installment"}
              name="paymentInstallmentId"
              value={paymentInstallmentId}
              handleDropdownSelect={handleSelect}
              dropdownItems={statusOptions}
            />
            <UnderlinedSelector
              title={"Admission Type"}
              name="admisionType"
              value={admisionType}
              handleDropdownSelect={handleSelect}
              dropdownItems={admissionTypeFilter}
            />
            <UnderlinedSelector
              title={"Programme Type"}
              name="programmeId"
              value={programmeId}
              handleDropdownSelect={handleSelect}
              dropdownItems={programmeOptions}
            />
            <TextCenteredInput
              type="number"
              placeholder={"Fee Amount"}
              name="amount"
              value={amount}
              onChangeHandler={handleOnChange}
            />
            <SmallButton
              buttonText="Create"
              leftIcon={<IoMdAdd />}
              loading={creatingFeeMapping}
              handleClick={handleFeemappingCreation}
              alignWithSiblings={true}
            />
          </ItemsCenterJustifyBtw>
        </>
      )}

      <ItemsCenterJustifyBtw>
        <OutlinedSearchInput
          placeholder="Search Student"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={getPaymentsFormDetails}
        />
        <FilterSection
          resetFilterFunction={() => dispatch(resetPaymentParams())}
          filterFunction={handleGetFeeMappings}
        />
      </ItemsCenterJustifyBtw>
      <FeeMappingsTable />
    </>
  );
};

export default FeeMapping;
