import StudentsPayment from "../Pages/PrivatePages/Students/StudentsPayment";
import UploadOfflinePayment from "../Pages/PrivatePages/Payments/UploadOfflinePayment";
import CourseRegistration from "../Pages/PrivatePages/Students/CourseRegistration";
import HostelSessions from "../Pages/PrivatePages/Hostels/HostelSessions";
import HostelBlocks from "../Pages/PrivatePages/Hostels/HostelBlocks";
import HostelRoomBunks from "../Pages/PrivatePages/Hostels/HostelRoomBunks";
import FeeMapping from "../Pages/PrivatePages/Settings/FeeMapping";
import HostelAllocations from "../Pages/PrivatePages/Hostels/HostelAllocations";
import Security from "../Pages/PrivatePages/Settings/Security";
import DefferedStudents from "../Pages/PrivatePages/Students/DeferStudents";
import ChangeStudentLevel from "../Pages/PrivatePages/Students/ChangeStudentLevel";
import ExamClearance from "../Pages/PrivatePages/Settings/ExamClearance";
import Report from "../Pages/PrivatePages/Hostels/Report";
import BunkSettings from "../Pages/PrivatePages/Hostels/BunkSettings";
import Exports from "../Pages/PrivatePages/Exports";
import SetSession from "../Pages/PrivatePages/Settings/SetSession";
import Dashboard from "../Pages/PrivatePages/Dashboard";
import Payments from "../Pages/PrivatePages/Payments";
import Hostels from "../Pages/PrivatePages/Hostels";
import Students from "../Pages/PrivatePages/Students";
import Settings from "../Pages/PrivatePages/Settings";
import PaymentInvoice from "../Pages/PrivatePages/Payments/PaymentInvoice";
import VerifyPayment from "../Pages/PrivatePages/Payments/VerifyPayment";
import NextOfKin from "../Pages/PrivatePages/Students/NextOfKinDetails";
import PendingAdmissions from "../Pages/PrivatePages/Admission/PendingAdmissions";
import AdmittedStudents from "../Pages/PrivatePages/Admission/AdmittedStudents";
import ViewPendingAdmission from "../Pages/PrivatePages/Admission/ViewPendingAdmission";
import ViewAdmitedStudentDetails from "../Pages/PrivatePages/Admission/ViewAdmitedStudentDetails";
import InitiatedAdmissions from "../Pages/PrivatePages/Admission/InitiatedAdmissions";
import FeeSchedule from "../Pages/PrivatePages/Payments/FeeSchedule";
import VerifyHostel from "../Pages/PrivatePages/Hostels/VerifyHostel";
import AdmittedStudentProfile from "../Pages/PrivatePages/Students/StudentProfile";
import ConvertedStudentProfile from "../Pages/PrivatePages/Students/ConvertedStudentProfile";
import ConvertedStudentNextOfKin from "../Pages/PrivatePages/Students/ConvertedStudentNextOfKin";

export const RouteComponents = [
  {
    id: 1,
    routeLink: "/",
    component: <Dashboard />,
    permission: "dashboard",
  },
  {
    id: 2,
    routeLink: "/Students",
    component: <Students />,
    permission: "view-students",
  },
  {
    id: 3,
    routeLink: "/Students/Admitted/:Id/profile",
    component: <AdmittedStudentProfile />,
    permission: "view-students",
  },
  {
    id: 3.0,
    routeLink: "/Students/Converted/:Id/profile",
    component: <ConvertedStudentProfile />,
    permission: "view-students",
  },
  {
    id: 3.1,
    routeLink: "/admitted/Students/:Id/next-of-kin",
    component: <NextOfKin />,
    permission: "view-students",
  },
  {
    id: 3.2,
    routeLink: "/converted/Students/:Id/next-of-kin",
    component: <ConvertedStudentNextOfKin />,
    permission: "view-students",
  },
  {
    id: 4,
    routeLink: "/Students/course-registration",
    component: <CourseRegistration />,
    permission: "course-reg",
  },
  {
    id: 5,
    routeLink: "/Students/:Id/payments",
    component: <StudentsPayment />,
    permission: "view-student-payments",
  },
  {
    id: 6,
    routeLink: "/payments",
    component: <Payments />,
    permission: "view-payments",
  },
  {
    id: 7,
    routeLink: "/payments/:Id/view-payment",
    component: <PaymentInvoice />,
    permission: "view-payments",
  },
  {
    id: 8,
    routeLink: "/payments/upload-offline-payment",
    component: <UploadOfflinePayment />,
    permission: "upload-offline-payments",
  },
  {
    id: 9,
    routeLink: "/payments/verify-payments",
    component: <VerifyPayment />,
    permission: "verify-payments",
  },
  {
    id: 10,
    routeLink: "/hostels",
    component: <Hostels />,
    permission: "view-hostel",
  },
  {
    id: 11,
    routeLink: "/hostel/view-hostel-session",
    component: <HostelSessions />,
    permission: "hostel-settings",
  },
  {
    id: 12,
    routeLink: "/hostel/view-hostel-blocks",
    component: <HostelBlocks />,
    permission: "hostel-settings",
  },
  {
    id: 13,
    routeLink: "/hostel/view-block-rooms",
    component: <HostelRoomBunks />,
    permission: "hostel-settings",
  },
  {
    id: 14,
    routeLink: "/hostel/allocate-hostel",
    component: <HostelAllocations />,
    permission: "hostel-settings",
  },
  {
    id: 15,
    routeLink: "/hostel/allocation-report",
    component: <Report />,
    permission: "hostel-report",
  },
  {
    id: 16,
    routeLink: "/hostel/bunk-settings",
    component: <BunkSettings />,
    permission: "hostel-settings",
  },
  {
    id: 17,
    routeLink: "/settings/fee-mapping",
    component: <FeeMapping />,
    permission: "fee-setting",
  },
  {
    id: 18,
    routeLink: "/settings/profile",
    component: <Settings />,
    permission: "other-settings",
  },
  {
    id: 19,
    routeLink: "/settings/security",
    component: <Security />,
    permission: "other-settings",
  },
  {
    id: 20,
    routeLink: "/settings/exam-clearance",
    component: <ExamClearance />,
    permission: "exam-clearance",
  },
  {
    id: 21,
    routeLink: "/settings/deferment",
    component: <DefferedStudents />,
    permission: "deferment-settings",
  },
  {
    id: 22,
    routeLink: "/settings/set-student-level",
    component: <ChangeStudentLevel />,
    permission: "set-student-level",
  },
  {
    id: 23,
    routeLink: "/settings/set-session",
    component: <SetSession />,
    permission: "set-session",
  },
  {
    id: 24,
    routeLink: "/exports",
    component: <Exports />,
    permission: "view-exports",
  },
  {
    id: 25,
    routeLink: "/admission/pending-admissions",
    component: <PendingAdmissions />,
    permission: "view-admission",
  },
  {
    id: 26,
    routeLink: "/admission/admitted-students",
    component: <AdmittedStudents />,
    permission: "view-admission",
  },
  {
    id: 27,
    routeLink: "/admission/pending-admission/:Id",
    component: <ViewPendingAdmission />,
    permission: "view-admission",
  },
  {
    id: 28,
    routeLink: "/admission/admitted-student/:Id",
    component: <ViewAdmitedStudentDetails />,
    permission: "view-admission",
  },
  {
    id: 29,
    routeLink: "/admission/initiated-admissions",
    component: <InitiatedAdmissions />,
    permission: "view-admission",
  },
  {
    id: 30,
    routeLink: "/settings/fee-schedule",
    component: <FeeSchedule />,
    permission: "set-fee-schedule",
  },
  {
    id: 31,
    routeLink: "/verify-hostel-allocation/:studentId/:semesterId",
    component: <VerifyHostel />,
    permission: "verify-hostel-allocation",
  },
];
