import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectUtil } from "../../Redux/features/utilsSlice";
import { filter } from "../../Pages/PrivatePages/util";
import {
  getPaymentsFormDetails,
  resetPaymentParams,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import {
  genderOptions,
  levelOptions,
} from "../../Pages/PrivatePages/Hostels/util";
import ReactSuiteDropdown from "../Form/Dropdowns/NewDropdown";
import { FC } from "react";
import FilterButtons from "./FilterButtons";

interface FilterSectionProps {
  filterFunction?: any;
}

const FilterSection: FC<FilterSectionProps> = ({ filterFunction }) => {
  const {
    departments,
    faculties,
    sessions,
    semesters,
    paymentMean,
    feeSchedule,
  } = useAppSelector(selectUtil);
  const {
    SessionId,
    SemesterId,
    DepartmentId,
    FacultyId,
    FeeScheduledId,
    PaymentMeans,
    LevelId,
    GenderId,
  } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const facultyFilter = filter(faculties, "facultyName", "facultyId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const paymentMeanFilter = filter(paymentMean, "name", "id");
  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };
  return (
    <div className="flex-centered-items flex-wrap">
      <ReactSuiteDropdown
        dropdownItems={facultyFilter}
        title={`Faculty`}
        handleDropdownSelect={handleSelect}
        name="FacultyId"
        value={FacultyId}
      />
      <ReactSuiteDropdown
        dropdownItems={departmentFilter}
        title={`Department`}
        handleDropdownSelect={handleSelect}
        name="DepartmentId"
        value={DepartmentId}
      />
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />

      <ReactSuiteDropdown
        dropdownItems={paymentMeanFilter}
        title={`payment Mean`}
        handleDropdownSelect={handleSelect}
        name="PaymentMeans"
        value={PaymentMeans}
      />
      <ReactSuiteDropdown
        dropdownItems={feeScheduleFilter}
        title={`Fee Scheduled`}
        handleDropdownSelect={handleSelect}
        name="FeeScheduledId"
        value={FeeScheduledId}
      />
      <ReactSuiteDropdown
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="SemesterId"
        value={SemesterId}
      />
      <ReactSuiteDropdown
        dropdownItems={levelOptions}
        title={`Level`}
        handleDropdownSelect={handleSelect}
        name="LevelId"
        value={LevelId}
      />
      <ReactSuiteDropdown
        dropdownItems={genderOptions}
        title={`Gender`}
        handleDropdownSelect={handleSelect}
        name="GenderId"
        value={GenderId}
      />
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => {
          dispatch(resetPaymentParams());
        }}
      />
    </div>
  );
};

export default FilterSection;
