import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectStudents } from "../../../Redux/features/studentsSlice";
import {
  getReportsFormDetails,
  selectReport,
} from "../../../Redux/features/reportSlice";
import ExportedStudentReportTable from "../../../Tables/AllStudents/ExportedStudentReportTable";
import { getAllGeneratedReportBySession } from "../../../Apis/Private/reports";
import ReportFilter from "./ReportFilter";
const Exports = () => {
  const { SessionId, PageSize, studentType } = useAppSelector(selectStudents);
  const { studentReportName } = useAppSelector(selectReport);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
  };

  useEffect(() => {
    getAllGeneratedReportBySession(
      SessionId,
      null,
      studentReportName,
      PageSize
    );
  }, [SessionId, studentType, studentReportName, PageSize]);

  return (
    <>
      <PageTitle>Exported Reports</PageTitle>
      <ItemsCenterJustifyBtw>
        <OutlinedSearchInput
          placeholder="Type Report Name"
          name="studentReportName"
          value={studentReportName}
          onChangeHandler={handleOnChange}
          searchFunction={getReportsFormDetails}
        />
        <ReportFilter />
      </ItemsCenterJustifyBtw>
      <ExportedStudentReportTable />
    </>
  );
};

export default Exports;
