import React from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  PageTitle,
} from "../style";
import InnerNavTabs from "../../../Components/Nav/InnerNavTabs";
import { TabList } from "./utils";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";

const Settings = () => {
  const { currentUser } = useAppSelector(selectUser);


  return (
    <>
      <PageTitle>Account</PageTitle>
      <InnerNavTabs tabList={TabList} />
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <TextCenteredInput
              placeholder="Fullname"
              alignText="left"
              value={currentUser?.fullname}
            />
            <TextCenteredInput
              placeholder="Username"
              alignText="left"
              value={currentUser?.username}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default Settings;
