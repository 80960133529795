import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { getAdmmitedStudentDetails } from "../../../Apis/Private/students";
import moment from "moment";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectStudents } from "../../../Redux/features/studentsSlice";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";

const ViewAdmitedStudentDetails = () => {
  const { admittedStudentsDetails } = useAppSelector(selectStudents);
  const { Id } = useParams();

  useEffect(() => {
    getAdmmitedStudentDetails(Number(Id));
  }, [Id]);

  return (
    <>
      <PageTitle>Admitted Student Information</PageTitle>
      <NavigationLink
        to={`/admission/admitted-students`}
        className="flex-centered-items"
      >
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <LongTransparentCard>
        <LongWhiteCard>
          <ItemsCenterJustifyBtw>
            <div
              style={{ display: " flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Name:</span>
                <span>{admittedStudentsDetails?.fullname}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Faculty:</span>
                <span>{admittedStudentsDetails?.facultyName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Department:</span>
                <span>{admittedStudentsDetails?.departmentName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Phone Number:</span>
                <span>{admittedStudentsDetails?.mobilePhone}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Email Address:
                </span>
                <span>{admittedStudentsDetails?.emailAddress}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Parent Name:</span>
                <span>{admittedStudentsDetails?.parentName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Contact Address:
                </span>
                <span>{admittedStudentsDetails?.contactAddress}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>State:</span>
                <span>{admittedStudentsDetails?.stateName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Local Government:
                </span>
                <span>{admittedStudentsDetails?.localGovernmentName}</span>
              </div>
            </div>
            <div
              style={{ display: " flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Programme Name:
                </span>
                <span>{admittedStudentsDetails?.programmeName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Application Number:
                </span>
                <span>{admittedStudentsDetails?.appicationNumber}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Session:</span>
                <span>{admittedStudentsDetails?.sessionName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Gender:</span>
                <span>
                  {admittedStudentsDetails?.genderId === 1 ? "Male" : "Female"}
                </span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Religion:</span>
                <span>{admittedStudentsDetails?.religionName}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Date of birth:
                </span>
                <span>
                  {moment(admittedStudentsDetails?.dateOfBirth)?.format(
                    "Do MMM YYYY"
                  )}
                </span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Means:</span>
                <span>{admittedStudentsDetails?.advertMeans}</span>
              </div>
              <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>
                  Admission Status:
                </span>
                <span style={{ color: `green` }}>
                  {admittedStudentsDetails?.admitted && "Admitted"}
                </span>
              </div>
            </div>
          </ItemsCenterJustifyBtw>
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default ViewAdmitedStudentDetails;
