import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import moment from "moment";
import { getSinglePendingAdmission } from "../../../Apis/Private/Admissions";
import {
  getAdmissionFormDetails,
  selectAdmission,
} from "../../../Redux/features/admissionSlice";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { handleActiveModal } from "../../../Redux/features/navSlice";
import AdmitStudentModal from "../../../Components/Modals/AdmitStudentModal";
import RejectStudentModal from "../../../Components/Modals/RejectStudentModal";
import ReactPrint from "../../../Components/Layouts/ReactPrint";
import { nextOfKinRelationship } from "../../AuthPages/Applications/NextOfKinDetails";

const ViewPendingAdmission = () => {
  const { Id } = useParams();
  const { singlePendingAdmission } = useAppSelector(selectAdmission);
  const dispatch = useAppDispatch();

  // const {}
  useEffect(() => {
    getSinglePendingAdmission(Number(Id));
  }, [Id]);
  return (
    <>
      <PageTitle>Pending Admission Details</PageTitle>
      <NavigationLink
        to={`/admission/pending-admissions`}
        className="flex-centered-items"
      >
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <LongTransparentCard>
        <ReactPrint>
          <LongWhiteCard>
            <ItemsCenterJustifyBtw>
              <div style={{ display: " flex", flexDirection: "column" }}>
                <h4>Personal Information</h4>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Name:</span>
                  <span>{singlePendingAdmission?.fullname}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Email:</span>
                  <span>
                    {singlePendingAdmission?.olevelData?.emailAddress}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Date Of Birth:
                  </span>
                  <span>{singlePendingAdmission?.olevelData?.dateOfBirth}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Gender:</span>
                  <span>{singlePendingAdmission?.olevelData?.genderName}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Religion:</span>
                  <span>
                    {singlePendingAdmission?.olevelData?.religionName}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>State:</span>
                  <span>{singlePendingAdmission?.olevelData?.stateName}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Local Government Area:
                  </span>
                  <span>
                    {singlePendingAdmission?.olevelData?.localGovernmentName}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Tribe:</span>
                  <span>{singlePendingAdmission?.olevelData?.tribeName}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Home Town:</span>
                  <span>{singlePendingAdmission?.olevelData?.homeTown}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Faculty:</span>
                  <span>
                    {singlePendingAdmission?.appliedCourse?.department
                      ?.faculty || "N/A"}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Department:</span>
                  <span>
                    {
                      singlePendingAdmission?.appliedCourse?.department
                        ?.departmentName
                    }
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Application Number:
                  </span>
                  <span>
                    {singlePendingAdmission?.olevelData?.appicationNumber}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Phone Number:
                  </span>
                  <span>{singlePendingAdmission?.olevelData?.mobilePhone}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Registered Date:
                  </span>
                  <span>
                    {moment(singlePendingAdmission?.dateEntered).format(
                      "Do MMM YYYY"
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: " flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Admission Type:
                  </span>
                  <span>
                    {singlePendingAdmission?.appliedCourse?.admissionType?.name}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Session:</span>
                  <span>
                    {
                      singlePendingAdmission?.appliedCourse?.session
                        ?.sessionName
                    }
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Referred By:
                  </span>
                  <span>{singlePendingAdmission?.referal?.advert?.means}</span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Admission Status:
                  </span>
                  <span style={{ color: `gold` }}>
                    {!singlePendingAdmission?.admitted && "Pending"}
                  </span>
                </div>
                <div>
                  <h4>Sponsor Information</h4>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Full Name:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {singlePendingAdmission?.sponsorAndNextOfKin?.fullname}
                    </span>
                  </div>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Contact Address:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {
                        singlePendingAdmission?.sponsorAndNextOfKin
                          ?.contactAddress
                      }
                    </span>
                  </div>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Phone Number:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {singlePendingAdmission?.sponsorAndNextOfKin?.mobilePhone}
                    </span>
                  </div>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Relationship:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {
                        nextOfKinRelationship?.filter(
                          (x: any) =>
                            x?.value ===
                            singlePendingAdmission?.sponsorAndNextOfKin
                              ?.relationshipId
                        )[0]?.label
                      }
                    </span>
                  </div>
                </div>
              </div>
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <div>
                <h4>First O level Result</h4>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Exam Name:</span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.firstAtempt?.examName}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    Exam Number:
                  </span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.firstAtempt?.examNumber}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Exam Year:</span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.firstAtempt?.examYear}
                  </span>
                </div>
                <h5>Result:</h5>
                {Object?.entries(
                  singlePendingAdmission?.firstAtempt?.results || {}
                )
                  ?.map(([subject, grade]) => ({
                    subject,
                    grade,
                  }))
                  ?.map(({ subject, grade }: any, index: any) => (
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                      key={index}
                    >
                      <span style={{ color: `var(--clr-black)` }}>
                        {subject} ----------------
                      </span>
                      <span style={{ color: `var(--clr-black)` }}>{grade}</span>
                    </div>
                  ))}
              </div>
              {singlePendingAdmission?.secondAtempt && (
                <div>
                  <h4>Second O level Result</h4>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Exam Name:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {singlePendingAdmission?.secondAtempt?.examName}
                    </span>
                  </div>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Exam Number:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {singlePendingAdmission?.secondAtempt?.examNumber}
                    </span>
                  </div>
                  <div
                    className="flex-centered-items"
                    style={{ fontSize: `16px` }}
                  >
                    <span style={{ color: `var(--clr-black)` }}>
                      Exam Year:
                    </span>
                    <span style={{ color: `var(--clr-black)` }}>
                      {singlePendingAdmission?.secondAtempt?.examYear}
                    </span>
                  </div>
                  <h5>Result:</h5>
                  {Object?.entries(
                    singlePendingAdmission?.secondAtempt?.results || {}
                  )
                    ?.map(([subject, grade]) => ({
                      subject,
                      grade,
                    }))
                    ?.map(({ subject, grade }: any, index: any) => (
                      <div
                        className="flex-centered-items"
                        style={{ fontSize: `16px` }}
                        key={index}
                      >
                        <span style={{ color: `var(--clr-black)` }}>
                          {subject} ----------------
                        </span>
                        <span style={{ color: `var(--clr-black)` }}>
                          {grade}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <div>
                <h4>Jamb Info.</h4>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Exam Year:</span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.examYear}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Reg. No:</span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.jambRegistrationNumber}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>Score:</span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.jambScore}
                  </span>
                </div>
                <h5>Result:</h5>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.firstSubject}
                    ----------------
                  </span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.firstSubjectScore}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.secondSubject}
                    ----------------
                  </span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.seconeSubjectScore}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.thirdSubject}
                    ----------------
                  </span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.thirdSubjectScore}
                  </span>
                </div>
                <div
                  className="flex-centered-items"
                  style={{ fontSize: `16px` }}
                >
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.forthSubject}
                    ----------------
                  </span>
                  <span style={{ color: `var(--clr-black)` }}>
                    {singlePendingAdmission?.jambInfo?.forthSubjectScore}
                  </span>
                </div>
              </div>
              <div></div>
            </ItemsCenterJustifyBtw>
          </LongWhiteCard>
        </ReactPrint>
        <div className="flex-row">
          <SmallButton
            buttonText="Admit"
            handleClick={() => {
              dispatch(handleActiveModal("admit_student_modal"));
              dispatch(
                getAdmissionFormDetails({
                  name: "personId",
                  value: Id,
                })
              );
              dispatch(
                getAdmissionFormDetails({
                  name: "reason",
                  value: "",
                })
              );
            }}
          />
          <SmallButton
            buttonText="Reject"
            bgColor={"red"}
            handleClick={() => {
              dispatch(handleActiveModal("reject_student_modal"));
              dispatch(
                getAdmissionFormDetails({
                  name: "personId",
                  value: Id,
                })
              );
              dispatch(
                getAdmissionFormDetails({
                  name: "reason",
                  value: "",
                })
              );
            }}
          />
        </div>
      </LongTransparentCard>
      <AdmitStudentModal />
      <RejectStudentModal />
    </>
  );
};

export default ViewPendingAdmission;
