import React from "react";
import { FormContainer } from "../../Pages/AuthPages/styles";
import CustomModal from "./CustomModal";
import Swal, { SweetAlertOptions } from "sweetalert2";
import SmallButton from "../Form/Buttons/SmallButton";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getAdmissionFormDetails,
  selectAdmission,
} from "../../Redux/features/admissionSlice";
import { toast } from "react-toastify";
import { rejectAdmission } from "../../Apis/Private/Admissions";
import TextArea from "../ApplicationComponents/Form/Inputs/TextArea";

const RejectStudentModal = () => {
  const { reason, personId, grantingAdmission } =
    useAppSelector(selectAdmission);
  const dispatch = useAppDispatch();

  const handleTextAreaChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(getAdmissionFormDetails({ name, value }));
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure to reject this student?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    } as SweetAlertOptions).then((res) => {
      if (res?.isConfirmed) {
        if (personId) {
          rejectAdmission(personId, reason);
        } else {
          toast.error("Student doesn't exist");
        }
      }
    });
  };

  return (
    <CustomModal
      id={"reject_student_modal"}
      content={
        <FormContainer
          style={{
            width: "100%",
            marginTop: "20px;",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label
            style={{ fontSize: "15px", fontWeight: "bold", color: "#000" }}
          >
            Reason for rejecting student
          </label>
          <TextArea
            placeholder="Type message..."
            alignText="left"
            value={reason}
            onChange={handleTextAreaChange}
            name="reason"
          />
          <SmallButton
            buttonText="Submit"
            textTransform="capitalize"
            handleClick={handleSubmit}
            loading={grantingAdmission}
          />
        </FormContainer>
      }
    />
  );
};

export default RejectStudentModal;
