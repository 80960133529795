import React, { FC } from "react";
import styled from "styled-components";

interface SmallButtonProps {
  buttonText?: string;
  textTransform?: string;
  handleClick?: any;
  disabled?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  loading?: any;
}

const Conatainer = styled.button<SmallButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 35px;
  border: none;
  outline: none;
  background: ${(props) =>
    props?.disabled ? `var(--clr-primary-100)` : `var(--clr-primary-900)`};
  box-shadow: 0px 4px 20px 0px rgba(90, 2, 14, 0.15);
  padding: 10px 20px;
  color: var(--clr-white-100);
  border-radius: 3px;
  text-transform: ${(props) =>
    props?.textTransform ? props?.textTransform : `uppercase`};
  cursor: pointer;
`;
const SmallButton: FC<SmallButtonProps> = ({
  buttonText,
  disabled,
  textTransform,
  handleClick,
  leftIcon,
  rightIcon,
  loading,
}) => {
  return (
    <Conatainer
      disabled={disabled}
      textTransform={textTransform}
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick();
      }}
    >
      {leftIcon && leftIcon}
      {buttonText}
      {rightIcon && rightIcon}
    </Conatainer>
  );
};

export default SmallButton;
