import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectUtil } from "../../Redux/features/utilsSlice";
import { getHostelsFormDetails, resetHostelParams, selectHostel } from "../../Redux/features/hostelSlice";
import ReactSuiteDropdown from "../../Components/Form/Dropdowns/NewDropdown";
import { filter } from "../../Pages/PrivatePages/util";
import FilterButtons from "./FilterButtons";

interface FilterSectionProps {
  filterFunction?: any;
}


const HostelSectionFilter: FC<FilterSectionProps> = ({ filterFunction }) => {
  const { sessions } = useAppSelector(selectUtil);
  const { SessionId } = useAppSelector(selectHostel);

  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };
  return (
    <div className="flex-centered-items flex-wrap">
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => {
          dispatch(resetHostelParams());
        }}
      />
    </div>
  );
};

export default HostelSectionFilter;
