import React from "react";
import AuthPageLayout from "../../../Components/Layouts/AuthPageLayout";
import { AuthRedirectionLink, FormContainer } from "../styles";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import TextInputWithIcon from "../../../Components/Form/Inputs/TextInputWithIcon";
import { AiOutlineMail } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getAccountDetails,
  selectUser,
} from "../../../Redux/features/userSlice";
import { resetPassword } from "../../../Apis/Auth/Account";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const { username, password, newPassword, resettingPassword, otp } =
    useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAccountDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (password && username) {
      if (password === newPassword) {
        resetPassword(username, password, newPassword, otp);
      } else {
        toast.error("Password does not match");
      }
    }
  };

  return (
    <AuthPageLayout authText="forgot password">
      <FormContainer>
        <TextInputWithIcon
          type="text"
          placeholder="Enter Username"
          icon={<AiOutlineMail />}
          name="username"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="text"
          placeholder="Enter New Password"
          icon={<AiOutlineMail />}
          name="password"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="text"
          placeholder="Confirm New Password"
          icon={<AiOutlineMail />}
          name="newPassword"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="text"
          placeholder="otp"
          name="otp"
          handleChange={handleOnChange}
        />
        <BigButton
          buttonText="Reset"
          disabled={false}
          handleClick={handleSubmit}
          loading={resettingPassword}
        />
      </FormContainer>
      <AuthRedirectionLink to="/">SIGN IN</AuthRedirectionLink>
    </AuthPageLayout>
  );
};

export default ForgotPassword;
