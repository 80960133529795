import React from "react";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  ItemsCenterJustifyBtw,
  PageTitle,
} from "../style";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../../Redux/features/paymentSlice";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { filter } from "../util";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import {
  getStudentsInformation,
  uploadOfflinePayment,
} from "../../../Apis/Private/payment";
import { toast } from "react-toastify";

const UploadOfflinePayment = () => {
  const {
    accountNo,
    tellerNumber,
    description,
    bankId,
    uploadingOfflinePayment,
    regNumber,
    FeeScheduledId,
    SessionId,
    gettingStudentsInfo,
    studentsInfo,
    feeDetailsId,
    SemesterId,
  } = useAppSelector(selectPayment);
  const { sessions, feeSchedule, bankList, semesters } =
    useAppSelector(selectUtil);
  const dispatch = useAppDispatch();
  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );
  const bankFilter = filter(bankList, "bankName", "bankId");
  const feeDetailsFilter = studentsInfo?.feeDetails?.map((info: any) => {
    return {
      label: info?.feeSchedule?.feeScheduleName,
      value: info?.feeDetailId,
    };
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;

    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleGetStudentsInfo = () => {
    if (regNumber && FeeScheduledId && SessionId) {
      getStudentsInformation(regNumber, SessionId, SemesterId, FeeScheduledId);
    } else {
      toast.error("Please fill all fields");
    }
  };

  const handleUpload = () => {
    uploadOfflinePayment(
      feeDetailsFilter[0].value,
      studentsInfo?.personId,
      bankId,
      tellerNumber,
      accountNo,
      description
    );
  };

  return (
    <>
      <PageTitle>Upload Offline Payment</PageTitle>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="Reg No."
                alignText="left"
                value={regNumber}
                onChangeHandler={handleOnChange}
                name="regNumber"
              />
              <UnderlinedSelector
                dropdownItems={feeScheduleFilter}
                handleDropdownSelect={handleSelect}
                name="FeeScheduledId"
                title="Fee Schedule"
                value={FeeScheduledId}
              />
              <UnderlinedSelector
                dropdownItems={sessionFilter}
                handleDropdownSelect={handleSelect}
                name="SessionId"
                title="Session"
                value={SessionId}
              />
              <UnderlinedSelector
                dropdownItems={semesterFilter}
                handleDropdownSelect={handleSelect}
                name="SemesterId"
                title="Semester"
                value={SemesterId}
              />
            </ItemsCenterJustifyBtw>
            <BigButton
              buttonText="Fetch Student Info"
              textTransform="capitalize"
              disabled={false}
              loading={gettingStudentsInfo}
              handleClick={handleGetStudentsInfo}
            />
          </Form>
          {studentsInfo && (
            <Form>
              <ItemsCenterJustifyBtw>
                <TextCenteredInput
                  placeholder="Matric Number"
                  alignText="left"
                  value={regNumber}
                />
                <TextCenteredInput
                  placeholder="Fullname"
                  alignText="left"
                  value={studentsInfo?.studentName}
                />
                <TextCenteredInput
                  placeholder="Department"
                  alignText="left"
                  value={studentsInfo?.departmentName}
                />
                {/* <TextCenteredInput
                  placeholder="Admission Type"
                  alignText="left"
                  value={studentsInfo?.personId}
                /> */}
              </ItemsCenterJustifyBtw>
              <ItemsCenterJustifyBtw>
                <UnderlinedSelector
                  title="Fee Type"
                  handleDropdownSelect={handleSelect}
                  value={
                    feeDetailsFilter?.length > 1
                      ? feeDetailsId
                      : feeDetailsFilter[0]?.value
                  }
                  dropdownItems={feeDetailsFilter}
                  name="feeDetailsId"
                />
                <TextCenteredInput
                  placeholder="Level"
                  alignText="left"
                  value={studentsInfo?.levelName}
                />
                <UnderlinedSelector
                  title="Session"
                  value={SessionId}
                  dropdownItems={sessionFilter}
                  handleDropdownSelect={handleSelect}
                />
              </ItemsCenterJustifyBtw>
              <ItemsCenterJustifyBtw>
                <UnderlinedSelector
                  dropdownItems={feeScheduleFilter}
                  handleDropdownSelect={handleSelect}
                  name="FeeScheduledId"
                  title="Fee Schedule"
                  value={FeeScheduledId}
                />
                <UnderlinedSelector
                  dropdownItems={bankFilter}
                  handleDropdownSelect={handleSelect}
                  name="bankId"
                  title="Bank"
                  value={bankId}
                />
                {(studentsInfo?.feeDetails[0]?.feeSchedule?.feeScheduleId ===
                  33 ||
                  studentsInfo?.feeDetails[0]?.feeSchedule?.feeScheduleId ===
                    10) && (
                  <TextCenteredInput
                    alignText="left"
                    onChangeHandler={handleOnChange}
                    name="accountNo"
                    placeholder="Account No"
                    value={accountNo}
                  />
                )}
              </ItemsCenterJustifyBtw>
              <ItemsCenterJustifyBtw>
                <TextCenteredInput
                  placeholder="Teller Number"
                  alignText="left"
                  value={tellerNumber}
                  onChangeHandler={handleOnChange}
                  name="tellerNumber"
                />
                <TextCenteredInput
                  placeholder="description"
                  alignText="left"
                  value={description}
                  onChangeHandler={handleOnChange}
                  name="description"
                />
                <span
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Fee: ₦{" "}
                  {studentsInfo?.feeDetails[0]?.feeAmount.toLocaleString()}
                </span>
              </ItemsCenterJustifyBtw>
              <BigButton
                buttonText="Upload Payment"
                textTransform="capitalize"
                disabled={false}
                loading={uploadingOfflinePayment}
                handleClick={handleUpload}
              />
            </Form>
          )}
        </FormCardContainer>
        {/* </LeftContent> */}
      </FormSectionContainer>
    </>
  );
};

export default UploadOfflinePayment;
