/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import InitiatedAdmissionsTable from "../../../Tables/AllStudents/InitiatedAdmissionsTable";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { getInitiatedAdmissions } from "../../../Apis/Private/Admissions";
import InitiatedAdmissionsFilter from "../../../Components/Filters/InitiatedAdmissionsFilter";

const InitiatedAdmissions = () => {
  const dispatch = useAppDispatch();
  const { SessionId, SearchPrams, PageNumber, PageSize } =
    useAppSelector(selectStudents);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleFetchInitiatedAdmissions = () => {
    if (SessionId || SearchPrams) {
      getInitiatedAdmissions(SessionId, SearchPrams, PageSize, PageNumber);
    }
  };

  useEffect(() => {
    handleFetchInitiatedAdmissions();
  }, [PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Initiated Applications</PageTitle>
      <ItemsCenterJustifyBtw className="justify-between">
        <OutlinedSearchInput
          placeholder="Search by name or email"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={handleFetchInitiatedAdmissions}
        />
        <InitiatedAdmissionsFilter
          filterFunction={handleFetchInitiatedAdmissions}
        />
      </ItemsCenterJustifyBtw>
      <InitiatedAdmissionsTable />
    </>
  );
};

export default InitiatedAdmissions;
