/* eslint-disable react-hooks/exhaustive-deps */
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
// import SummarySection from "./SummarySection";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import {
  getHostelBlocks,
  getRoomBunks,
  getStudentsHostelAllocation,
  getStudentsHostelBookingsDownloadable,
} from "../../../Apis/Private/hostel";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import AllocationReport from "../../../Tables/AllHostels/AlocationReport";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { PiExportBold } from "react-icons/pi";
import {
  getReportsFormDetails,
  selectReport,
} from "../../../Redux/features/reportSlice";
import { toast } from "react-toastify";
import AllocationFilter from "../../../Components/Filters/AllocationFilter";

const Report = () => {
  const {
    HostelId,
    SemesterId,
    BlockId,
    LevelId,
    GenderId,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    StudentType,
    SearchPrams,
    gettingStudentsHostelBookingDownloadable,
    BunkId,
  } = useAppSelector(selectHostel);
  const { hostelReportName } = useAppSelector(selectReport);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getHostelsFormDetails({ name, value }));
    dispatch(getReportsFormDetails({ name, value }));
  };

  const handleExport = (e: any) => {
    e.preventDefault();
    if (hostelReportName) {
      getStudentsHostelBookingsDownloadable(
        HostelId,
        SemesterId,
        BlockId,
        LevelId,
        GenderId,
        hostelReportName,
        SessionId,
        FacultyId,
        DepartmentId,
        PageSize,
        PageNumber
      );
    } else {
      toast.error("Enter the report name");
    }
  };

  const handleGettingAllocationReport = () => {
    dispatch(getHostelsFormDetails({ name: "", value: null }));
    if ((HostelId && SessionId &&BlockId && SemesterId) || SearchPrams) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    } else {
      toast.info("At least Hostel, Block, Session and Semester must be selected");
    }
  };

  useEffect(() => {
    getRoomBunks(BlockId, HostelId);
    getHostelBlocks(HostelId);
  }, [HostelId, BlockId]);

  useEffect(() => {
    if (SearchPrams) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    }
    if ((FacultyId && SessionId && SemesterId) || SearchPrams) {
      getStudentsHostelAllocation(
        HostelId,
        SemesterId,
        BlockId,
        BunkId,
        LevelId,
        GenderId,
        SessionId,
        FacultyId,
        DepartmentId,
        StudentType,
        SearchPrams,
        PageSize,
        PageNumber
      );
    }
  }, [SearchPrams, PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Allocations Report</PageTitle>
      <ItemsCenterJustifyBtw className="justify-end">
        <TextCenteredInput
          placeholder="Type Report Name to Export"
          alignText="left"
          name="hostelReportName"
          value={hostelReportName}
          onChangeHandler={handleOnChange}
        />
        {hostelReportName && (
          <SmallButton
            buttonText="Export"
            leftIcon={<PiExportBold />}
            loading={gettingStudentsHostelBookingDownloadable}
            handleClick={handleExport}
          />
        )}
      </ItemsCenterJustifyBtw>
      <OutlinedSearchInput
        placeholder="Search Student"
        name="SearchPrams"
        value={SearchPrams}
        onChangeHandler={handleOnChange}
        searchFunction={getHostelsFormDetails}
      />
      <AllocationFilter filterFunction={handleGettingAllocationReport} />
      <AllocationReport />
    </>
  );
};

export default Report;
