import { ItemsCenterJustifyBtw } from "../style";
import { useAppSelector } from "../../../Redux/app/hooks";
import BLockFilter from "../../../Components/Filters/BLockFilter";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import { getHostelBlocks } from "../../../Apis/Private/hostel";
import HostelBlockTable from "../../../Tables/AllHostels/HostelBlockTable";

const HostelBlock = () => {
  const { HostelId } = useAppSelector(selectHostel);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <BLockFilter
          filterFunction={() => {
            HostelId && getHostelBlocks(HostelId);
          }}
        />
        <span></span>
      </ItemsCenterJustifyBtw>
      <HostelBlockTable />
    </>
  );
};

export default HostelBlock;
