import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";

const OtherInformation = () => {
  const dispatch = useAppDispatch();
  const {
    institutionName,
    courseOfStudy,
    cgpa,
    certificateObtained,
    classOfCertificate,
    dateOfGraduation,
  } = useAppSelector(selectapplicationForm);
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleGpa = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = maxNumberInput(value);
    dispatch(getApplicationDetails({ name, value: newValue }));
  };

  const handleSubmit = () => {
    if (
      institutionName &&
      courseOfStudy &&
      cgpa &&
      certificateObtained &&
      classOfCertificate &&
      dateOfGraduation
    ) {
      navigate("/edit/o-level-result-details");
    } else {
      toast.info("Please fill all fields");
    }
  };

  const maxNumberInput = (value: string) => {
    if (Number(value) > 5.0 || Number(value) < 0) {
      toast.warning("CGPA should not be less than 0 or greater than 5");
      return 0;
    } else {
      return value;
    }
  };

  return (
    <AuthPageLayout authText="Previous Education">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Institution Name"
            name="institutionName"
            handleChange={handleOnChange}
            value={institutionName}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Course Of Study"
            name="courseOfStudy"
            handleChange={handleOnChange}
            value={courseOfStudy}
          />
          <TextInputWithIcon
            type="number"
            placeholder="CGPA"
            name="cgpa"
            handleChange={handleGpa}
            value={cgpa}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Certificate Obtained"
            name="certificateObtained"
            handleChange={handleOnChange}
            value={certificateObtained}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Class Of Certificate"
            name="classOfCertificate"
            handleChange={handleOnChange}
            value={classOfCertificate}
          />
          <TextInputWithIcon
            type="date"
            placeholder="Date Of Graduation"
            name="dateOfGraduation"
            handleChange={handleOnChange}
            value={dateOfGraduation}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default OtherInformation;
