import React, { FC } from "react";
import SmallButton from "../Form/Buttons/SmallButton";
import { CiFilter } from "react-icons/ci";
import { GrPowerReset } from "react-icons/gr";

interface FilterButtonProps {
  filterFunction?: any;
  resetFilterFunction?: any;
}
const FilterButtons: FC<FilterButtonProps> = ({
  filterFunction,
  resetFilterFunction,
}) => {
  return (
    <>
      <SmallButton
        leftIcon={<CiFilter />}
        buttonText="Filter"
        textTransform="Capitalize"
        handleClick={filterFunction}
      />
      <SmallButton
        leftIcon={<GrPowerReset />}
        buttonText="Reset Filter"
        textTransform="Capitalize"
        handleClick={resetFilterFunction}
      />
    </>
  );
};

export default FilterButtons;
