export const gatewaysOptions = [
  { label: "All", value: null },
  { label: "EntransactCredo", value: 1 },
  { label: "Remita", value: 2 },
];

export const canPayInstallmentOptions = [
  { label: "True", value: true },
  { label: "False", value: false },
];
