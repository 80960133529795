import { FC } from "react";
import styled from "styled-components";

interface InputProps {
  alignText?: string;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
`;

const Input = styled.input<InputProps>`
  align-self: stretch;
  background: var(--clr-white-100);
  outline: none;
  border: none;
  border: 1px solid var(--clr-grey-100);
  padding: 10px;
  text-align: ${(props) => props.alignText || `center`};
  transition: all 0.5s ease;
  width: 100%;
  min-width: 200px;
  border-radius: 10px;

  &:focus {
    border-color: var(--clr-black);
    color: var(--clr-black);
  }
`;

interface TextInputProps {
  placeholder?: string;
  alignText?: string;
  type?: string;
  max?: string;
  defaultValue?: any;
  value?: any;
  name?: string;
  onChangeHandler?: any;
  readOnly?: boolean;
}
const TextCenteredInput: FC<TextInputProps> = ({
  placeholder,
  alignText,
  type,
  max,
  defaultValue,
  value,
  name,
  onChangeHandler,
  readOnly,
}) => {
  return (
    <InputContainer>
      {placeholder && <Label>{placeholder}:</Label>}
      <Input
        placeholder={placeholder}
        defaultValue={defaultValue}
        alignText={alignText}
        type={type}
        max={max}
        value={value}
        name={name}
        onChange={onChangeHandler}
        readOnly={readOnly}
      />
    </InputContainer>
  );
};

export default TextCenteredInput;
