export const filter = (data: any, name: string, id: string) => {
  const res = data?.map((x: any) => {
    return {
      label: x?.[name],
      value: x?.[id],
    };
  });

  return res;
};

export const maxNumberInput = (el: any) => {
  if (el.value !== "") {
    if (parseInt(el.value) < parseInt(el.min)) {
      el.value = el.min;
    }
    if (parseInt(el.value) > parseInt(el.max)) {
      el.value = el.max;
    }
  }
};

export const admissionModeFilters = [
  { label: "Full Admission", value: 1 },
  { label: "Provisional Admission", value: 2 },
];


export const disabilityFilters = [
  { label: "none", value: 1 },
  { label: "Epilepsy", value: 2 },
  { label: "Sickle Cell", value: 3 },
];

export const advertMeansOptions = [
  { label: "Radio", value: 1 },
  { label: "Television", value: 2 },
  { label: "News_paper", value: 3 },
  { label: "Google", value: 4 },
  { label: "Friends", value: 5 },
  { label: "Facebook", value: 6 },
  { label: "Tweeter", value: 7 },
  { label: "Whatsapp", value: 8 },
  { label: "Staff", value: 9 },
  { label: "Students_of_Gouni", value: 10 },
  { label: "Others", value: 11 },
];
