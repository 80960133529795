import React from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { completeUndergraduateStudent } from "../../../Apis/Application/ApplicationForm";

const Declaration = () => {
  const dispatch = useAppDispatch();
  const {
    sendingApplicationForm,
    agreement,
    firstName,
    otherName,
    lastName,
    studentEmailId,
    mobileNumber,
    dateOfBirth,
    Gender,
    Disability,
    Religion,
    parentName,
    parentPhoneNumber,
    hobbies,
    emailAddress,
    contactAddress,
    kinFullName,
    kinPhoneNumber,
    KinContactAddress,
    kinRelationship,
    stateOfOrigin,
    localGovernment,
    addressLine1,
    OlevelResult,
    examType,
    examYear,
    examNumber,
    institutionName,
    courseOfStudy,
    cgpa,
    certificateObtained,
    classOfCertificate,
    dateOfGraduation,
    regNumber,
    score,
    year,
    firstSubjectId,
    firstSubjectScore,
    secondSubjectId,
    seconeSubjectScore,
    thirdSubjectId,
    thirdSubjectScore,
    forthSubjectId,
    forthSubjectScore,
    department,
    admissionMode,
    personId,
    admissionTypeId,
  } = useAppSelector(selectapplicationForm);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleOnCheck = (e: any) => {
    const { name, checked } = e.target;
    dispatch(getApplicationDetails({ name, value: checked }));
  };

  const handleSubmit = () => {
    completeUndergraduateStudent(
      firstName,
      lastName,
      otherName,
      lastName + " " + firstName + " " + otherName,
      dateOfBirth,
      Disability,
      emailAddress || studentEmailId,
      Gender,
      contactAddress,
      mobileNumber,
      stateOfOrigin,
      localGovernment,
      addressLine1,
      1,
      Religion,
      admissionTypeId,
      parentName,
      parentPhoneNumber,
      {
        nextOfKinFullName: kinFullName,
        mobilePhone: kinPhoneNumber,
        relationshipId: kinRelationship,
        contactAddress: KinContactAddress,
      },
      {
        regNumber,
        score,
        year,
        firstSubjectId,
        firstSubjectScore,
        secondSubjectId,
        seconeSubjectScore,
        thirdSubjectId,
        thirdSubjectScore,
        forthSubjectId,
        forthSubjectScore,
      },
      {
        firstsubjectArray: OlevelResult,
        secondSubjectArray: [],
        firstexamNumber: examNumber,
        firstexamType: examType,
        firstexamYear: examYear,
      },
      hobbies,
      {
        institution: institutionName,
        certificate_obtained: certificateObtained,
        class_of_certificate: classOfCertificate,
        course_of_study: courseOfStudy,
        date_of_graduation: dateOfGraduation,
        cgpa: cgpa,
      },
      personId,
      department,
      admissionMode
    );
  };

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  console.log(personId)

  return (
    <AuthPageLayout authText="Declaration">
      <FormContainer>
        <p>
          I certify that the information i have submitted of my application is
          true to best of my knowledge and belief. I understand that in event of
          any information being found false or incorrect, my admission is liable
          to be rejected/cancelled at any stage of the program. I undertake to
          abide by the disciplinary rules and regulations of the Godfrey Okeye
          University
          <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <input
              type="checkbox"
              name="agreement"
              onChange={handleOnCheck}
              checked={agreement}
            />
            <span>I Agree</span>
          </p>
        </p>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Application Name"
            name="applicationName"
            // handleChange={handleOnChange}
            value={firstName + lastName}
          />
          <TextInputWithIcon
            type="date"
            placeholder="Date"
            name="date"
            handleChange={handleOnChange}
            value={date}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Submit Application"
          disabled={false}
          handleClick={handleSubmit}
          loading={sendingApplicationForm}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default Declaration;
