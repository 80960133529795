export function calculateSum(array: any, property: any) {
  const total = array.reduce((accumulator: any, object: any) => {
    return accumulator + object[property];
  }, 0);

  return total;
}

export function onlyUnique(value: any, index: any, array: any) {
  return array.indexOf(value) === index;
}
