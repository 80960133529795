import { useState } from "react";
import { filter } from "../../Pages/PrivatePages/util";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import { selectUtil } from "../../Redux/features/utilsSlice";
import CustomTable2 from "../CustomTable2";
import { feeScheduleCols, feeMappingRows } from "./utils";

const FeeScheduleTable = () => {
  const { paymentFees, gettingPaymentFees } = useAppSelector(selectPayment);
  const { feeSchedule } = useAppSelector(selectUtil);
  const [clickedButton, setClickedButton] = useState(0);
  const dispatch = useAppDispatch();
  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );

  const rows = feeMappingRows(
    paymentFees,
    feeScheduleFilter,
    null,
    null,
    null,
    clickedButton,
    setClickedButton
  );

  const handlePagination = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageSize", value }));
  };


  return (
    <CustomTable2
      data={{ rows, columns: feeScheduleCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingPaymentFees}
      noRecord={paymentFees?.records?.length === 0 || !paymentFees}
      page={paymentFees?.currentPage}
      pageCount={paymentFees?.totalRecords}
      pageSize={paymentFees?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default FeeScheduleTable;
