import React from "react";
import ColumnChart from "../Prototypes/ColumnChart";
import { transactionOptions } from "./options";
import CardWrapper from "../../Components/Layouts/CardWrapper";
import ChartContainerLayout from "../../Components/Layouts/ChartContainerLayout";
import { useAppSelector } from "../../Redux/app/hooks";
import { selectReport } from "../../Redux/features/reportSlice";
import { onlyUnique } from "./utils";

const TransactionChart = () => {
  const { allTransactionChart, gettingAllTransactionChart } =
    useAppSelector(selectReport);

  const formatedData = allTransactionChart?.chartData?.map((data: any) => {
    return { date: new Date(data?.date)?.getFullYear(), amount: data?.amount };
  });

  const filteredYear = formatedData
    ?.map((data: any) => {
      return data?.date;
    })
    .filter(onlyUnique)
    ?.sort((a: any, b: any) => {
      return a - b;
    });

  const yearAmount = filteredYear?.map((year: any) => {
    const yearAmount = formatedData?.filter((x: any) => x?.date === year);
    const YearSum = yearAmount.reduce(
      (partialSum: any, a: any) => partialSum + Number(a?.amount),
      0
    );
    return { x: `${year}`, y: String(YearSum) };
  });

  const seriesData = [{ name: "Payments", data: yearAmount }];

  return (
    <CardWrapper>
      <ChartContainerLayout title="All Transactions">
        <ColumnChart
          options={transactionOptions}
          series={seriesData}
          title="App Usage"
          height="50vh"
          loading={gettingAllTransactionChart}
        />
      </ChartContainerLayout>
    </CardWrapper>
  );
};

export default TransactionChart;
