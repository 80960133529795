import React from "react";
import SmallColoredCardWithIcon from "../../../Components/Layouts/SmallColoredCardWithIcon";
import { PiStudentBold } from "react-icons/pi";
import { BsCreditCard2Back } from "react-icons/bs";
import { SummaryContainer } from "../style";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import { selectReport } from "../../../Redux/features/reportSlice";

const SummarySection = () => {
  const { paymentSummary, gettingPaymentSummary } =
    useAppSelector(selectPayment);
  const { gettingStudentsNumberBySession, studentsNumberBySession } =
    useAppSelector(selectReport);

  return (
    <SummaryContainer>
      <SmallColoredCardWithIcon
        bgColor="var(--clr-pink)"
        icon={<PiStudentBold />}
        title="Admitted Students"
        value={studentsNumberBySession?.admitedStudentCount}
        link="/students"
        loading={gettingStudentsNumberBySession}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-sky-blue)"
        icon={<PiStudentBold />}
        title="Converted Students"
        value={studentsNumberBySession?.convertedStudentCount}
        link="/students"
        loading={gettingStudentsNumberBySession}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-purple)"
        icon={<BsCreditCard2Back />}
        title="Online Transactions"
        value={paymentSummary?.onlineTransaction?.count}
        link="/payments"
        loading={gettingPaymentSummary}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-dark-blue)"
        icon={<BsCreditCard2Back />}
        title="Offline Transactions"
        value={paymentSummary?.offlineTransaction?.count}
        link="/payments"
        loading={gettingPaymentSummary}
      />
    </SummaryContainer>
  );
};

export default SummarySection;
