import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import CustomTable2 from "../CustomTable2";
import { hostelAllocationsCols, hostelAllocationRows } from "./utils";

const HostelAllocationTable = () => {
  const {
    gettingStudentsHostelAllocation,
    studentsHostelAllocation,
    assigningBunkToStudent,
  } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();
  const rows = hostelAllocationRows(
    studentsHostelAllocation,
    assigningBunkToStudent
  );

  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: hostelAllocationsCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingStudentsHostelAllocation}
      noRecord={
        studentsHostelAllocation?.records?.length === 0 ||
        !studentsHostelAllocation
      }
      page={studentsHostelAllocation?.currentPage}
      pageCount={studentsHostelAllocation?.totalRecords}
      pageSize={studentsHostelAllocation?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default HostelAllocationTable;
