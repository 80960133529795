import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../Redux/features/studentsSlice";
import CustomTable2 from "../CustomTable2";
import { examClearanceRows, examClearanceCols } from "./utils";

const ExamClearanceTable = () => {
  const {
    clearanceList,
    gettingClearance,
    clearanceEndDate,
    clearanceStartDate,
    updatingClearance,
  } = useAppSelector(selectStudents);
  const dispatch = useAppDispatch();
  const rows = examClearanceRows(
    clearanceList,
    clearanceEndDate,
    clearanceStartDate,
    updatingClearance
  );

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: examClearanceCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingClearance}
      noRecord={clearanceList?.length === 0}
      page={clearanceList?.currentPage}
      pageCount={clearanceList?.totalRecords}
      pageSize={clearanceList?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default ExamClearanceTable;
