import React from "react";
import { styled } from "styled-components";
import { media } from "../../Screens";

interface styleProps {
  flex?: string;
}
export const ChartContainer = styled.div<styleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--MWB-Background-Main, #fff);
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  flex: ${(props) => props.flex};

  ${media.tablet}{
  padding: 10px;
  }
`;

interface CardWrapperProp {
  children: any;
  flex?: string;
}

const CardWrapper = ({ children, flex }: CardWrapperProp) => {
  return <ChartContainer flex={flex}>{children}</ChartContainer>;
};

export default CardWrapper;
