import React from "react";
import AuthPageLayout from "../../../Components/Layouts/AuthPageLayout";
import { AuthRedirectionLink, FormContainer } from "../styles";
import TextInputWithIcon from "../../../Components/Form/Inputs/TextInputWithIcon";
import { CiUser } from "react-icons/ci";
import { FiLock } from "react-icons/fi";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import {
  getAccountDetails,
  selectUser,
} from "../../../Redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { loginUser } from "../../../Apis/Auth/Account";
import { toast } from "react-toastify";

const Login = () => {
  const { username, password, logingUser, otp } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAccountDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (password && username && otp) {
      loginUser(username, password, otp);
    } else {
      toast.error("Please fill fields to proceed");
    }
  };

  return (
    <AuthPageLayout authText="Login">
      <FormContainer>
        <TextInputWithIcon
          type="text"
          placeholder="Enter Username"
          icon={<CiUser />}
          value={username}
          name="username"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="password"
          placeholder="Enter Password"
          icon={<FiLock />}
          value={password}
          name="password"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="text"
          placeholder="Enter OTP"
          icon={<FiLock />}
          value={otp}
          name="otp"
          handleChange={handleOnChange}
        />

        <BigButton
          buttonText="Login"
          disabled={false}
          handleClick={handleSubmit}
          loading={logingUser}
        />
      </FormContainer>
      <AuthRedirectionLink to="/reset-password/2FA">
        Reset Password
      </AuthRedirectionLink>
    </AuthPageLayout>
  );
};

export default Login;
