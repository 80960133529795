import React, { FC } from "react";
import styled from "styled-components";
import Loader from "../../../Loaders/MoonLoader";

interface BigButtonProps {
  buttonText?: string;
  textTransform?: string;
  handleClick?: any;
  disabled?: boolean;
  loading?: boolean;
}

const Conatainer = styled.button<BigButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  outline: none;
  background: var(--clr-primary-900);
  padding: 20px;
  color: var(--clr-white-100);
  border-radius: 3px;
  text-transform: ${(props) =>
    props?.textTransform ? props?.textTransform : `uppercase`};
  cursor: pointer;
  opacity: ${(props) => (props?.disabled ? `0.25` : `1`)};

  transition: all 0.25s ease-in-out;
  box-shadow: var(--box-shadow-2);


  &:hover{
  background: var( --clr-primary-100);
  box-shadow: var(--box-shadow);
}
`;
const BigButton: FC<BigButtonProps> = ({
  buttonText,
  disabled,
  textTransform,
  handleClick,
  loading,
}) => {
  return (
    <Conatainer
      disabled={disabled}
      textTransform={textTransform}
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick();
      }}
    >
      {loading ? <Loader color="#fff" size="12" /> : buttonText}
    </Conatainer>
  );
};

export default BigButton;
