/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
// import PaymentRoute from "./PaymentRoute";
import PrivateRoutes from "./PrivateRoutes";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  restoreCurrentUser,
  selectUser,
  setUserPermission,
} from "../Redux/features/userSlice";

const Router = () => {
  const { currentUser } = useAppSelector(selectUser);
  const adminUser = JSON.parse(
    localStorage.getItem("currentAdminUser") || "{}"
  );
  const persistRoot = JSON.parse(
    localStorage.getItem("persist:root") || "{}"
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adminUser && persistRoot) {
      dispatch(restoreCurrentUser(adminUser?.response));
      dispatch(setUserPermission(adminUser?.permissions));
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        {currentUser?.username && (
          <Route path="/*" element={<PrivateRoutes />} />
        )}
        {!currentUser?.username && <Route path="/*" element={<AuthRoutes />} />}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
