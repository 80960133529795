import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface reportState {
  gettingReportDownload: boolean;
  reportDownload: object | null;
  gettingStudentsNumberBySession: boolean;
  studentsNumberBySession: object | null | any;
  gettingAllGeneratedReportBySession: boolean;
  allGeneratedReportBySession: object | null | any;
  gettingAllTransactionChart: boolean;
  allTransactionChart: object | null | any;

  // report states
  studentReportName: string | null;
  paymentReportName: string | null;
  hostelReportName: string | null;
  pendingAdmissionsReportName: string | null;
}

const initialState: reportState = {
  gettingReportDownload: false,
  reportDownload: null,
  gettingAllGeneratedReportBySession: false,
  allGeneratedReportBySession: null,
  gettingAllTransactionChart: false,
  allTransactionChart: null,
  gettingStudentsNumberBySession: false,
  studentsNumberBySession: null,

  // report states
  studentReportName: null,
  paymentReportName: null,
  hostelReportName: null,
  pendingAdmissionsReportName: null,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setGettingReportDownload: (state, action) => {
      state.gettingReportDownload = action.payload;
    },
    setReportDownload: (state, action) => {
      state.reportDownload = action.payload;
    },
    setGettingAllGeneratedReportBySession: (state, action) => {
      state.gettingAllGeneratedReportBySession = action.payload;
    },
    setAllGeneratedReportBySession: (state, action) => {
      state.allGeneratedReportBySession = action.payload;
    },
    setGettingAllTransactionChart: (state, action) => {
      state.gettingAllTransactionChart = action.payload;
    },
    setAllTransactionChart: (state, action) => {
      state.allTransactionChart = action.payload;
    },
    setGettingStudentsNumberBySession: (state, action) => {
      state.gettingStudentsNumberBySession = action.payload;
    },
    setStudentsNumberBySession: (state, action) => {
      state.studentsNumberBySession = action.payload;
    },
    clearReport: (state) => {
      state.gettingReportDownload = false;
      state.reportDownload = null;
      state.gettingAllGeneratedReportBySession = false;
      state.allGeneratedReportBySession = null;
      state.gettingAllTransactionChart = false;
      state.allTransactionChart = null;
      state.gettingStudentsNumberBySession = false;
      state.studentsNumberBySession = null;

      // report states
      state.studentReportName = null;
      state.paymentReportName = null;
      state.hostelReportName = null;
      state.pendingAdmissionsReportName = null;
    },
    getReportsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingReportDownload,
  setReportDownload,
  getReportsFormDetails,
  setAllGeneratedReportBySession,
  setGettingAllGeneratedReportBySession,
  setGettingStudentsNumberBySession,
  setStudentsNumberBySession,
  setAllTransactionChart,
  setGettingAllTransactionChart,
  clearReport,
} = reportSlice.actions;
export const selectReport = (state: RootState) => state.report;

export default reportSlice.reducer;
