export const genderOptions = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
];

export const statusOptions = [
  {
    label: "Full Payment",
    value: 1,
  },
  {
    label: "Part Payment",
    value: 2,
  },
];

export const hostelStatusOptions = [
  {
    label: "Enable",
    value: 1,
  },
  {
    label: "Disable",
    value: 2,
  },
];

export const levelOptions = [
  {
    label: "100 Level",
    value: 1,
  },
  {
    label: "200 Level",
    value: 2,
  },
  {
    label: "300 Level",
    value: 3,
  },
  {
    label: "400 Level",
    value: 4,
  },
  {
    label: "500 Level",
    value: 5,
  },
];

export const multiLevelOptions = [
  {
    label: "100 Level",
    value: 1,
  },
  {
    label: "200 Level",
    value: 2,
  },
  {
  label: "300 Level",
    value: 3,
  },
  {
    label: "400 Level",
    value: 4,
  },
  {
    label: "500 Level",
    value: 5,
  },
];

export const programmeOptions = [
  {
    label: "All",
    value: null,
  },
  {
    label: "UNDERGRADUATE_FULL_tIME",
    value: 14,
  },
  {
    label: "JUPEB",
    value: 15,
  },
  {
    label: "POSTGRADUATE_FULL_TIME",
    value: 16,
  },
  {
    label: "HND_PART_TIME",
    value: 17,
  },
];


export const bunkTypeOptions = [
  {
    label: "LOWER_BUNK" ,
    value: 1,
  },
  {
    label: "UPPER_BUNK",
    value: 2,
  },
];


export const sessionStatusOptions = [
  {
    label: "Enabled",
    value: 1,
  },
  {
    label: "Disabled",
    value: 2,
  },
];
