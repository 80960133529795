import { Dropdown } from "rsuite";
import styled from "styled-components";
import { FC } from "react";

const DropDownContainer = styled(Dropdown)`
  // .rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn {
  //     background: rgba(255, 255, 255, 0.45);
  //     display: flex;
  //     height: 25px;
  //     padding: 10px;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 10px;
  //     border-radius: 5px;
  //     border: 1px solid var(--clr-black);
  //     color: var(--clr-black);
  //     font-size: 10px;
  //     font-style: normal;
  //     font-weight: var(--fw-regular);
  //     line-height: normal;

  //     &:hover {
  //       background: rgba(255, 255, 255, 0.45);
  //       color: var(--clr-black);
  //     }

  // }
  // .rs-btn > .rs-dropdown-toggle-caret {
  //         margin-left: 4px;
  //         position: relative;
  //         right: 0;
  //         top: 0;
  //     }

  .rs-dropdown-menu {
    left: -100px;
    max-height: 300px;
    overflow-y: auto;
  }
`;

const CustomDropdown = ({ ...props }) => (
  <DropDownContainer {...props}>
    {props?.dropdownItems?.map((item: any) => (
      <Dropdown.Item
        onClick={() => props?.handleDropdownSelect(props?.name, item?.value)}
      >
        {item?.label}
      </Dropdown.Item>
    ))}
  </DropDownContainer>
);

interface DropdownProps {
  value: any;
  dropdownItems: any;
  title: string;
  handleDropdownSelect: any;
  name: string;
}

const ReactSuiteDropdown: FC<DropdownProps> = ({
  value,
  dropdownItems,
  title,
  handleDropdownSelect,
  name,
}) => (
  <>
    <CustomDropdown
      title={
        !dropdownItems?.filter((x: any) => x?.value === value)[0]?.label
          ? title
          : dropdownItems?.filter((x: any) => x?.value === value)[0]?.label
      }
      trigger={["click"]}
      dropdownItems={dropdownItems}
      handleDropdownSelect={handleDropdownSelect}
      name={name}
    />
  </>
);
export default ReactSuiteDropdown;
