import { filter } from "../../Pages/PrivatePages/util";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectAdmission } from "../../Redux/features/admissionSlice";
import { getStudentsFormDetails } from "../../Redux/features/studentsSlice";
import { selectUtil } from "../../Redux/features/utilsSlice";
import CustomTable2 from "../CustomTable2";
import { StudentRows, pendingStudentsCols } from "./utils";

const PendingAdmissionsTable = () => {
  const { pendingAdmissions, gettingPendingAdmissions } =
    useAppSelector(selectAdmission);
  const { sessions, departments } = useAppSelector(selectUtil);

  const sessionOptions = filter(sessions, "sessionName", "sessionId");

  const dispatch = useAppDispatch();
  const rows = StudentRows(pendingAdmissions, sessionOptions, departments);

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: pendingStudentsCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingPendingAdmissions}
      noRecord={pendingAdmissions?.records?.length === 0 || !pendingAdmissions}
      page={pendingAdmissions?.currentPage}
      pageCount={pendingAdmissions?.totalRecords}
      pageSize={pendingAdmissions?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default PendingAdmissionsTable;
