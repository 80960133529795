import { useState } from "react";
import { PiCaretUpDownFill } from "react-icons/pi";
import { Dropdown, Popover, Whisper, Button } from "rsuite";
import styled from "styled-components";

const ParentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 12px;
  color: #70707c;
`;

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 60px;
  padding: 12px 15px;
  border: 1px solid var(--clr-grey-100);
  background: var(--clr-white-100);
  transition: all 0.5s ease;
  justify-content: space-between;

  &:focus-within {
    border-color: var(--clr-black);

    svg {
      color: var(--clr-black);
    }

    .sep {
      background: var(--clr-black);
    }
  }
`;

const PopoverContainer = styled(Popover)`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--clr-white-100);
  backdrop-filter: blur(2px);
  min-width: 100px;
  max-width: 200px;
  max-height: 300px;
  overflow-y: auto;

  .rs-dropdown-item {
    color: var(--clr-black);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: 16px;
  }
`;

const renderSpeaker = (
  { onClose, left, top, className, ...rest }: any,
  ref: any,
  dropdownItems: string[],
  handleDropdownSelect: any,
  name?: string,
  setOpen?: any
) => {
  const handleSelect = (e: any) => {
    handleDropdownSelect(name, e?.value);
    setOpen(false);
  };
  return (
    <PopoverContainer
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu onSelect={handleSelect}>
        {dropdownItems?.map((item: any, index: any) => (
          <Dropdown.Item eventKey={item} value={item?.value}>
            {item?.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </PopoverContainer>
  );
};

interface DropdownProps {
  dropdownItems?: any;
  title?: any;
  handleDropdownSelect?: any;
  name?: string;
  value?: any;
}

const CustomRegularDropdown = ({
  dropdownItems,
  title,
  handleDropdownSelect,
  name,
  value,
}: DropdownProps) => {
  const [open, setOpen] = useState<any>(false);

  return (
    <ParentContainer>
      <Label>{title}:</Label>
      <Whisper
        open={open}
        onClick={() => setOpen(true)}
        placement="bottomStart"
        trigger="click"
        speaker={renderSpeaker(
          ``,
          ``,
          dropdownItems,
          handleDropdownSelect,
          name,
          setOpen
        )}
      >
        <ButtonContainer>
          <span>
            {dropdownItems?.filter((x: any) => x?.value === value)[0]?.label ||
              title}
          </span>
          <PiCaretUpDownFill />
        </ButtonContainer>
      </Whisper>
    </ParentContainer>
  );
};

export default CustomRegularDropdown;
