import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  PageTitle,
} from "../style";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { useParams } from "react-router-dom";
import { editConvertedStudentInfo } from "../../../Apis/Private/students";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { selectUser } from "../../../Redux/features/userSlice";

const ConvertedStudentNextOfKin = () => {
  const {
    personId,
    departmentName,
    facultyName,
    stateName,
    religionName,
    localGovernmentName,
    admissionsId,
    prospectiveStudentId,
    programmeId,
    programmeName,
    sessionId,
    sessionName,
    admitted,
    refererName,
    refferalId,
    advertMeans,
    departmentId,
    facultyId,
    appicationNumber,
    firstName,
    lastName,
    mobilePhone,
    contactAddress,
    emailAddress,
    parentName,
    stateId,
    religionId,
    localGovernmentId,
    editingStudentInfo,
    genderId,
    disabilityId,
    parentNumber,
    dateOfBirth,
    nextOfKinAddress,
    nextOfKinPhoneNo,
    nextOfKinFullname,
    tribeId,
    tribeName,
    matricNumber,
  } = useAppSelector(selectStudents);
  const { userPermissions } = useAppSelector(selectUser);
  const { Id } = useParams();
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleUpdate = () => {
    editConvertedStudentInfo(
      personId,
      departmentName,
      facultyName,
      stateName,
      religionName,
      localGovernmentName,
      admissionsId,
      prospectiveStudentId,
      programmeId,
      programmeName,
      sessionId,
      sessionName,
      admitted,
      refererName,
      refferalId,
      advertMeans,
      departmentId,
      facultyId,
      appicationNumber,
      firstName,
      lastName,
      mobilePhone,
      contactAddress,
      emailAddress,
      parentName,
      stateId,
      religionId,
      localGovernmentId,
      genderId,
      disabilityId,
      parentNumber,
      dateOfBirth,
      nextOfKinAddress,
      nextOfKinPhoneNo,
      nextOfKinFullname,
      tribeId,
      tribeName,
      matricNumber
    );
  };

  return (
    <>
      <PageTitle>Next of Kin</PageTitle>
      <NavigationLink
        to={`/Students/Converted/${Id}/profile`}
        className="flex-centered-items"
      >
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <div className="flex-centered-items" style={{ width: `100%` }}>
              <TextCenteredInput
                placeholder="Fullname"
                value={nextOfKinFullname}
                name="nextOfKinFullname"
                alignText="left"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="PhoneNo"
                value={nextOfKinPhoneNo}
                name="nextOfKinPhoneNo"
                onChangeHandler={handleOnChange}
                alignText="left"
              />
            </div>
            <div className="flex-centered-items" style={{ width: `100%` }}>
              <TextCenteredInput
                placeholder="Address"
                value={nextOfKinAddress}
                alignText="left"
                name="nextOfKinAddress"
                onChangeHandler={handleOnChange}
              />
            </div>
            <BigButton
              buttonText="Save & Continue"
              textTransform="capitalize"
              disabled={!userPermissions?.includes("edit-students-details")}
              handleClick={handleUpdate}
              loading={editingStudentInfo}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default ConvertedStudentNextOfKin;
