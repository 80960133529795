import React, { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { FaPrint } from "react-icons/fa";
import styled from "styled-components";
import { media } from "../../Screens";
import { ItemsCenterJustifyBtw } from "../../Pages/AuthPages/Applications/styles";
import SmallButton from "../Form/Buttons/SmallButton";

interface ReactPrintProp {
  children: any;
  pay?: boolean;
  etransactPaymentLink?: string;
  remitaObject?: any;
}

const Printer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.tablet} {
    width: 70%;
  }
`;

const ReactPrint: FC<ReactPrintProp> = ({ children }) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    removeAfterPrint: true,
  });

  return (
    <Printer>
      <div ref={contentToPrint}>{children}</div>
      <ItemsCenterJustifyBtw className="justify-end">
        <SmallButton
          buttonText="Print"
          leftIcon={<FaPrint />}
          handleClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        />
      </ItemsCenterJustifyBtw>
    </Printer>
  );
};

export default ReactPrint;
