// import { useState } from "react";
import { styled } from "styled-components";
import { PiCaretRight } from "react-icons/pi";
import { media } from "../../Screens";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--clr-white-100);
  background: rgba(255, 255, 255, 0.5);

  ${media.tablet} {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
`;

const HeaderTitle = styled.div`
  color: #2e2e3a;
  font-size: 18px;
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
`;

const HeaderRight = styled.div`
  svg {
    width: 18px;
    height: 18px;
  }
  background: var(--clr-white);
  border-radius: 3px;
  padding: 5px;
`;

// const Footer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   align-self: stretch;
// `;

// const DurationButton = styled.button`
//   display: flex;
//   padding: 5px 10px;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   color: var(--clr-black);
//   font-size: 10px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;

//   &.active {
//     border-radius: 2px;
//     background: var(--clr-primary-900);
//     box-shadow: 0px 4px 20px 0px rgba(154, 18, 36, 0.15);
//     color: var(--clr-white-100);
//   }
// `;

interface ChartContainerProp {
  children: any;
  title: string;
}

const ChartContainerLayout = ({ children, title }: ChartContainerProp) => {
  // const [activeButton, setActiveButton] = useState(1);

  return (
    <Container>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderRight>
          <PiCaretRight />
        </HeaderRight>
      </Header>
      {children}
      {/* <Footer>
        <DurationButton className={`${activeButton === 1 && `active`}`} onClick={() => setActiveButton(1)}>
          Day
        </DurationButton>
        <DurationButton className={`${activeButton === 2 && `active`}`} onClick={() => setActiveButton(2)}>
          Week
        </DurationButton>
        <DurationButton className={`${activeButton === 3 && `active`}`} onClick={() => setActiveButton(3)}>
          Month
        </DurationButton>
        <DurationButton className={`${activeButton === 4 && `active`}`} onClick={() => setActiveButton(4)}>
          Year
        </DurationButton>
      </Footer> */}
    </Container>
  );
};

export default ChartContainerLayout;
