import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  MainBody,
  MainBodyContainer,
  RouteContainer,
} from "../Pages/PrivatePages/style";
import Header from "../Components/Nav/Header";
import Sidebar from "../Components/Nav/Sidebar";
import { useEffect } from "react";
import {
  getActiveSession,
  getAdmissionTypes,
  getBankList,
  getDepartments,
  getFaculties,
  getFeeschedule,
  getHostel,
  getHostelBlock,
  getPaymentMean,
  getSemester,
  getSessions,
} from "../Apis/Private/Utils";
import { useAppSelector } from "../Redux/app/hooks";
import { RouteComponents } from "./utils";
import { selectUser } from "../Redux/features/userSlice";
import ApplicationRouter from "./ApplicationRoute";
import { selectStudents } from "../Redux/features/studentsSlice";
const PrivateRoutes = () => {
  const { currentUser, userPermissions } = useAppSelector(selectUser);
  const { FacultyId } = useAppSelector(selectStudents);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    getSessions();
    getSemester();
    getFeeschedule();
    getPaymentMean();
    getHostel();
    getFaculties();
    getActiveSession();
    getHostelBlock();
    getBankList();
    getAdmissionTypes();

    if (
      (currentUser?.roleId === 11 ||
        currentUser?.roleId === 9 ||
        currentUser?.roleId === 14) &&
      pathname === "/"
    ) {
      navigate("/students");
    }
    if (currentUser?.roleId === 6 && pathname === "/") {
      navigate("/payments");
    }
  }, [currentUser, pathname, navigate]);

  useEffect(() => {
    getDepartments(FacultyId);
  }, [FacultyId]);

  return (
    <RouteContainer>
      <Header />
      <MainBodyContainer>
        <Sidebar />
        <MainBody>
          <Routes>
            {RouteComponents?.map((route) => (
              <>
                {userPermissions?.includes(route.permission) && (
                  <Route path={route?.routeLink} element={route?.component} />
                )}
              </>
            ))}
          </Routes>
          {userPermissions?.includes("register-student") && (
            <ApplicationRouter />
          )}
        </MainBody>
      </MainBodyContainer>
    </RouteContainer>
  );
};

export default PrivateRoutes;
