import { selectUtil } from "../../Redux/features/utilsSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  resetStudentParams,
  selectStudents,
} from "../../Redux/features/studentsSlice";
import ReactSuiteDropdown from "../Form/Dropdowns/NewDropdown";
import { filter } from "../../Pages/PrivatePages/util";
import { FilterContainer } from "../../Pages/PrivatePages/style";
import FilterButtons from "./FilterButtons";
import { FC } from "react";

interface AdmittedStudentFilterProps {
  filterFunction?: any;
}

const AdmittedStudentFilter: FC<AdmittedStudentFilterProps> = ({
  filterFunction,
}) => {
  const { departments, faculties, sessions } = useAppSelector(selectUtil);
  const { SessionId, DepartmentId, FacultyId } = useAppSelector(selectStudents);

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const facultyFilter = filter(faculties, "facultyName", "facultyId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  return (
    <FilterContainer className="flex-centered-items">
      <ReactSuiteDropdown
        dropdownItems={facultyFilter}
        title={`Faculty`}
        handleDropdownSelect={handleSelect}
        name="FacultyId"
        value={FacultyId}
      />
      <ReactSuiteDropdown
        dropdownItems={departmentFilter}
        title="Department"
        handleDropdownSelect={handleSelect}
        name="DepartmentId"
        value={DepartmentId}
      />
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => dispatch(resetStudentParams())}
      />
    </FilterContainer>
  );
};

export default AdmittedStudentFilter;
