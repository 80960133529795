export const studentTypes = [
  {
    label: `Admitted`,
    value: 1,
  },
  {
    label: `Converted`,
    value: 2,
  },
];
