/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { getAllStudents } from "../../../Apis/Private/students";
import { getReportsFormDetails } from "../../../Redux/features/reportSlice";
import CourseRegTable from "../../../Tables/AllStudents/CourseRegTable";
import CourseRegFilter from "../../../Components/Filters/CourseRegfilter";
import { toast } from "react-toastify";
const CourseRegistration = () => {
  const {
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    SearchPrams,
    LevelId,
  } = useAppSelector(selectStudents);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleCourseRegFetch = () => {
    dispatch(getStudentsFormDetails({ name: "allStudents", value: null }));
    if (SessionId) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        2,
        PageSize,
        PageNumber
      );
    } else {
      toast.info("Please select at least session to filter");
    }
  };

  useEffect(() => {
    dispatch(getStudentsFormDetails({ name: "allStudents", value: null }));
    if (SearchPrams) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        2,
        PageSize,
        PageNumber
      );
    }
    if (SessionId) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        2,
        PageSize,
        PageNumber
      );
    }
  }, [SearchPrams, PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Course Registration</PageTitle>
      <ItemsCenterJustifyBtw className="justify-between">
        <OutlinedSearchInput
          placeholder="Search by name or email"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={getStudentsFormDetails}
        />
        <CourseRegFilter filterFunction={handleCourseRegFetch} />
      </ItemsCenterJustifyBtw>
      <CourseRegTable />
    </>
  );
};

export default CourseRegistration;
