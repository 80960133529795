import React from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { useNavigate } from "react-router-dom";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { toast } from "react-toastify";

export const nextOfKinRelationship = [
  { label: "Mother", value: 1 },
  { label: "Father", value: 2 },
  { label: "Brother", value: 3 },
  { label: "Sister", value: 4 },
  { label: "Uncle", value: 5 },
  { label: "Aunt", value: 6 },
  { label: "Cousin", value: 7 },
  { label: "Guardian", value: 8 },
  { label: "Other", value: 9 },
];

const NextOfKinDetails = () => {
  const dispatch = useAppDispatch();
  const {
    kinFullName,
    KinEmail,
    kinPhoneNumber,
    kinRelationship,
    KinContactAddress,
  } = useAppSelector(selectapplicationForm);
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (
      kinFullName &&
      KinEmail &&
      kinPhoneNumber &&
      kinRelationship &&
      KinContactAddress
    ) {
      navigate("/address-info");
    } else {
      toast.info("Please fill all fields");
    }
  };

  return (
    <AuthPageLayout authText="next of kin Details">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Next Of Kin Full Name"
            name="kinFullName"
            handleChange={handleOnChange}
            value={kinFullName}
          />
          <TextInputWithIcon
            type="email"
            placeholder="Next Of Kin Email"
            name="KinEmail"
            handleChange={handleOnChange}
            value={KinEmail}
          />
          <TextInputWithIcon
            type="tel"
            placeholder="Mobile Number"
            name="kinPhoneNumber"
            handleChange={handleOnChange}
            value={kinPhoneNumber}
            characterLimit={14}
          />
          {/* <PhoneNumberInput
            placeholder="Mobile Number"
            name="kinPhoneNumber"
            handleChange={handlePhoneChange}
            value={kinPhoneNumber}
          /> */}
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Next Of Kin Contact Address"
            name="KinContactAddress"
            handleChange={handleOnChange}
            value={KinContactAddress}
            characterLimit={48}
          />
          <CustomRegularDropdown
            title="Relationship"
            name="kinRelationship"
            handleDropdownSelect={handleSelect}
            dropdownItems={nextOfKinRelationship}
            value={kinRelationship}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default NextOfKinDetails;
