/* eslint-disable react/require-default-props */
import { FC } from "react";
import Chart from "react-apexcharts";
import Loader from "../../Loaders/MoonLoader";

interface ColumnChartProps {
  series: any;
  options: any;
  title?: string;
  height?: string;
  loading?: boolean;
}

const ColumnChart: FC<ColumnChartProps> = ({
  series,
  options,
  title,
  height,
  loading,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: height || "50vh",
        // padding: "15px 5px",
        borderRadius: "5px",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader color={"#3a0ca3"} size={20} />
        </div>
      ) : (
        <>
          {series && (
            <Chart
              type="bar"
              width="100%"
              height="100%"
              series={series}
              options={options}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ColumnChart;
