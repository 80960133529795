import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectUtil } from "../../Redux/features/utilsSlice";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import ReactSuiteDropdown from "../../Components/Form/Dropdowns/NewDropdown";
import { filter } from "../../Pages/PrivatePages/util";

const FilterSection = () => {
  const { sessions, semesters } = useAppSelector(selectUtil);
  const { SessionId, SemesterId } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  return (
    <div className="flex-centered-items flex-wrap">
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
      <ReactSuiteDropdown
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="SemesterId"
        value={SemesterId}
      />
    </div>
  );
};

export default FilterSection;
