import { FormContainer } from "../../Pages/AuthPages/styles";
import CustomModal from "./CustomModal";
import TextArea from "../ApplicationComponents/Form/Inputs/TextArea";
import Swal, { SweetAlertOptions } from "sweetalert2";
import SmallButton from "../Form/Buttons/SmallButton";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getAdmissionFormDetails,
  selectAdmission,
} from "../../Redux/features/admissionSlice";
import UnderlinedSelector from "../Form/Dropdowns/UnderlinedSelector";
import { grantAdmission } from "../../Apis/Private/Admissions";
import { toast } from "react-toastify";

const admissionModeOPtions = [
  {
    label: "Full Admission",
    value: 1,
  },
  {
    label: "Provisional Admission",
    value: 2,
  },
];

const AdmitStudentModal = () => {
  const { reason, personId, grantingAdmission, admissionMode } =
    useAppSelector(selectAdmission);
  const dispatch = useAppDispatch();

  const handleTextAreaChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(getAdmissionFormDetails({ name, value }));
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getAdmissionFormDetails({ name, value }));
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure to admit this student?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    } as SweetAlertOptions).then((res) => {
      if (res?.isConfirmed) {
        if (admissionMode && personId) {
          grantAdmission(personId, admissionMode, reason);
        } else {
          toast.error("Please select admission type");
        }
      }
    });
  };

  return (
    <CustomModal
      id={"admit_student_modal"}
      content={
        <FormContainer
          style={{
            width: "100%",
            marginTop: "20px;",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label
            style={{ fontSize: "15px", fontWeight: "bold", color: "#000" }}
          >
            Reason for admitting student
          </label>
          <UnderlinedSelector
            title={"Admission Mode"}
            name="admissionMode"
            value={admissionMode}
            handleDropdownSelect={handleSelect}
            dropdownItems={admissionModeOPtions}
          />
          {admissionMode === 2 && (
            <TextArea
              placeholder="Type message..."
              alignText="left"
              value={reason}
              onChange={handleTextAreaChange}
              name="reason"
            />
          )}
          <SmallButton
            buttonText="Submit"
            textTransform="capitalize"
            handleClick={handleSubmit}
            loading={grantingAdmission}
          />
        </FormContainer>
      }
    />
  );
};

export default AdmitStudentModal;
