import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../Redux/features/studentsSlice";
import CustomTable2 from "../CustomTable2";
import { StudentRows, studentsCols } from "./utils";

const AllStudentsTable = () => {
  const { allStudents, gettingAllStudents, semesterId, StudentType } =
    useAppSelector(selectStudents);
  const dispatch = useAppDispatch();
  const rows = StudentRows(allStudents, semesterId, null, StudentType);

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: studentsCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingAllStudents}
      noRecord={allStudents?.records?.length === 0 || !allStudents}
      page={allStudents?.currentPage}
      pageCount={allStudents?.totalRecords}
      pageSize={allStudents?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default AllStudentsTable;
