import { filter } from "../../Pages/PrivatePages/util";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import { selectUtil } from "../../Redux/features/utilsSlice";
import CustomTable2 from "../CustomTable2";
import { feeMappingCols, feeMappingRows } from "./utils";

const FeeMappingsTable = () => {
  const { feeMappings, gettingFeeMappings } = useAppSelector(selectPayment);
  const { feeSchedule, departments, semesters, sessions } =
    useAppSelector(selectUtil);
  const dispatch = useAppDispatch();
  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const feeScheduleFilter = filter(
    feeSchedule,
    "feeScheduleName",
    "feeScheduleId"
  );

  const rows = feeMappingRows(
    feeMappings?.records,
    feeScheduleFilter,
    departmentFilter,
    sessionFilter,
    semesterFilter
  );

  const handlePagination = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageSize", value }));
  };
  return (
    <CustomTable2
      data={{ rows, columns: feeMappingCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingFeeMappings}
      noRecord={feeMappings?.records?.length === 0 || !feeMappings}
      page={feeMappings?.currentPage}
      pageCount={feeMappings?.totalRecords}
      pageSize={feeMappings?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default FeeMappingsTable;
