import { useEffect, useState } from "react";
import { NavContainer, NavItem, NavMenu, SidebarContainer } from "./style";
import { Sidenav } from "rsuite";
import "rsuite/dist/rsuite.css";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectNav, toggleSidebar } from "../../../Redux/features/navSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { resetHostelParams } from "../../../Redux/features/hostelSlice";
import { resetPaymentParams } from "../../../Redux/features/paymentSlice";
import { resetStudentParams } from "../../../Redux/features/studentsSlice";
import { selectUser } from "../../../Redux/features/userSlice";
import { NavItems } from "./Utils";
import { resetAdmissions } from "../../../Redux/features/admissionSlice";
import { clearReport } from "../../../Redux/features/reportSlice";

const Sidebar = () => {
  // const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const { showSidebar } = useAppSelector(selectNav);
  const { userPermissions } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    pathname === `/` && setActiveKey("1");
    pathname.includes(`/Students`) && setActiveKey("2-1");
    pathname.includes(`/Students/course-registration`) && setActiveKey("2-2");
    pathname === `/payments` && setActiveKey("3-1");
    pathname === `/payments/view-payments` && setActiveKey("3-1");
    pathname === `/payments/verify-payments` && setActiveKey("3-2");
    pathname === `/payments/upload-offline-payment` && setActiveKey("3-3");
    pathname === `/payments//payments/exports` && setActiveKey("3-5");
    pathname === `/hostels` && setActiveKey("4-1");
    pathname === `/hostel/view-hostel-session` && setActiveKey("4-2");
    pathname === `/hostel/view-hostel-blocks` && setActiveKey("4-3");
    pathname === `/hostel/view-block-rooms` && setActiveKey("4-4");
    pathname === `/hostel/bunk-settings` && setActiveKey("4-5");
    pathname === `/hostel/allocate-hostel` && setActiveKey("4-7");
    pathname === `/hostel/allocation-report` && setActiveKey("4-6");
    // pathname === `/hostel/exports` && setActiveKey("4-9");
    // pathname === `/hostel/report` && setActiveKey("4-7");
    pathname === `/settings/fee-mapping` && setActiveKey("5-1");
    pathname === `/settings/exam-clearance` && setActiveKey("5-2");
    pathname === `/settings/deferment` && setActiveKey("5-3");
    pathname === `/settings/set-student-level` && setActiveKey("5-4");
    pathname === `/settings/profile` && setActiveKey("5-6");
    pathname === `/settings/security` && setActiveKey("5-6");
    pathname === `/settings/fee-schedule` && setActiveKey("5-7");
    pathname === `/settings/set-session` && setActiveKey("5-5");
    pathname === `/exports` && setActiveKey("6");
    pathname === `/admission/pending-admissions` && setActiveKey("7-1");
    pathname === `/admission/admitted-students` && setActiveKey("7-2");
    pathname === `/admission/initiated-admissions` && setActiveKey("7-3");
    pathname === `/application` && setActiveKey("7-4");
  }, [pathname]);

  return (
    <SidebarContainer showSidebar={showSidebar}>
      <Sidenav
        defaultOpenKeys={["1", "2", "3", "5", "4", "7"]}
        style={{ height: `100%`, overflowY: `auto`, background: `none` }}
      >
        <Sidenav.Body>
          <NavContainer
            activeKey={activeKey}
            onSelect={setActiveKey}
            appearance="tabs"
          >
            {NavItems?.map((nav: any) => (
              <>
                {nav?.hasChildren ? (
                  <NavMenu
                    placement="rightStart"
                    eventKey={nav?.id}
                    title={nav?.parentName}
                    style={{ background: `none` }}
                    icon={nav?.parentIcon}
                  >
                    {nav?.Children?.map((childNav: any) => (
                      <>
                        {userPermissions?.includes(childNav?.permission) && (
                          <NavItem
                            eventKey={childNav?.id}
                            icon={childNav?.childIcon}
                            onClick={() => {
                              navigate(`${childNav?.childLink}`);
                              dispatch(resetHostelParams());
                              dispatch(resetPaymentParams());
                              dispatch(resetStudentParams());
                              dispatch(resetAdmissions());
                              dispatch(clearReport());
                              dispatch(toggleSidebar());
                            }}
                          >
                            {childNav?.childName}
                          </NavItem>
                        )}
                      </>
                    ))}
                  </NavMenu>
                ) : (
                  <>
                    {userPermissions?.includes(nav?.permission) && (
                      <NavItem
                        eventKey={nav?.id}
                        icon={nav?.parentIcon}
                        onClick={() => {
                          navigate(`${nav?.parentLink}`);
                          dispatch(resetHostelParams());
                          dispatch(resetPaymentParams());
                          dispatch(resetStudentParams());
                          dispatch(resetAdmissions());
                          dispatch(clearReport());
                        }}
                      >
                        {nav?.parentName}
                      </NavItem>
                    )}
                  </>
                )}
              </>
            ))}
          </NavContainer>
        </Sidenav.Body>
        {/* <Sidenav.Toggle expanded={expanded} onToggle={(expanded) => setExpanded(expanded)} /> */}
      </Sidenav>
    </SidebarContainer>
  );
};

export default Sidebar;
