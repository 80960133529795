import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import {
  setAssigningBunkToStudent,
  setCreatingHostel,
  setCreatingHostelBlock,
  setGettingBunkSettings,
  setEditingHostel,
  setGeneratedReportById,
  setGettingGeneratedReportById,
  setGettingHostelAllocation,
  setGettingHostelBlocks,
  setGettingHostelBySession,
  setGettingHostels,
  setGettingRoomBunks,
  setGettingStudentsHostelAllocation,
  setGettingStudentsHostelBooking,
  setGettingStudentsHostelBookingDownloadable,
  setGettingStudentsHostelBookingSummary,
  setHostelAllocation,
  setHostelBlocks,
  setHostelBySession,
  setHostels,
  setRoomBunks,
  setSettingHostelSession,
  setSettingHostelSessionStatus,
  setStudentsHostelAllocation,
  setStudentsHostelBooking,
  setStudentsHostelBookingDownloadable,
  setStudentsHostelBookingSummary,
  setBunkSettings,
  setUpdatingBunkSettings,
  setCheckingHostelValidity,
  setHostelValidityData,
} from "../../Redux/features/hostelSlice";

export const getStudentsHostelAllocation = async (
  HostelId: number | null,
  SemesterId: number | null,
  BlockId: number | null,
  RoomBunkId: number | null,
  LevelId: number | null,
  GenderId: number | null,
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  StudentType: number | null,
  SearchPrams: string | null,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingStudentsHostelAllocation(true));
  const params: any = {
    HostelId,
    SemesterId,
    BlockId,
    RoomBunkId,
    LevelId,
    GenderId,
    SessionId,
    FacultyId,
    DepartmentId,
    StudentType,
    SearchPrams,
    PageSize,
    PageNumber,
  };
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  await api
    .get("/Hostel/Report/HostelBookings/verify", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsHostelAllocation(data));
      store.dispatch(setGettingStudentsHostelAllocation(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsHostelAllocation(false));
    });
};

export const getHostelAllocation = async (Id: number | null) => {
  store.dispatch(setGettingHostelAllocation(true));
  const params = {
    Id,
  };
  await api
    .get("/Reports/GetHostelAllocation", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelAllocation(data?.response));
      store.dispatch(setGettingHostelAllocation(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostelAllocation(false));
    });
};

export const getStudentsHostelBookings = async (
  HostelId: number | null,
  SemesterId: number | null,
  BlockId: number | null,
  LevelId: number | null,
  StartDate: string | null,
  EndDate: string | null,
  GenderId: number | null,
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  StudentType: number | null,
  SearchPrams: string | null,
  PageSize: number,
  PageNumber: number
) => {
  store.dispatch(setGettingStudentsHostelBooking(true));
  const params: any = {
    HostelId,
    SemesterId,
    BlockId,
    LevelId,
    StartDate,
    EndDate,
    GenderId,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetstudentsHostelBokings", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsHostelBooking(data));
      store.dispatch(setGettingStudentsHostelBooking(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsHostelBooking(false));
    });
};

export const getStudentsHostelBokingSummary = async (
  HostelId: number,
  SemesterId: number,
  BlockId: number,
  LevelId: number,
  StartDate: string,
  EndDate: string,
  GenderId: number,
  SessionId: number,
  FacultyId: number,
  DepartmentId: number,
  PageSize: number,
  PageNumber: number
) => {
  store.dispatch(setGettingStudentsHostelBookingSummary(true));
  const params: any = {
    HostelId,
    SemesterId,
    BlockId,
    LevelId,
    StartDate,
    EndDate,
    GenderId,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetstudentsHostelBokingSummary", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsHostelBookingSummary(data?.response));
      store.dispatch(setGettingStudentsHostelBookingSummary(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsHostelBookingSummary(false));
    });
};

export const getGeneratedReportById = async (Id: number) => {
  store.dispatch(setGettingGeneratedReportById(true));
  const params = {
    Id,
  };
  await api
    .get("/Reports/GeneratedReportBy", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGeneratedReportById(data?.response));
      store.dispatch(setGettingGeneratedReportById(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingGeneratedReportById(false));
    });
};

export const getStudentsHostelBookingsDownloadable = async (
  HostelId: number | null,
  SemesterId: number | null,
  BlockId: number | null,
  LevelId: number | null,
  GenderId: number | null,
  ReportName: string,
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingStudentsHostelBookingDownloadable(true));
  const params: any = {
    HostelId,
    SemesterId,
    BlockId,
    LevelId,
    GenderId,
    ReportName,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetstudentsHostelBokings/downloadable", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingStudentsHostelBookingDownloadable(false));
      store.dispatch(setStudentsHostelBookingDownloadable(data?.response));
      toast.success("Report exported Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsHostelBookingDownloadable(false));
    });
};

// new hostel apis -------------------------------------

export const getHostels = async () => {
  store.dispatch(setGettingHostels(true));
  await api
    .get("/Hostel")
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostels(data));
      store.dispatch(setGettingHostels(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostels(false));
    });
};

export const createNewHostel = async (
  name: string,
  numberOfBlocks: number,
  genderId: number
) => {
  store.dispatch(setCreatingHostel(true));
  const body = {
    name,
    numberOfBlocks,
    genderId,
  };
  await api
    .post("/Hostel/Create", body)
    .then(() => {
      store.dispatch(setCreatingHostel(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingHostel(false));
    });
};

export const editHostel = async (
  HostelId: number | null,
  numberOfBlocks: number | null
) => {
  store.dispatch(setEditingHostel(true));
  const params: any = {
    HostelId,
    numberOfBlocks,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .patch(
      `/Hostel/Edit?HostelId=${HostelId}&numberOfBlocks=${numberOfBlocks}`,
      { params }
    )
    .then((res) => {
      store.dispatch(setEditingHostel(false));
      toast.success("Successful");
      // window.location.reload();
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingHostel(false));
    });
};

export const getHostelBySession = async (sessionId: number | null) => {
  store.dispatch(setGettingHostelBySession(true));
  const params = {
    sessionId,
  };
  await api
    .get("/Hostel/HostelSession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelBySession(data));
      store.dispatch(setGettingHostelBySession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostelBySession(false));
    });
};

export const setHostelSession = async (
  status: number | null,
  session: number | null,
  hostelId: number | null,
  semester: number | null,
  department: number | null,
  level: any
) => {
  store.dispatch(setSettingHostelSession(true));

  const body: any = {
    status,
    session,
    hostelId,
    semester,
    department,
    level,
  };
  Object.keys(body).forEach((key) => {
    if (!body[key]) {
      delete body[key];
    }
  });
  await api
    .post("/Hostel/HostelSession/Set", body)
    .then(() => {
      store.dispatch(setSettingHostelSession(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSettingHostelSession(false));
    });
};

export const setHostelSessionStatus = async (
  hostelsessionId: number,
  status: number | null
) => {
  store.dispatch(setSettingHostelSessionStatus(true));
  const params = {
    hostelsessionId,
    status,
  };
  await api
    .get("/Hostel/HostelSession/Status/Set", { params })
    .then(() => {
      store.dispatch(setSettingHostelSessionStatus(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSettingHostelSessionStatus(false));
    });
};

export const getHostelBlocks = async (hostelId: number | null) => {
  store.dispatch(setGettingHostelBlocks(true));
  const params = {
    hostelId,
  };
  await api
    .get("/Hostel/HostelBlock", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelBlocks(data));
      store.dispatch(setGettingHostelBlocks(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostelBlocks(false));
    });
};

export const createHostelBlocks = async (
  status: number,
  numberOfrooms: number,
  hostelBlockId: number
) => {
  store.dispatch(setCreatingHostelBlock(true));
  const body: any = {
    status,
    numberOfrooms,
    hostelBlockId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Hostel/HostelBlock/Set", body)
    .then(() => {
      store.dispatch(setCreatingHostelBlock(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingHostelBlock(false));
    });
};

export const editHostelBlocks = async (
  status: number | null,
  numberOfrooms: number | null,
  roomBlockId: number | null
) => {
  store.dispatch(setCreatingHostelBlock(true));
  const body: any = {
    status,
    numberOfrooms,
    roomBlockId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Hostel/RoomBunks/set", body)
    .then(() => {
      store.dispatch(setCreatingHostelBlock(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingHostelBlock(false));
    });
};

export const getRoomBunks = async (
  BlockId: number | null,
  hostelId: number | null
) => {
  store.dispatch(setGettingRoomBunks(true));
  const params = {
    BlockId,
    hostelId,
  };
  await api
    .get("/Hostel/RoomBunks", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setRoomBunks(data));
      store.dispatch(setGettingRoomBunks(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingRoomBunks(false));
    });
};

export const editRoomBunks = async (
  status: number | null,
  numberOfBunk: number | null,
  roomBlockId: number | null
) => {
  store.dispatch(setCreatingHostelBlock(true));
  const body: any = {
    status,
    numberOfBunk,
    roomBlockId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Hostel/RoomBunks/set", body)
    .then(() => {
      store.dispatch(setCreatingHostelBlock(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingHostelBlock(false));
    });
};

export const getBunkSettings = async (
  BunkId: number | null,
  Sessionid: number | null,
  semesterid: number | null
) => {
  store.dispatch(setGettingBunkSettings(true));
  const params = {
    BunkId,
    Sessionid,
    semesterid,
  };
  await api
    .get("/Hostel/Bunksettings", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingBunkSettings(false));
      store.dispatch(setBunkSettings(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingBunkSettings(false));
    });
};

export const editBunkSettings = async (
  bunkId: number | null,
  bunkType: number | null,
  status: number | null,
  bunkNumber: string | null
) => {
  store.dispatch(setUpdatingBunkSettings(true));
  const body: any = {
    bunkId,
    bunkType,
    status,
    bunkNumber,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Hostel/Bunksettings", body)
    .then(() => {
      store.dispatch(setUpdatingBunkSettings(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingBunkSettings(false));
    });
};

export const assignBunk = async (
  bunkId: number | null,
  studentReg: string | null
) => {
  store.dispatch(setAssigningBunkToStudent(true));
  const body = {
    bunkId,
    studentReg,
  };
  await api
    .post("/Hostel/Bunksettings/Assign", body)
    .then(() => {
      store.dispatch(setAssigningBunkToStudent(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setAssigningBunkToStudent(false));
    });
};

export const revokeStudentBunk = async (
  bunkId: number | null,
  studentReg: string | null
) => {
  store.dispatch(setAssigningBunkToStudent(true));
  const body = {
    bunkId,
    studentReg,
  };
  await api
    .post("/Hostel/Bunksettings/Revoke", body)
    .then(() => {
      store.dispatch(setAssigningBunkToStudent(false));
      toast?.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setAssigningBunkToStudent(false));
    });
};

export const checkHostelAllocationValid = async (
  studentId: number,
  semesterId: number
) => {
  store.dispatch(setCheckingHostelValidity(true));
  const params = {
    studentId,
    semesterId,
  };
  await api
    .get("/Utill/PrintHostelCard", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelValidityData(data));
      store.dispatch(setCheckingHostelValidity(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCheckingHostelValidity(false));
    });
};
