import { FC } from "react";
import {
  AppLogo,
  AuthPageContainer,
  AuthText,
  FormSection,
} from "./styles/AuthPageLayout";

interface AuthPageLayoutProps {
  children: any;
  authText: string;
}
const AuthPageLayout: FC<AuthPageLayoutProps> = ({ children, authText }) => {
  return (
    <AuthPageContainer>
      <div className="cover"></div>
      <FormSection>
        <AppLogo>
          <img src="/assets/images/PNG/logo.png" alt="logo" />
        </AppLogo>
        <AuthText>{authText}</AuthText>
        {children}
      </FormSection>
    </AuthPageContainer>
  );
};

export default AuthPageLayout;
