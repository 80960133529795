import styled from "styled-components";

const SelectContainer = styled.select`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: var(--clr-white-100);
  outline: none;
  border: none;
  border-bottom: 1px solid var(--clr-grey-100);
  padding: 10px 0;
  text-align: left;
  transition: all 0.5s ease;
  width: 100%;
  min-width: 200px;

  &:focus {
    border-color: var(--clr-black);
    color: var(--clr-black);
  }

  &:hover {
    background: none;
  }

  option {
    font-size: 14px;
    padding: 10px;
  }
`;

interface DropdownProps {
  dropdownItems?: any;
  title?: any;
  handleDropdownSelect?: any;
  name?: string;
  value?: any;
  defaultValue?: any;
}

const RegularSelector = ({
  dropdownItems,
  title,
  handleDropdownSelect,
  name,
  value,
  defaultValue,
}: DropdownProps) => {
  return (
    <SelectContainer
      onChange={handleDropdownSelect}
      name={name}
      defaultValue={defaultValue}
      value={value}
      // placeholder={title}
    >
      {dropdownItems?.map((item: any) => (
        <option value={item?.value}>{item?.label}</option>
      ))}
    </SelectContainer>
  );
};

export default RegularSelector;
