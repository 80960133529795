import React from "react";
import SmallColoredCardWithIcon from "../../../Components/Layouts/SmallColoredCardWithIcon";
import { BsCreditCard2Back } from "react-icons/bs";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import { useAppSelector } from "../../../Redux/app/hooks";
import { SummaryContainer } from "../style";

const SummarySection = () => {
  const { gettingPaymentSummary, paymentSummary } =
    useAppSelector(selectPayment);

  const remitaTransaction = paymentSummary?.transaction?.filter(
    (trans: any) => trans.feeType === "Remita"
  );
  const offlineTransaction = paymentSummary?.transaction?.filter(
    (trans: any) => trans.feeType === "Offline"
  );
  const etranzactTransaction = paymentSummary?.transaction?.filter(
    (trans: any) => trans.feeType === "Etranzact"
  );

  return (
    <SummaryContainer>
      <SmallColoredCardWithIcon
        bgColor="var(--clr-pink)"
        icon={<BsCreditCard2Back />}
        title="Remita Transactions"
        value={remitaTransaction?.length > 0 ? remitaTransaction[0]?.count : 0}
        loading={gettingPaymentSummary}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-sky-blue)"
        icon={<BsCreditCard2Back />}
        title="Entransact Transactions"
        value={
          etranzactTransaction?.length > 0 ? etranzactTransaction[0]?.count : 0
        }
        loading={gettingPaymentSummary}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-purple)"
        icon={<BsCreditCard2Back />}
        title="offline Transactions"
        value={
          offlineTransaction?.length > 0 ? offlineTransaction[0]?.count : 0
        }
        loading={gettingPaymentSummary}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-dark-blue)"
        icon={<BsCreditCard2Back />}
        title="Total Transactions"
        value={
          remitaTransaction?.length > 0 &&
          etranzactTransaction?.length > 0 &&
          offlineTransaction?.length > 0 &&
          Number(remitaTransaction[0]?.count) +
            Number(etranzactTransaction[0]?.count) +
            Number(offlineTransaction[0]?.count)
        }
        loading={gettingPaymentSummary}
      />
    </SummaryContainer>
  );
};

export default SummarySection;
