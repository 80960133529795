import { toast } from "react-toastify";

export function removeEmptyValues(obj) {
  // Iterate through each key in the object
  for (let key in obj) {
    // If the value is an empty string, set it to null
    if (obj[key] === "") {
      obj[key] = null;
    }
    // If the value is an object (and not an array), recursively set empty values to null
    else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      removeEmptyValues(obj[key]);
    }
  }

  return obj;
}

export function formatDate(dateString) {
  // Parse the date string using the Date constructor
  const date = new Date(Date.parse(dateString));

  // Check if the date is valid
  if (isNaN(date)) {
    return "Invalid Date";
  }

  // Format the date to "yyyy-MM-dd"
  const formattedDate = date.toLocaleDateString("en-CA"); // "en-CA" for ISO format "yyyy-MM-dd"
  return formattedDate;
}

export const checkCharacterLimit = (characters, limit) => {
  let continueTyping = true;

  if (characters.length > limit) {
    toast.error(`Number of characters can't exceed ${limit}`);
    continueTyping = false;
  }

  return continueTyping;
};
