import React, { FC } from "react";
import { PiCaretRight } from "react-icons/pi";
import { styled } from "styled-components";
import { media } from "../../Screens";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loaders/MoonLoader";


const CardContainer = styled.div<CardProps>`
  display: flex;
  height: 98px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid var(--MWB-Sep-Stroke, #f2edff);
  background: ${(props) => props?.bgColor};
  box-shadow: 0px 4px 30px 0px rgba(136, 136, 136, 0.2);
  cursor: pointer;

  ${media.tablet} {
    padding: 10px;
  }

  svg {
    color: var(--clr-white-100);
    font-size: 18px;
  }

  svg:first-child {
    width: 45px;
    height: 45px;
  }

  &:last-child {
    flex: 1;
  }
`;

const CenterItems = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const Title = styled.p`
  color: var(--clr-white-100);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

const Value = styled.p`
  color: var(--clr-white-100);
  font-size: 22px;
  font-weight: var(--fw-very-bold);
  line-height: normal;
`;

const LeftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    width: 10px;
  }
`;

const Icon = styled.div`
  svg,
  img {
    width: 16px;
    font-size: 16px;

    ${media.tablet} {
      width: 50px;
      font-size: 20px;
    }
  }
`;
interface CardProps {
  bgColor: string;
  title?: string;
  value?: any;
  icon?: any;
  link?: string;
  loading?: boolean;
}

const SmallColoredCardWithIcon: FC<CardProps> = ({
  title,
  bgColor,
  value,
  icon,
  link,
  loading,
}) => {
  const navigate = useNavigate();
  return (
    <CardContainer bgColor={bgColor}>
      <LeftItem>
        <Icon>{icon}</Icon>
        <CenterItems>
          <Title>{title}</Title>
          {loading ? <Loader size={10} color="#fff" /> : <Value>{value}</Value>}
        </CenterItems>
      </LeftItem>
      {link && <PiCaretRight onClick={() => navigate(`${link}`)} />}
    </CardContainer>
  );
};

export default SmallColoredCardWithIcon;
