import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import SummarySection from "./SummarySection";
// import StudentsChart from "../../../Charts/StudentsChart";
import TransactionChart from "../../../Charts/TransactionsChart";
import FilterSection from "../../../Components/Filters/DashboardFilterSection";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import { getPaymentsSummary } from "../../../Apis/Private/payment";
import {
  getAllTransactionChart,
  getStudentsNumberBySession,
} from "../../../Apis/Private/reports";

const Dashboard = () => {
  const { SessionId, SemesterId } = useAppSelector(selectPayment);

  useEffect(() => {
    getPaymentsSummary(SessionId, SemesterId);
    getAllTransactionChart(SessionId, SemesterId);
    getStudentsNumberBySession(SessionId);
  }, [SessionId, SemesterId]);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Dashboard</PageTitle>
        <FilterSection />
      </ItemsCenterJustifyBtw>
      <SummarySection />
      {/* <LongTransparentCard>
        <StudentsChart />
      </LongTransparentCard> */}
      <LongTransparentCard>
        <TransactionChart />
      </LongTransparentCard>
    </>
  );
};

export default Dashboard;
