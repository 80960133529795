import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import CustomTable2 from "../CustomTable2";
import { bunkSettingsCols, bunkSettingsRows } from "./utils";

const BunkSettingsTable = () => {
  const {
    bunkSettings,
    gettingBunkSettings,
    updatingBunkSettings,
  } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();
  const rows = bunkSettingsRows(
    bunkSettings,
    updatingBunkSettings,
  );


  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: bunkSettingsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingBunkSettings}
      noRecord={bunkSettings?.length === 0}
      page={bunkSettings?.currentPage}
      pageCount={bunkSettings?.totalRecords}
      pageSize={bunkSettings?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default BunkSettingsTable;
