export const TabList = [
  {
    id: 1,
    name: "Profile",
    link: "/settings/profile",
  },
  {
    id: 2,
    name: "Security",
    link: "/settings/security",
  },
];
