import React, { useState } from "react";
import { SubTitle, SubTitleContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import AddResult from "./AddResult";
import { filter } from "./utils";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";
import { getApplicationDetails, selectapplicationForm } from "../../../Redux/features/Application/ApplicationFormSlice";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import AddResult2 from "./AddResult2";
import { FormContainer } from "../styles";
import { ItemsCenterJustifyBtw } from "../../PrivatePages/style";

const OLevelResultPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentSitting, setCurrentSitting] = useState(1);
  const { allExamTypes, allExamYears } = useAppSelector(selectApplicationUtils);
  const {
    examType,
    examYear,
    examNumber,
    OlevelResult,
    examType2,
    examYear2,
    examNumber2,
    OlevelResult2,
  } = useAppSelector(selectapplicationForm);
  const examYearsFilter = filter(allExamYears, "text", "value");
  const examTypesFilter = filter(
    allExamTypes,
    "olevelExamName",
    "olevelExamTypeId"
  );

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (examNumber && examYear && examNumber && OlevelResult?.length) {
      if (OlevelResult?.length < 9 || OlevelResult?.length > 9) {
        toast.error("Subjects should not be less than or greater than nine ");
        if (examNumber2 && examYear2 && examNumber2 && OlevelResult2?.length) {
          if (OlevelResult2?.length < 9 || OlevelResult2?.length > 9) {
            toast.error(
              "Subjects should not be less than or greater than nine "
            );
          }
        }
      } else {
        if (
          (examNumber &&
            examYear &&
            examNumber &&
            OlevelResult?.length &&
            !(
              examNumber2 &&
              examYear2 &&
              examNumber2 &&
              OlevelResult2?.length
            )) ||
          (examNumber &&
            examYear &&
            examNumber &&
            OlevelResult?.length &&
            examNumber2 &&
            examYear2 &&
            examNumber2 &&
            OlevelResult2?.length)
        ) {
          navigate("/edit/jamb-result-details");
        } else {
          toast.error("PLease fill all necessary information correctly ");
        }
      }
    } else {
      toast.error("First sitting result must be filled correctly");
    }

    // if (OlevelResult?.length) {
    //   if (OlevelResult?.length === 9) {
    //     if (!examType || !examYear || !examNumber) {
    //       toast.error("please fill all fields");
    //     }
    //   } else {
    //     toast.error("Total subjects for first sitting must be equal to nine");
    //   }
    // } else if (OlevelResult2?.length) {
    //   if (OlevelResult?.length !== 9) {
    //     if (!examType2 || !examYear2 || !examNumber2) {
    //       toast.error("please fill all fields");
    //     } else {
    //       toast.error(
    //         "Total subjects for second sitting must be equal to nine if any"
    //       );
    //     }
    //   }
    // } else {
    //   navigate("/jamb-result-details");
    // }
  };

  return (
    <AuthPageLayout authText="O Level Result">
      <SubTitleContainer>
        <SubTitle>First Sitting</SubTitle>
        {currentSitting !== 1 && (
          <span onClick={() => setCurrentSitting(1)}>+</span>
        )}
        {currentSitting === 1 && (
          <span onClick={() => setCurrentSitting(0)}>-</span>
        )}
      </SubTitleContainer>
      {currentSitting === 1 && (
        <FormContainer>
          <ItemsCenterJustifyBtw>
            <CustomRegularDropdown
              title="Exam Type"
              name="examType"
              handleDropdownSelect={handleSelect}
              dropdownItems={examTypesFilter}
              value={examType}
            />
            <CustomRegularDropdown
              title="Exam Year"
              name="examYear"
              handleDropdownSelect={handleSelect}
              dropdownItems={examYearsFilter}
              value={examYear}
            />
            <TextInputWithIcon
              value={examNumber}
              placeholder="Exam Number"
              type="text"
              handleChange={handleOnChange}
              name="examNumber"
            />
          </ItemsCenterJustifyBtw>
          <AddResult />
        </FormContainer>
      )}
      <SubTitleContainer>
        <SubTitle>Second Sitting</SubTitle>
        {currentSitting !== 2 && (
          <span onClick={() => setCurrentSitting(2)}>+</span>
        )}
        {currentSitting === 2 && (
          <span onClick={() => setCurrentSitting(0)}>-</span>
        )}
      </SubTitleContainer>
      {currentSitting === 2 && (
        <FormContainer>
          <ItemsCenterJustifyBtw>
            <CustomRegularDropdown
              title="Exam Type"
              name="examType2"
              handleDropdownSelect={handleSelect}
              dropdownItems={examTypesFilter}
              value={examType2}
            />
            <CustomRegularDropdown
              title="Exam Year"
              name="examYear2"
              handleDropdownSelect={handleSelect}
              dropdownItems={examYearsFilter}
              value={examYear2}
            />
            <TextInputWithIcon
              value={examNumber2}
              placeholder="Exam Number"
              type="text"
              handleChange={handleOnChange}
              name="examNumber2"
            />
          </ItemsCenterJustifyBtw>
          <AddResult2 />
        </FormContainer>
      )}
      <FormContainer>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default OLevelResultPage;
