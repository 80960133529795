import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  ItemsCenterJustifyBtw,
  PageTitle,
} from "../style";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { confirmPayment } from "../../../Apis/Private/payment";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../../Redux/features/paymentSlice";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { toast } from "react-toastify";
import { gatewaysOptions } from "./util";

const VerifyPayment = () => {
  const { confirmingPayment, reference, gateWay } =
    useAppSelector(selectPayment);
  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleVerify = () => {
    if (reference && gateWay) {
      confirmPayment(reference, gateWay);
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <>
      <PageTitle>Verify Student Payment</PageTitle>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="Invoice No./RRR"
                alignText="left"
                value={reference}
                name="reference"
                onChangeHandler={handleOnChange}
              />
              <UnderlinedSelector
                title="Gateway"
                value={gateWay}
                handleDropdownSelect={handleSelect}
                name="gateWay"
                dropdownItems={gatewaysOptions}
              />
            </ItemsCenterJustifyBtw>
            <BigButton
              buttonText="Verify"
              textTransform="capitalize"
              disabled={false}
              handleClick={handleVerify}
              loading={confirmingPayment}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default VerifyPayment;
