/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import AdmissionsFilter from "../../../Components/Filters/AdmisionsFilter";
import PendingAdmissionsTable from "../../../Tables/AllStudents/PendingAdmissionsTable";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import {
  DownloadPendingAdmissionsReport,
  getPendingAdmissions,
} from "../../../Apis/Private/Admissions";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { PiExportBold } from "react-icons/pi";
import {
  getReportsFormDetails,
  selectReport,
} from "../../../Redux/features/reportSlice";
import { toast } from "react-toastify";
import { selectAdmission } from "../../../Redux/features/admissionSlice";

const PendingAdmissions = () => {
  const dispatch = useAppDispatch();
  const {
    SessionId,
    SearchPrams,
    PageNumber,
    PageSize,
    FacultyId,
    DepartmentId,
  } = useAppSelector(selectStudents);
  const { pendingAdmissionsReportName } = useAppSelector(selectReport);
  const { downloadingPendingAdmissionREport } = useAppSelector(selectAdmission);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleFetchPendingAdmissions = () => {
    if (SessionId || SearchPrams) {
      getPendingAdmissions(
        SearchPrams,
        SessionId,
        FacultyId,
        DepartmentId,
        PageNumber,
        PageSize
      );
    } else {
      toast.info("Please select at least session to filter");
    }
  };
  const handleExport = (e: any) => {
    e.preventDefault();
    if (pendingAdmissionsReportName) {
      DownloadPendingAdmissionsReport(
        pendingAdmissionsReportName,
        SessionId,
        FacultyId,
        DepartmentId,
        PageSize,
        PageNumber,
        SearchPrams
      );
    } else {
      toast.error("Enter the report name");
    }
  };

  useEffect(() => {
    if (SessionId || SearchPrams) {
      handleFetchPendingAdmissions();
    }
  }, [PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Pending Admissions</PageTitle>
      <ItemsCenterJustifyBtw
        className="justify-end"
        style={{ alignItems: "baseline" }}
      >
        <TextCenteredInput
          placeholder="Type Report Name to Export"
          alignText="left"
          name="pendingAdmissionsReportName"
          value={pendingAdmissionsReportName}
          onChangeHandler={handleOnChange}
        />
        {pendingAdmissionsReportName && (
          <SmallButton
            buttonText="Export"
            leftIcon={<PiExportBold />}
            loading={downloadingPendingAdmissionREport}
            handleClick={handleExport}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="justify-between">
        <OutlinedSearchInput
          placeholder="Search by name or email"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={handleFetchPendingAdmissions}
        />
        <AdmissionsFilter filterFunction={handleFetchPendingAdmissions} />
      </ItemsCenterJustifyBtw>
      <PendingAdmissionsTable />
    </>
  );
};

export default PendingAdmissions;
