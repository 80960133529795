import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import CustomTable2 from "../CustomTable2";
import { HostelsCols, HostelRows } from "./utils";

const AllHostelsTable = () => {
  const { gettingHostels, hostels } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();
  const rows = HostelRows(hostels);

  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: HostelsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingHostels}
      noRecord={hostels?.length === 0}
      page={hostels?.currentPage}
      pageCount={hostels?.totalRecords}
      pageSize={hostels?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default AllHostelsTable;
