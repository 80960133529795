import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface UtilState {
  gettingSessions: boolean;
  sessions: object | null | any;
  gettingSemesters: boolean;
  semesters: object | null;
  gettingFeeSchedule: boolean;
  feeSchedule: object | null | any;
  gettingPaymentMean: boolean;
  paymentMean: object | null;
  gettingHostels: boolean;
  hostels: object | null | any;
  gettingHostelBlock: boolean;
  hostelBlock: object | null;
  gettingFaculties: boolean;
  faculties: object | null | any;
  gettingDepartments: boolean;
  departments: object | null | any;
  gettingActiveSession: boolean;
  activeSession: object | null;
  gettingBankList: boolean;
  bankList: object | null;
  gettingAdmissionTypes: boolean;
  admissionTypes: object | null;
  faculty: number | null;
}

const initialState: UtilState = {
  gettingSessions: false,
  sessions: null,
  gettingSemesters: false,
  semesters: null,
  gettingFeeSchedule: false,
  feeSchedule: null,
  gettingPaymentMean: false,
  paymentMean: null,
  gettingHostels: false,
  hostels: null,
  gettingHostelBlock: false,
  hostelBlock: null,
  gettingFaculties: false,
  faculties: null,
  gettingDepartments: false,
  departments: null,
  gettingActiveSession: false,
  activeSession: null,
  gettingBankList: false,
  bankList: null,
  gettingAdmissionTypes: false,
  admissionTypes: null,
  faculty: null,
};

export const UtilSlice = createSlice({
  name: "Util",
  initialState,
  reducers: {
    setGettingSessions: (state, action) => {
      state.gettingSessions = action.payload;
    },
    setGettingSemesters: (state, action) => {
      state.gettingSemesters = action.payload;
    },
    setGettingFeeSchedule: (state, action) => {
      state.gettingFeeSchedule = action.payload;
    },
    setGettingPaymentMean: (state, action) => {
      state.gettingPaymentMean = action.payload;
    },
    setGettingHostels: (state, action) => {
      state.gettingHostels = action.payload;
    },
    setGettingHostelBlock: (state, action) => {
      state.gettingHostelBlock = action.payload;
    },
    setGettingFaculties: (state, action) => {
      state.gettingFaculties = action.payload;
    },
    setGettingDepartments: (state, action) => {
      state.gettingDepartments = action.payload;
    },
    setGettingActiveSession: (state, action) => {
      state.gettingActiveSession = action.payload;
    },
    setGettingAdmissionTypes: (state, action) => {
      state.gettingAdmissionTypes = action.payload;
    },
    setSessions: (state, action) => {
      state.sessions = action.payload;
    },
    setGettingBankList: (state, action) => {
      state.gettingBankList = action.payload;
    },
    setBankList: (state, action) => {
      state.bankList = action.payload;
    },
    setSemesters: (state, action) => {
      state.semesters = action.payload;
    },
    setFeeSchedule: (state, action) => {
      state.feeSchedule = action.payload;
    },
    setPaymentMean: (state, action) => {
      state.paymentMean = action.payload;
    },
    setHostels: (state, action) => {
      state.hostels = action.payload;
    },
    setHostelBlock: (state, action) => {
      state.hostelBlock = action.payload;
    },
    setFaculties: (state, action) => {
      state.faculties = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setActiveSession: (state, action) => {
      state.activeSession = action.payload;
    },
    setAdmissionTypes: (state, action) => {
      state.admissionTypes = action.payload;
    },
  },
});

export const {
  setActiveSession,
  setDepartments,
  setFaculties,
  setFeeSchedule,
  setGettingActiveSession,
  setGettingDepartments,
  setGettingFaculties,
  setGettingFeeSchedule,
  setGettingHostelBlock,
  setGettingHostels,
  setGettingPaymentMean,
  setGettingSemesters,
  setGettingSessions,
  setHostelBlock,
  setHostels,
  setPaymentMean,
  setSemesters,
  setSessions,
  setBankList,
  setGettingBankList,
  setGettingAdmissionTypes,
  setAdmissionTypes,
} = UtilSlice.actions;
export const selectUtil = (state: RootState) => state.utils;

export default UtilSlice.reducer;
