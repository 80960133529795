import { useEffect } from "react";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  ItemsCenterJustifyBtw,
  PageTitle,
} from "../style";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { useNavigate, useParams } from "react-router-dom";
import { getAdmmitedStudentDetails } from "../../../Apis/Private/students";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
  setEditingStudentInfo,
} from "../../../Redux/features/studentsSlice";
import { selectUser } from "../../../Redux/features/userSlice";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";
import { getLGA } from "../../../Apis/Application/util";
import { genderOptions } from "../Hostels/util";
import { disabilityFilters } from "../../AuthPages/Applications/utils";
import moment from "moment";

const AdmittedStudentProfile = () => {
  const {
    departmentId,
    facultyId,
    appicationNumber,
    firstName,
    lastName,
    otherName,
    mobilePhone,
    contactAddress,
    emailAddress,
    parentName,
    stateId,
    religionId,
    localGovernmentId,
    genderId,
    disabilityId,
    parentNumber,
    dateOfBirth,
  } = useAppSelector(selectStudents);
  const { userPermissions } = useAppSelector(selectUser);
  const { faculties, departments } = useAppSelector(selectUtil);
  const { allState, allLGA, allReligion } = useAppSelector(
    selectApplicationUtils
  );
  const { Id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const facultyFilter = filter(faculties, "facultyName", "facultyId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const StatesFilter = filter(allState, "stateName", "stateId");
  const LGAFilter = filter(allLGA, "localGovernmentName", "localGovernmentId");
  const ReligionFilter = filter(allReligion, "religionName", "religionId");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleUpdate = () => {
    navigate(`/admitted/Students/${Id}/next-of-kin`);
  };

  useEffect(() => {
    getAdmmitedStudentDetails(Number(Id));
    dispatch(setEditingStudentInfo(false));
  }, [Id, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      stateId && getLGA(stateId);
    }, 2000);
  }, [stateId]);

  return (
    <>
      <PageTitle>Personal Information</PageTitle>
      <NavigationLink to={`/Students`} className="flex-centered-items">
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="firstname"
                value={firstName}
                name="firstName"
                alignText="left"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="lastname"
                value={lastName}
                name="lastName"
                onChangeHandler={handleOnChange}
                alignText="left"
              />
              <TextCenteredInput
                placeholder="othername"
                value={otherName}
                name="otherName"
                onChangeHandler={handleOnChange}
                alignText="left"
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                value={genderId}
                name="genderId"
                handleDropdownSelect={handleSelect}
                title={"Gender"}
                dropdownItems={genderOptions}
              />
              <TextCenteredInput
                type="date"
                placeholder="Date of Birth"
                value={moment(dateOfBirth).format("YYYY-MM-DD")}
                alignText="left"
                name="dateOfBirth"
                onChangeHandler={handleOnChange}
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                value={facultyId}
                name="facultyId"
                handleDropdownSelect={handleSelect}
                title={"Faculty"}
                dropdownItems={facultyFilter}
                valueName="facultyName"
              />
              <UnderlinedSelector
                title="Department"
                value={departmentId}
                name="departmentId"
                handleDropdownSelect={handleSelect}
                dropdownItems={departmentFilter}
                valueName="departmentName"
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                title="State"
                value={stateId}
                name="stateId"
                handleDropdownSelect={handleSelect}
                dropdownItems={StatesFilter}
                valueName="stateName"
              />
              <UnderlinedSelector
                title="Local Government"
                value={localGovernmentId}
                name="localGovernmentId"
                handleDropdownSelect={handleSelect}
                dropdownItems={LGAFilter}
                valueName="localGovernmentName"
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="ContactAddress"
                value={contactAddress}
                alignText="left"
                name="contactAddress"
                onChangeHandler={handleOnChange}
              />
              <UnderlinedSelector
                title="Religion"
                value={religionId}
                name="religionId"
                handleDropdownSelect={handleSelect}
                dropdownItems={ReligionFilter}
                valueName="religionName"
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="Phone Number"
                value={mobilePhone}
                alignText="left"
                name="mobilePhone"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="Email Address"
                value={emailAddress}
                alignText="left"
                name="emailAddress"
                onChangeHandler={handleOnChange}
                readOnly={true}
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <UnderlinedSelector
                title="Disability"
                value={disabilityId}
                name="disabilityId"
                handleDropdownSelect={handleSelect}
                dropdownItems={disabilityFilters}
              />
              <TextCenteredInput
                placeholder="Application Number"
                value={appicationNumber}
                alignText="left"
                name="appicationNumber"
                onChangeHandler={handleOnChange}
              />
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
              <TextCenteredInput
                placeholder="Parent/Sponsor Name"
                value={parentName}
                alignText="left"
                name="parentName"
                onChangeHandler={handleOnChange}
              />
              <TextCenteredInput
                placeholder="Parent/Sponsor Phone Number"
                value={parentNumber}
                alignText="left"
                name="parentPhoneNumber"
                onChangeHandler={handleOnChange}
              />
            </ItemsCenterJustifyBtw>
            {/* <div className="flex-centered-items" style={{ width: `100%` }}>
              <TextCenteredInput
                placeholder="Hobbies"
                value={hobbies}
                alignText="left"
                name="hobbies"
                onChangeHandler={handleOnChange}
              />
            </div> */}
            <BigButton
              buttonText="Continue"
              textTransform="capitalize"
              disabled={!userPermissions?.includes("edit-students-details")}
              handleClick={handleUpdate}
              // loading={editingStudentInfo}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default AdmittedStudentProfile;
