import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import BunkFilter from "../../../Components/Filters/BunkFilter";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import { getHostelBlocks, getRoomBunks } from "../../../Apis/Private/hostel";
import BunkTable from "../../../Tables/AllHostels/BunkTables";

const HostelRoomBunk = () => {
  const { HostelId, BlockId } = useAppSelector(selectHostel);

  const handleGetHostelRoomsBunks = () => {
    if (HostelId && BlockId) {
      getRoomBunks(BlockId, HostelId);
    }
  };

  useEffect(() => {
    getHostelBlocks(HostelId);
  }, [HostelId]);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Hostel Block Rooms</PageTitle>
        <BunkFilter filterFunction={handleGetHostelRoomsBunks} />
      </ItemsCenterJustifyBtw>
      <BunkTable />
    </>
  );
};

export default HostelRoomBunk;
