import { FC } from "react";
import { BigAvatarDetails } from "./tableCellsStyles";

interface ImageCellProps {
  avatar?: any;
  firstName?: string;
  lastName?: string;
  otherName?: string;
  userName?: string;
}
export const ImageCell: FC<ImageCellProps> = ({
  avatar,
  firstName,
  lastName,
  userName,
  otherName,
}) => (
  <div style={{ display: `flex`, gap: `40px`, alignItems: `center` }}>
    {/* <div
      style={{
        width: 40,
        height: 40,
        background: "#f5f5f5",
        marginTop: 2,
        // overflow: "hidden",
        display: "inline-block",
        borderRadius: `50%`,
      }}
    >
      <img src={avatar} width="40" alt="avatar" />
    </div> */}
    <BigAvatarDetails>
      <p>
        {firstName} {lastName} {otherName}
      </p>
      <span>{userName}</span>
    </BigAvatarDetails>
  </div>
);
