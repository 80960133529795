/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { advertMeansOptions, disabilityFilters, filter } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import Overlay from "../../../Loaders/Overlay";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";
import moment from "moment";
import { getAndUpdateSinglePendingAdmission } from "../../../Apis/Private/Admissions";

const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const newStudentData: any = JSON.parse(
    localStorage.getItem("newStudentDetails") || "{}"
  );
  const {
    title,
    firstName,
    otherName,
    lastName,
    studentEmailId,
    mobileNumber,
    dateOfBirth,
    Gender,
    Religion,
    stateOfOrigin,
    homeTown,
    parentName,
    hobbies,
    parentPhoneNumber,
    gettingAndUpdatingSinglePendingAdmission,
    localGovernment,
    department,
    admissionTypeId,
    Disability,
    tribeId,
    advertMeansId,
    referral,
    faculty,
    country,
  } = useAppSelector(selectapplicationForm);
  const {
    allState,
    allReligion,
    allLGA,
    departments,
    allAdmissionType,
    tribes,
    faculties,
    allCountries,
  } = useAppSelector(selectApplicationUtils);
  const { id } = useParams();

  const countryFilter = filter(allCountries, "countryName", "countryId");
  const stateFilter = filter(allState, "stateName", "stateId");
  const religionFilter = filter(allReligion, "religionName", "religionId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const admissionTypeFilter = filter(allAdmissionType, "name", "id");
  const tribeFilter = filter(tribes, "tribeName", "tribeId");
  const facultyFilter = filter(faculties, "facultyName", "facultyId");
  const localGovernmentFilter = filter(
    allLGA,
    "localGovernmentName",
    "localGovernmentId"
  );
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    navigate("/edit/next-of-kin-details");
  };

  useEffect(() => {
    getAndUpdateSinglePendingAdmission(Number(id));
    dispatch(
      getApplicationDetails({
        name: "personId",
        value: newStudentData?.personId,
      })
    );
  }, [id]);

  return (
    <AuthPageLayout authText="Personal Details">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Title"
            name="title"
            handleChange={handleOnChange}
            value={title}
          />
          <TextInputWithIcon
            type="text"
            placeholder="First Name"
            name="firstName"
            handleChange={handleOnChange}
            value={firstName}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Other Name"
            name="otherName"
            handleChange={handleOnChange}
            value={otherName}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Last Name"
            name="lastName"
            handleChange={handleOnChange}
            value={lastName}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw>
          <CustomRegularDropdown
            dropdownItems={facultyFilter}
            title="Faculty"
            name="faculty"
            handleDropdownSelect={handleSelect}
            value={faculty}
          />
          <CustomRegularDropdown
            dropdownItems={departmentFilter}
            title="Department"
            name="department"
            handleDropdownSelect={handleSelect}
            value={department}
          />
          <CustomRegularDropdown
            dropdownItems={admissionTypeFilter}
            title="Admission Type"
            name="admissionTypeId"
            handleDropdownSelect={handleSelect}
            value={admissionTypeId}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="email"
            placeholder="Student Email"
            name="studentEmailId"
            handleChange={handleOnChange}
            value={studentEmailId}
          />
          <TextInputWithIcon
            type="tel"
            placeholder="Mobile Number"
            name="mobileNumber"
            handleChange={handleOnChange}
            value={mobileNumber}
            characterLimit={14}
          />
          <TextInputWithIcon
            type="date"
            placeholder="Date Of Birth"
            name="dateOfBirth"
            handleChange={handleOnChange}
            value={dateOfBirth}
            readonly={true}
          />
          <CustomRegularDropdown
            title="Gender"
            name="Gender"
            handleDropdownSelect={handleSelect}
            dropdownItems={[
              { label: "Male", value: 1 },
              { label: "Female", value: 2 },
            ]}
            value={Gender}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <CustomRegularDropdown
            title="Religion"
            name="Religion"
            handleDropdownSelect={handleSelect}
            dropdownItems={religionFilter}
            value={Religion}
          />
          <CustomRegularDropdown
            dropdownItems={countryFilter}
            title={"Country"}
            name="country"
            handleDropdownSelect={handleSelect}
            value={country}
          />
          <CustomRegularDropdown
            dropdownItems={stateFilter}
            title="State Of Origin"
            name="stateOfOrigin"
            handleDropdownSelect={handleSelect}
            value={stateOfOrigin}
          />
          <CustomRegularDropdown
            dropdownItems={localGovernmentFilter}
            title={"Local Government"}
            name="localGovernment"
            handleDropdownSelect={handleSelect}
            value={localGovernment}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Home Town"
            name="homeTown"
            handleChange={handleOnChange}
            value={homeTown}
            characterLimit={48}
          />
          <CustomRegularDropdown
            dropdownItems={tribeFilter}
            title={"Tribe"}
            name="tribeId"
            handleDropdownSelect={handleSelect}
            value={tribeId}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Parent/Sponsor Name"
            name="parentName"
            handleChange={handleOnChange}
            value={parentName}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Parent/Guardian Mobile Number"
            name="parentPhoneNumber"
            handleChange={handleOnChange}
            value={parentPhoneNumber}
            characterLimit={14}
          />
          <CustomRegularDropdown
            dropdownItems={advertMeansOptions}
            title={"How did you hear about us?."}
            name="advertMeansId"
            handleDropdownSelect={handleSelect}
            value={advertMeansId}
          />
          {(advertMeansId === 9 ||
            advertMeansId === 10 ||
            advertMeansId === 11) && (
            <TextInputWithIcon
              type="text"
              placeholder="Referer Code"
              name="referral"
              handleChange={handleOnChange}
              value={referral}
            />
          )}
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Hobbies"
            name="hobbies"
            handleChange={handleOnChange}
            value={hobbies}
            characterLimit={48}
          />
          <CustomRegularDropdown
            dropdownItems={disabilityFilters}
            title="Disability"
            name="Disability"
            handleDropdownSelect={handleSelect}
            value={Disability}
          />
        </ItemsCenterJustifyBtw>

        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
      {gettingAndUpdatingSinglePendingAdmission && <Overlay />}
    </AuthPageLayout>
  );
};

export default PersonalDetails;
