import React, { useEffect } from "react";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { FaCircleCheck } from "react-icons/fa6";
import styled from "styled-components";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import { useParams } from "react-router-dom";
import { checkHostelAllocationValid } from "../../../Apis/Private/hostel";
import Loader from "../../../Loaders/MoonLoader";
import ReactPrint from "../../../Components/Layouts/ReactPrint";
import {
  Description,
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
} from "../Payments/styles";
import { ItemsCenterJustifyBtw } from "../style";
import { BsInfoCircleFill } from "react-icons/bs";
import SpecialHeader from "../../../Components/Nav/Header/SpecialHeader";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  /* height: 100vh; */
`;

const SuccessText = styled.p`
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #757575;
`;

const StudentImage = styled.img`
  width: 250px;
  height: 250px;
`;

const VerifyHostel = () => {
  const { checkingHostelValidity, hostelValidityData } =
    useAppSelector(selectHostel);
  const { studentId, semesterId } = useParams();

  useEffect(() => {
    checkHostelAllocationValid(Number(studentId), Number(semesterId));
  }, [studentId, semesterId]);

  return (
    <>
      <SpecialHeader />
      <LongWhiteCard>
        <LongTransparentCard>
          <ContentContainer>
            {checkingHostelValidity ? (
              <Loader size={20} color="#f72585" />
            ) : (
              <>
                <FaCircleCheck
                  color={hostelValidityData ? "green" : "red"}
                  size={100}
                />
                <SuccessText>
                  Hostel Allocated to this student is
                  {hostelValidityData ? " Valid" : " Not Valid"}
                </SuccessText>
                {hostelValidityData && (
                  <ReactPrint pay={false}>
                    <ReceiptContainer>
                      <ReceiptHeader>
                        <img src="/assets/images/PNG/logo.png" alt="logo" />
                        <SchoolName>
                          Godfrey Okoye University (GOUNI)
                        </SchoolName>
                        <SchoolPhone>Allocated Hostel Details</SchoolPhone>
                      </ReceiptHeader>
                      <ReceiptBody>
                        <StudentImage
                          src={hostelValidityData?.profileImageUrl}
                          alt="profile"
                        />
                        <ItemsCenterJustifyBtw>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Name:
                            </span>
                            <span>{hostelValidityData?.name}</span>
                          </div>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Matric. NO.:
                            </span>
                            <span>{hostelValidityData?.matricNo}</span>
                          </div>
                        </ItemsCenterJustifyBtw>
                        <ItemsCenterJustifyBtw>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Department:
                            </span>
                            <span>{hostelValidityData?.department}</span>
                          </div>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Level:
                            </span>
                            <span>{hostelValidityData?.level}</span>
                          </div>
                        </ItemsCenterJustifyBtw>
                        <ItemsCenterJustifyBtw>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Session:
                            </span>
                            <span>{hostelValidityData?.session}</span>
                          </div>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Semester:
                            </span>
                            <span>{hostelValidityData?.semester}</span>
                          </div>
                        </ItemsCenterJustifyBtw>
                        <ItemsCenterJustifyBtw>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Hostel:
                            </span>
                            <span>{hostelValidityData?.hostel}</span>
                          </div>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Block:
                            </span>
                            <span>{hostelValidityData?.hostelBlock}</span>
                          </div>
                        </ItemsCenterJustifyBtw>
                        <ItemsCenterJustifyBtw>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Room:
                            </span>
                            <span>{hostelValidityData?.roomNo}</span>
                          </div>
                          <div
                            className="flex-centered-items"
                            style={{ fontSize: `16px` }}
                          >
                            <span style={{ color: `var(--clr-black)` }}>
                              Bunk:
                            </span>
                            <span>{hostelValidityData?.bunk}</span>
                          </div>
                        </ItemsCenterJustifyBtw>
                      </ReceiptBody>
                      <Description style={{ alignItems: "center" }}>
                        <strong>
                          <BsInfoCircleFill
                            color={
                              hostelValidityData?.status === "Active"
                                ? "green"
                                : "red"
                            }
                            size={20}
                          />
                          Hostel Allocation is {hostelValidityData?.status}
                        </strong>
                      </Description>
                    </ReceiptContainer>
                  </ReactPrint>
                )}
              </>
            )}
          </ContentContainer>
        </LongTransparentCard>
      </LongWhiteCard>
    </>
  );
};

export default VerifyHostel;
