import { styled } from "styled-components";

export const BigAvatarDetails = styled.div`
  display: flex;
  flex-direction: column;
  //   gap: 10px;

  p {
    color: var(--clr-black);
    font-weight: var(--fw-very-bold);
  }
  span {
    font-size: 10px;
    font-weight: var(--fw-semi-bold);
    color: var(--clr-grey-200);
  }
`;
