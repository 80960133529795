import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import {
  getHostelBySession,
  setHostelSession,
} from "../../../Apis/Private/hostel";
import HostelSessionTable from "../../../Tables/AllHostels/HostelSessionTable";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { IoMdAdd } from "react-icons/io";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import { toast } from "react-toastify";
import { multiLevelOptions, sessionStatusOptions } from "./util";
import MultiSelector from "../../../Components/Form/Dropdowns/MultiSelector";
import HostelSectionFilter from "../../../Components/Filters/HostelSessiojnFilter";

const HostelSession = () => {
  const [createHostelSession, setCreateHostelSession] = useState(false);
  const {
    SessionId,
    settingHostelSession,
    status,
    session,
    hostelId,
    department,
    level,
    semester,
  } = useAppSelector(selectHostel);
  const { departments, sessions, semesters, hostels } =
    useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const hostelsFilter = filter(hostels, "hostelName", "hostelId");

  useEffect(() => {
    getHostelBySession(SessionId);
  }, [SessionId, settingHostelSession]);

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };

  const handleCreateHostelSession = () => {
    if (status && session && hostelId && semester) {
      const filterLevel = level?.map((x: any) => {
        return Number(x?.value);
      });
      setHostelSession(
        status,
        session,
        hostelId,
        semester,
        department,
        filterLevel
      );
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Hostel Session</PageTitle>
        {!createHostelSession && (
          <SmallButton
            buttonText="Add"
            leftIcon={<IoMdAdd />}
            handleClick={() => {
              setCreateHostelSession(true);
            }}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw
        className="justify-end flex-wrap"
        style={{ alignItems: "center" }}
      >
        {createHostelSession && (
          <>
            <UnderlinedSelector
              title={"Hostel"}
              name="hostelId"
              value={hostelId}
              handleDropdownSelect={handleSelect}
              dropdownItems={hostelsFilter}
            />
            <UnderlinedSelector
              title={"Session "}
              name="session"
              value={session}
              handleDropdownSelect={handleSelect}
              dropdownItems={sessionFilter}
            />
            <UnderlinedSelector
              title={"Semester "}
              name="semester"
              value={semester}
              handleDropdownSelect={handleSelect}
              dropdownItems={semesterFilter}
            />
            <UnderlinedSelector
              title={"Department"}
              name="department"
              value={department}
              handleDropdownSelect={handleSelect}
              dropdownItems={departmentFilter}
            />
            <MultiSelector
              title={"Level"}
              name="level"
              value={level}
              handleDropdownSelect={handleSelect}
              dropdownItems={multiLevelOptions}
            />
            <UnderlinedSelector
              title={"Status"}
              name="status"
              value={status}
              handleDropdownSelect={handleSelect}
              dropdownItems={sessionStatusOptions}
            />
            <SmallButton
              buttonText="Create"
              leftIcon={<IoMdAdd />}
              loading={settingHostelSession}
              handleClick={handleCreateHostelSession}
              alignWithSiblings={true}
            />
          </>
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw>
        <span></span>
        <HostelSectionFilter
          filterFunction={() => {
            if (SessionId) {
              getHostelBySession(SessionId);
            } else {
              toast.info("Please select session to filter");
            }
          }}
        />
      </ItemsCenterJustifyBtw>
      <HostelSessionTable />
    </>
  );
};

export default HostelSession;
