import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/AuthPages/Login";
import ForgotPassword from "../Pages/AuthPages/ForgotPassword";
import VerifyHostel from "../Pages/PrivatePages/Hostels/VerifyHostel";
// import SignUp from "../Pages/AuthPages/SignUp";
import FaAuth from "./../Pages/AuthPages/Login/2FA";
import _2FAPassword from './../Pages/AuthPages/ForgotPassword/2FA';

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<SignUp />} /> */}
      <Route path="/" element={<FaAuth />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password/2FA" element={<_2FAPassword />} />
      <Route path="/reset-password" element={<ForgotPassword />} />
      <Route
        path="/verify-hostel-allocation/:studentId/:semesterId"
        element={<VerifyHostel />}
      />
    </Routes>
  );
};

export default AuthRoutes;
