import { AiFillEdit } from "react-icons/ai";
import SmallButton from "../../Components/Form/Buttons/SmallButton";
import TextCenteredInput from "../../Components/Form/Inputs/TextCenteredInput";
import { ImageCell } from "./HostelTableCells";
import UnderlinedSelector from "../../Components/Form/Dropdowns/UnderlinedSelector";
import { MdSave } from "react-icons/md";
import { store } from "../../Redux/app/store";
import {
  getHostelsFormDetails,
  setClickedButtonIndex,
  setClickedButtonName,
} from "../../Redux/features/hostelSlice";
import {
  assignBunk,
  createHostelBlocks,
  editBunkSettings,
  editHostel,
  editRoomBunks,
  revokeStudentBunk,
  setHostelSessionStatus,
} from "../../Apis/Private/hostel";
import RegularSelector from "../../Components/Form/Dropdowns/RegularSelector";
import moment from "moment";
import { Link } from "react-router-dom";
import { bunkTypeOptions } from "../../Pages/PrivatePages/Hostels/util";

const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  const { name, value } = e.target;
  store?.dispatch(getHostelsFormDetails({ name, value }));
};
// const handleSelect = (name: string, value: string | number) => {
//   store?.dispatch(getHostelsFormDetails({ name, value }));
// };

// Users table data
export const HostelsCols = [
  { label: "Hostel Name", field: "hostelName" },
  { label: "Hostel Type", field: "hostelType" },
  { label: "Number Of Blocks", field: "numberOfBlocks" },
  { label: "Action", field: "action" },
];

export const HostelRows = (data: any) => {
  const res = data?.map((Hostel: any, index: number) => {
    let numberOfBlocks = Hostel?.numberOfBlock;

    return {
      hostelName: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={Hostel?.hostelName}
        />
      ),
      hostelType: <span>{Hostel?.type === 1 ? "Male" : "Female"}</span>,
      numberOfBlocks: (
        <TextCenteredInput
          type="number"
          placeholder="Number of Blocks"
          defaultValue={numberOfBlocks}
          alignText="left"
          onChangeHandler={(e: any) => {
            numberOfBlocks = e.target.value;
          }}
        />
      ),
      action: (
        <SmallButton
          buttonText="Edit"
          leftIcon={<AiFillEdit />}
          handleClick={() => {
            editHostel(
              Hostel?.hostelId,
              numberOfBlocks || Hostel?.numberOfBlock
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            store.getState().hostel.editingHostel
          }
          alignWithSiblings={true}
        />
      ),
    };
  });
  return res;
};

export const hostelSessionsCols = [
  { label: "Hostel Name", field: "hostelName" },
  { label: "Number Of Occupants", field: "numberOfOccupants" },
  { label: "Status", field: "status" },
  { label: "Department", field: "department" },
  { label: "Level", field: "level" },
  { label: "Session", field: "session" },
  { label: "Semester", field: "semester" },
  { label: "Action", field: "action" },
];

const statusOptions = [
  { label: "Enabled", value: 1 },
  { label: "Disabled", value: 2 },
];

export const hostelSessionsRows = (data: any, departments: any) => {
  const res = data?.map((Hostel: any, index: number) => {
    let hostelStatus = Hostel?.status;
    return {
      hostelName: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={Hostel?.hostel || "N/A"}
        />
      ),
      hostelType: <span>{Hostel?.hostelType || "N/A"}</span>,
      numberOfOccupants: <span>{Hostel?.numberOfOccupant || "N/A"}</span>,
      status: (
        <RegularSelector
          title={"Status"}
          dropdownItems={statusOptions}
          defaultValue={hostelStatus || Hostel?.status}
          name="hostelStatus"
          handleDropdownSelect={(e: any) => {
            hostelStatus = e.target.value;
          }}
        />
      ),
      department: (
        <span>
          {departments?.filter(
            (x: any) => x?.departmentId === Hostel?.departmentId
          )[0]?.departmentName || "N/A"}
        </span>
      ),
      level: Hostel?.level || "N/A",
      // (<UnderlinedSelector handleDropdownSelect={handleOnChange} dropdownItems={multiLevelOptions} value={Hostel?.level} title={"Level"}
      // name="Level"/>),
      session: <span>{Hostel?.session || "N/A"}</span>,
      semester: <span>{Hostel?.semester || "N/A"}</span>,
      action: (
        <SmallButton
          buttonText="save"
          leftIcon={<MdSave />}
          handleClick={() => {
            setHostelSessionStatus(
              Hostel?.hostelSessionId,
              hostelStatus || Hostel?.status
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            store.getState().hostel.settingHostelSessionStatus
          }
        />
      ),
    };
  });

  return res;
};

export const hostelBlocksCols = [
  // { label: "Hostel Name", field: "hostelName" },
  { label: "Block Name", field: "blockName" },
  { label: "Block Id", field: "blockId" },
  { label: "Status", field: "status" },
  { label: "Number of Rooms", field: "numberOfRooms" },
  { label: "Action", field: "action" },
];

export const hostelBlocksRows = (data: any, creatingHostelBlock: any) => {
  const res = data?.map((Hostel: any, index: number) => {
    let statusValue = Hostel?.status;
    let numberOfRoomsValue = Hostel?.numberOfRooms;
    return {
      blockId: <span>{Hostel?.blockId}</span>,
      status: (
        <RegularSelector
          title={"Status"}
          dropdownItems={statusOptions}
          defaultValue={statusValue}
          handleDropdownSelect={(e: any) => (statusValue = e.target.value)}
          name="blockStatus"
        />
      ),
      blockName: <span>{Hostel?.blockName}</span>,
      numberOfRooms: (
        <TextCenteredInput
          type="number"
          placeholder="Number of Rooms"
          defaultValue={numberOfRoomsValue}
          alignText="left"
          onChangeHandler={(e: any) => {
            numberOfRoomsValue =
              Number(e.target.value) > Number(Hostel?.numberOfRooms)
                ? e.target.value
                : Hostel?.numberOfRooms;
          }}
          name="blockNumberOfRooms"
        />
      ),
      action: (
        <SmallButton
          buttonText="save"
          leftIcon={<MdSave />}
          handleClick={() => {
            createHostelBlocks(
              statusValue,
              numberOfRoomsValue,
              Hostel?.blockId
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            creatingHostelBlock
          }
        />
      ),
    };
  });

  return res;
};

export const hostelBlockRoomsCols = [
  { label: "Hostel Name", field: "hostelName" },
  { label: "Hostel Type", field: "hostelType" },
  { label: "Block Name", field: "blockName" },
  { label: "Room Name", field: "roomName" },
  { label: "Number of Bunks", field: "numberOfBunks" },
  { label: "Action", field: "action" },
];

export const hostelBlockRoomsRows = (data: any) => {
  const res = data?.records?.map((Hostel: any) => {
    return {
      hostelName: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={Hostel?.hostelName}
        />
      ),
      hostelType: <span>{Hostel?.hostelType}</span>,
      blockName: <span>{Hostel?.blockName}</span>,
      roomName: <span>{Hostel?.roomName}</span>,
      numberOfBunks: (
        <UnderlinedSelector
          value={Hostel?.numberofBunks}
          title={"numberOfBunks"}
        />
      ),
      action: <SmallButton buttonText="save" leftIcon={<MdSave />} />,
    };
  });

  return res;
};

export const hostelBunksCols = [
  { label: "Room Number", field: "roomNumber" },
  { label: "Number Of Bunks", field: "numberOfBunks" },
  { label: "BunkStatus", field: "bunkStatus" },
  { label: "Action", field: "action" },
];

export const hostelBunksRows = (data: any, creatingHosteBlock: boolean) => {
  const res = data?.map((Hostel: any, index: number) => {
    let bunkStatusValue = Hostel?.status;
    let numberOfBunkValue = Hostel?.numberOfBunk;
    console.log({ Hostel });
    return {
      roomNumber: <span>{Hostel?.roomNumber}</span>,
      numberOfBunks: (
        <TextCenteredInput
          type="number"
          placeholder={"Number Of bunks"}
          defaultValue={numberOfBunkValue}
          alignText="left"
          onChangeHandler={(e: any) => {
            numberOfBunkValue = e.target.value;
          }}
        />
      ),
      bunkStatus: (
        <RegularSelector
          title={"Status"}
          dropdownItems={statusOptions}
          defaultValue={bunkStatusValue}
          handleDropdownSelect={(e: any) => {
            bunkStatusValue = e.target.value;
          }}
        />
      ),
      action: (
        <SmallButton
          buttonText="save"
          leftIcon={<MdSave />}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            creatingHosteBlock
          }
          handleClick={() => {
            editRoomBunks(
              Number(bunkStatusValue),
              Number(numberOfBunkValue),
              Hostel.roomBunkId
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
        />
      ),
    };
  });

  return res;
};
export const bunkSettingsCols = [
  { label: "Bunk Number", field: "bunkNumber" },
  { label: "Bunk Type", field: "bunkType" },
  { label: "BunkStatus", field: "bunkStatus" },
  { label: "Action", field: "action" },
];

export const bunkSettingsRows = (data: any, updatingBunkSettings: boolean) => {
  const res = data?.map((Hostel: any, index: any) => {
    let bunkNumberValue = Hostel?.bunkNumber;
    let bunkTypeValue = Hostel?.bunkType;
    let bunkStatusValue = Hostel?.status;
    return {
      bunkNumber: (
        <span>
          <TextCenteredInput
            defaultValue={bunkNumberValue}
            alignText="left"
            name="bunkNumber"
            onChangeHandler={(e: any) => {
              bunkNumberValue = e.target.value;
            }}
          />
        </span>
      ),
      bunkType: (
        <RegularSelector
          title={"Bunk Type"}
          defaultValue={bunkTypeValue}
          name="bunkType"
          handleDropdownSelect={(e: any) => {
            bunkTypeValue = e.target.value;
          }}
          dropdownItems={bunkTypeOptions}
        />
      ),
      bunkStatus: (
        <RegularSelector
          title={"Status"}
          dropdownItems={statusOptions}
          defaultValue={bunkStatusValue}
          handleDropdownSelect={(e: any) => {
            bunkStatusValue = e.target.value;
          }}
          name="bunkStatus"
        />
      ),
      action: (
        <SmallButton
          buttonText="save"
          leftIcon={<MdSave />}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            updatingBunkSettings
          }
          handleClick={() => {
            editBunkSettings(
              Hostel?.bunkId,
              Number(bunkTypeValue),
              Number(bunkStatusValue),
              bunkNumberValue
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
        />
      ),
    };
  });

  return res;
};

export const allocationReportCols = [
  { label: "Name", field: "name" },
  { label: "Block Name", field: "block" },
  { label: "Bunk Number", field: "bunk" },
  { label: "Allocation", field: "allocation" },
  // { label: "Email Address", field: "email" },
  // { label: "Phone Number", field: "phone" },
  // { label: "Faculty", field: "faculty" },
  // { label: "Department", field: "department" },
  { label: "Current Level", field: "level" },
  { label: "Session", field: "session" },
  { label: "Bunk ID", field: "bunkNumber" },
  { label: "Reg. No", field: "regNumber" },
];

export const hostelAllocationsCols = [
  { label: "Bunk No", field: "bunk" },
  { label: "Bunk Type", field: "bunkType" },
  { label: "Allocation", field: "allocation" },
  { label: "Occupant", field: "name" },
  { label: "Reg. No", field: "matricNo" },
  { label: "", field: "action1" },
  { label: "", field: "action2" },
];

export const hostelAllocationRows = (
  data: any,
  assigningBunkToStudent: any
) => {
  const res = data?.records?.map((student: any, index: number) => {
    let matricNumberValue = student?.matricNumber;

    return {
      name: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={student?.firstName}
          lastName={student?.lastName}
          userName={student?.otherName}
        />
      ),
      allocation: (
        <span>
          {student?.blockName} at {student?.hostelName}
        </span>
      ),
      email: <span>{student?.emailAddress || "N/A"}</span>,
      bunk: <span>{student?.bunkNumber || "N/A"} </span>,
      bunkType: <span>{student?.bunkType || "N/A"} </span>,
      block: <span>{student?.blockName || "N/A"} </span>,
      phone: <span>{student?.mobilePhone || "N/A"}, </span>,
      faculty: <span>{student?.facultyName || "N/A"} </span>,
      department: <span>{student?.departmentName || "N/A"} </span>,
      level: <span>{student?.levelName || "N/A"} </span>,
      session: <span>{student?.sessionName || "N/A"} </span>,
      semester: <span>{student?.semesterName || "N/A"} </span>,
      bunkNumber: <span>{student?.bunkId || "N/A"} </span>,
      bunkId: student?.bunkId ? (
        <TextCenteredInput
          placeholder={"Bunk Id"}
          defaultValue={student?.bunkId}
          alignText="left"
          name="newBunkId"
          onChangeHandler={handleOnChange}
        />
      ) : (
        "N/A"
      ),
      matricNo: (
        <TextCenteredInput
          placeholder={"Matric Number"}
          defaultValue={matricNumberValue}
          alignText="left"
          name="newMatricNummber"
          onChangeHandler={(e: any) => {
            matricNumberValue = e.target.value;
          }}
        />
      ),
      regNumber: <span>{student?.matricNumber || "N/A"}</span>,
      action1: (
        <SmallButton
          buttonText="save"
          leftIcon={<MdSave />}
          handleClick={() => {
            assignBunk(student?.bunkId, matricNumberValue);
            store.dispatch(setClickedButtonIndex(index));
            store.dispatch(setClickedButtonName("save"));
          }}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            store.getState().hostel.clickedButtonName === "save" &&
            assigningBunkToStudent
          }
        />
      ),
      action2: (
        <SmallButton
          buttonText="Revoke"
          leftIcon={<MdSave />}
          handleClick={() => {
            revokeStudentBunk(student?.bunkId, student?.matricNumber);
            store.dispatch(setClickedButtonIndex(index));
            store.dispatch(setClickedButtonName("revoke"));
          }}
          loading={
            store.getState().hostel.clickedButtonIndex === index &&
            store.getState().hostel.clickedButtonName === "revoke" &&
            assigningBunkToStudent
          }
        />
      ),
    };
  });

  return res;
};

export const hostelExportsCols = [
  { label: "Report Name", field: "name" },
  { label: "Report Type", field: "type" },
  { label: "Date Created", field: "date" },
  { label: "", field: "action" },
];
export const hostelExportsRows = (data: any) => {
  const res = data?.records?.map((report: any) => {
    return {
      name: <span>{report?.reportName}</span>,
      type: (
        <span>
          {report?.reportType === 2 ? "Active Students" : "Admitted Students"}
        </span>
      ),
      date: <span>{moment(report?.mobilePhone).format("DD/MM/YYYY")},</span>,
      action: <Link to={report?.reportUrl}>Download</Link>,
    };
  });

  return res;
};
