import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import CustomTable2 from "../CustomTable2";
import { hostelBlocksCols, hostelBlocksRows } from "./utils";

const HostelSessionTable = () => {
  const { gettingHostelBlocks, hostelBlocks, creatingHostelBlock } =
    useAppSelector(selectHostel);
  const dispatch = useAppDispatch();
  const rows = hostelBlocksRows(hostelBlocks, creatingHostelBlock);

  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: hostelBlocksCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingHostelBlocks}
      noRecord={hostelBlocks?.length === 0}
      page={hostelBlocks?.currentPage}
      pageCount={hostelBlocks?.totalRecords}
      pageSize={hostelBlocks?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default HostelSessionTable;
