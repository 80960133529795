import React, { useState } from "react";
import { GradeContainer, ItemsCenterJustifyBtw, Subject } from "./styles";
import { filter } from "./utils";
import { LuMinusCircle } from "react-icons/lu";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  addToOlevelResult,
  removeFromOLevelResult,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import SmallButton from "../../../Components/ApplicationComponents/Form/Buttons/SmallButton";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";

const AddResult = () => {
  const [subject, setSubject] = useState<any>(null);
  const [grade, setGrade] = useState<any>(null);
  const { OlevelResult } = useAppSelector(selectapplicationForm);
  const { allSubjects, allSubjectGrades } = useAppSelector(
    selectApplicationUtils
  );
  const dispatch = useAppDispatch();

  const subjectFilter = filter(
    allSubjects,
    "olevelSubjectName",
    "olevelSubjectId"
  );
  const subjectGradeFilter = filter(
    allSubjectGrades,
    "olevelSubjectGradeName",
    "olevelSubjectGradeId"
  );

  const handleSelectSubject = (name: string, value: string | number) => {
    setSubject(value);
  };
  const handleSelectGrade = (name: string, value: string | number) => {
    setGrade(value);
  };

  const handleAdd = () => {
    if (!grade || !subject) {
      toast.error("can't add empty grade and subject");
    } else {
      if (OlevelResult?.length < 9) {
        const checkSubject = OlevelResult?.filter(
          (x: any) => x?.subjectId === subject
        );
        if (checkSubject?.length > 0) {
          toast.info("subject already exists");
        } else {
          dispatch(addToOlevelResult({ subjectId: subject, gradeId: grade }));
        }
      } else {
        toast.info("subject has reached max of 9");
      }
    }
    setSubject(null);
    setGrade(null);
  };

  return (
    <>
      <ItemsCenterJustifyBtw className="flex justify-between">
        <CustomRegularDropdown
          title="Subject"
          handleDropdownSelect={handleSelectSubject}
          value={subject}
          dropdownItems={subjectFilter}
        />
        <CustomRegularDropdown
          title="Grade"
          handleDropdownSelect={handleSelectGrade}
          value={grade}
          dropdownItems={subjectGradeFilter}
        />
      </ItemsCenterJustifyBtw>
      {OlevelResult?.map((result: any) => (
        <div
          className="remain-row"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Subject>
            {
              subjectFilter?.filter(
                (x: any) => x?.value === result?.subjectId
              )[0]?.label
            }
          </Subject>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <GradeContainer>
              {
                subjectGradeFilter?.filter(
                  (x: any) => x?.value === result?.gradeId
                )[0]?.label
              }
            </GradeContainer>
            <LuMinusCircle
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(removeFromOLevelResult(result))}
            />
          </div>
        </div>
      ))}

      <SmallButton buttonText="Add" handleClick={handleAdd} />
    </>
  );
};

export default AddResult;
