import { toast } from "react-toastify";
import { store } from "../../Redux/app/store";
import api from "../baseUri";
import {
  setDownloadingPendingAdmissionsReport,
  setGettingInitiatedAdmissions,
  setGettingPendingAdmissions,
  setGettingSinglePendingAdmission,
  setGrantingAdmission,
  setInitiatedAdmissions,
  setPendingAdmissions,
  setRejectingAdmission,
  setSinglePendingAdmission,
} from "../../Redux/features/admissionSlice";
import { handleActiveModal } from "../../Redux/features/navSlice";
import {
  setGettingAndUpdatingSinglePendingAdmission,
  updateSinglePendingAdmission,
} from "../../Redux/features/Application/ApplicationFormSlice";

export const getPendingAdmissions = async (
  SearchPrams: string | null,
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  PageNumber: number | null,
  PageSize: number | null
) => {
  store.dispatch(setGettingPendingAdmissions(true));
  const params: any = {
    SessionId,
    SearchPrams,
    FacultyId,
    DepartmentId,
    PageNumber,
    PageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Admission/Pending`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPendingAdmissions(data));
      store.dispatch(setGettingPendingAdmissions(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPendingAdmissions(false));
    });
};

export const grantAdmission = async (
  personId: number,
  admissionMode: number,
  mesage: string | null
) => {
  store.dispatch(setGrantingAdmission(true));
  const params: any = {
    personId,
    admissionMode,
    mesage,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Admission/Grant`, { params })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setGrantingAdmission(false));
      store.dispatch(handleActiveModal(""));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGrantingAdmission(false));
    });
};

export const rejectAdmission = async (
  personId: number,
  mesage: string | null
) => {
  store.dispatch(setRejectingAdmission(true));
  const params: any = {
    personId,
    mesage,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Admission/Reject`, { params })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setRejectingAdmission(false));
      store.dispatch(handleActiveModal(""));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setRejectingAdmission(false));
    });
};

export const getSinglePendingAdmission = async (personId: number) => {
  store.dispatch(setGettingSinglePendingAdmission(true));
  const params: any = {
    personId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Admission/PendingById`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setSinglePendingAdmission(data));
      store.dispatch(setGettingSinglePendingAdmission(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSinglePendingAdmission(false));
    });
};

export const getAndUpdateSinglePendingAdmission = async (personId: number) => {
  store.dispatch(setGettingAndUpdatingSinglePendingAdmission(true));
  const params: any = {
    personId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Admission/EditModel`, { params })
    .then((res) => {
      const { data } = res;
      const record = data?.record;
      store.dispatch(updateSinglePendingAdmission(record));
      store.dispatch(setGettingAndUpdatingSinglePendingAdmission(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAndUpdatingSinglePendingAdmission(false));
    });
};

export const getInitiatedAdmissions = async (
  SessionId: number | null,
  SearchPrams: string | null,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingInitiatedAdmissions(true));

  const params: any = {
    PageSize,
    PageNumber,
    SessionId,
    SearchPrams,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  await api
    .get(`/Admission/InComplete`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setInitiatedAdmissions(data));
      store.dispatch(setGettingInitiatedAdmissions(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingInitiatedAdmissions(false));
    });
};

export const DownloadPendingAdmissionsReport = async (
  ReportName: string,
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  PageSize: number | null,
  PageNumber: number | null,
  SearchPrams: string | null
) => {
  store.dispatch(setDownloadingPendingAdmissionsReport(true));
  const params: any = {
    ReportName,
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    SearchPrams,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Admission/Pending/downloadable", { params })
    .then((res) => {
      store.dispatch(setDownloadingPendingAdmissionsReport(false));
      toast.success("Report exported Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDownloadingPendingAdmissionsReport(false));
    });
};
