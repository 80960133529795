import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { IoMdAdd } from "react-icons/io";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import ExamClearanceFilter from "./ExamClearanceFilter";
import {
  createExamClearance,
  getClearance,
} from "../../../Apis/Private/students";
import ExamClearanceTable from "../../../Tables/AllStudents/ExamClearanceTable";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import { toast } from "react-toastify";
// import { selectUser } from "../../../Redux/features/userSlice";

const ExamClearance = () => {
  const [createHostelSession, setCreateHostelSession] = useState(false);
  const { SessionId } = useAppSelector(selectHostel);
  const {
    clearanceSession,
    clearanceSemester,
    clearanceStartDate,
    clearanceEndDate,
    updatingClearance,
  } = useAppSelector(selectStudents);
  const { sessions, semesters } = useAppSelector(selectUtil);
  // const { currentUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  useEffect(() => {
    getClearance(SessionId);
  }, [SessionId]);

  const handleCreateClearance = () => {
    if (
      clearanceSession &&
      clearanceSemester &&
      clearanceStartDate &&
      clearanceEndDate
    ) {
      createExamClearance(
        clearanceSession,
        clearanceSemester,
        clearanceStartDate,
        clearanceEndDate
      );
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Exam Clearance</PageTitle>
        {!createHostelSession && (
          <SmallButton
            buttonText="Add"
            leftIcon={<IoMdAdd />}
            handleClick={() => {
              setCreateHostelSession(true);
            }}
            // disabled={currentUser?.roleId !== (1 || 17)}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="justify-end">
        {createHostelSession && (
          <>
            <UnderlinedSelector
              title={"Session"}
              name="clearanceSession"
              value={clearanceSession}
              handleDropdownSelect={handleSelect}
              dropdownItems={sessionFilter}
            />
            <UnderlinedSelector
              title={"Semester"}
              name="clearanceSemester"
              value={clearanceSemester}
              handleDropdownSelect={handleSelect}
              dropdownItems={semesterFilter}
            />
            <TextCenteredInput
              placeholder={"Start date"}
              name="clearanceStartDate"
              value={clearanceStartDate}
              type="date"
              onChangeHandler={handleOnChange}
              alignText="left"
            />
            <TextCenteredInput
              placeholder={"End date"}
              name="clearanceEndDate"
              value={clearanceEndDate}
              type="date"
              onChangeHandler={handleOnChange}
              alignText="left"
            />
            <SmallButton
              buttonText="Create"
              leftIcon={<IoMdAdd />}
              loading={updatingClearance}
              handleClick={handleCreateClearance}
              alignWithSiblings={true}
            />
          </>
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw>
        <span></span>
        <ExamClearanceFilter />
      </ItemsCenterJustifyBtw>
      <ExamClearanceTable />
    </>
  );
};

export default ExamClearance;
