import { store } from "../../Redux/app/store";
import {
  setAllGeneratedReportBySession,
  setAllTransactionChart,
  setGettingAllGeneratedReportBySession,
  setGettingAllTransactionChart,
  setGettingReportDownload,
  setGettingStudentsNumberBySession,
  setReportDownload,
  setStudentsNumberBySession,
} from "../../Redux/features/reportSlice";
import api from "../baseUri";
import { toast } from "react-toastify";

export const sendReportDownload = async (
  startdate: string,
  enddate: string
) => {
  store.dispatch(setGettingReportDownload(true));
  const params: any = {
    startdate,
    enddate,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/SendReport/downloadable", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setReportDownload(data?.response));
      store.dispatch(setGettingReportDownload(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingReportDownload(false));
    });
};

export const getAllGeneratedReportBySession = async (
  SessionId: number | null,
  Reportype: number | null,
  ReportName: string | null,
  PageSize: number | null
) => {
  store.dispatch(setGettingAllGeneratedReportBySession(true));
  const params: any = {
    SessionId,
    ReportName,
    Reportype,
    PageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/AllGeneratedReportBySession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllGeneratedReportBySession(data));
      store.dispatch(setGettingAllGeneratedReportBySession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllGeneratedReportBySession(false));
    });
};
export const getStudentsNumberBySession = async (session: number | null) => {
  store.dispatch(setGettingStudentsNumberBySession(true));
  const params: any = {
    session,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetStudentsNumberBySession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsNumberBySession(data));
      store.dispatch(setGettingStudentsNumberBySession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentsNumberBySession(false));
    });
};

export const getAllTransactionChart = async (
  SessionId: number | null,
  SemesterId?: number | null
) => {
  store.dispatch(setGettingAllTransactionChart(true));
  const params: any = {
    SessionId,
    SemesterId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetPaymentSummaryChart", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllTransactionChart(data));
      store.dispatch(setGettingAllTransactionChart(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllTransactionChart(false));
    });
};
