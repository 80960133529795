import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectReport } from "../../Redux/features/reportSlice";
import { getStudentsFormDetails } from "../../Redux/features/studentsSlice";
import CustomTable2 from "../CustomTable2";
import { studentsExportRows, studentsExportCols } from "./utils";

const ExportedStudentReportTable = () => {
  const { gettingAllGeneratedReportBySession, allGeneratedReportBySession } =
    useAppSelector(selectReport);
  const dispatch = useAppDispatch();
  const rows = studentsExportRows(allGeneratedReportBySession);

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };


  return (
    <CustomTable2
      data={{ rows, columns: studentsExportCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingAllGeneratedReportBySession}
      noRecord={allGeneratedReportBySession?.records?.length === 0}
      page={allGeneratedReportBySession?.currentPage}
      pageCount={allGeneratedReportBySession?.totalRecords}
      pageSize={allGeneratedReportBySession?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default ExportedStudentReportTable;
