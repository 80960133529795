/* eslint-disable react-hooks/exhaustive-deps */
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import AllPaymentsTable from "../../../Tables/AllPayments";
import SummarySection from "./SummarySection";
import { useEffect } from "react";
import {
  getPayments,
  getPaymentsDownload,
  getFeePaymentSummary,
} from "../../../Apis/Private/payment";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../../Redux/features/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import FilterSection from "../../../Components/Filters/PaymentFilterSection";
// import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import {
  getReportsFormDetails,
  selectReport,
} from "../../../Redux/features/reportSlice";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { PiExportBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { selectUser } from "../../../Redux/features/userSlice";

const Payments = () => {
  const {
    SessionId,
    SemesterId,
    FeeScheduledId,
    PaymentMeans,
    FacultyId,
    DepartmentId,
    LevelId,
    ReportName,
    StartDate,
    EndDate,
    GenderId,
    AsPdf,
    PageSize,
    PageNumber,
  } = useAppSelector(selectPayment);
  const { paymentReportName } = useAppSelector(selectReport);
  const { gettingDownloadablePayment } = useAppSelector(selectPayment);
  const { userPermissions } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleExport = (e: any) => {
    e.preventDefault();
    if (paymentReportName) {
      getPaymentsDownload(
        paymentReportName,
        SessionId,
        SemesterId,
        FeeScheduledId,
        PaymentMeans,
        FacultyId,
        DepartmentId,
        LevelId,
        GenderId,
        PageSize,
        PageNumber
      );
    } else {
      toast.error("Enter the report name");
    }
  };

  const handleFetchPayments = () => {
    if (SessionId) {
    getPayments(
      SessionId,
      SemesterId,
      FeeScheduledId,
      PaymentMeans,
      FacultyId,
      DepartmentId,
      LevelId,
      ReportName,
      StartDate,
      EndDate,
      GenderId,
      AsPdf,
      PageSize,
      PageNumber
    );
    getFeePaymentSummary(
      SessionId,
      SemesterId,
      FeeScheduledId,
      PaymentMeans,
      FacultyId,
      DepartmentId,
      LevelId,
      ReportName,
      StartDate,
      EndDate,
      GenderId,
      AsPdf,
      PageSize,
      PageNumber
    );
    } else {
      toast.info("Please select at least session to filter");
    }
  };

  useEffect(() => {
    if (SessionId) {
      getPayments(
        SessionId,
        SemesterId,
        FeeScheduledId,
        PaymentMeans,
        FacultyId,
        DepartmentId,
        LevelId,
        ReportName,
        StartDate,
        EndDate,
        GenderId,
        AsPdf,
        PageSize,
        PageNumber
      );
      getFeePaymentSummary(
        SessionId,
        SemesterId,
        FeeScheduledId,
        PaymentMeans,
        FacultyId,
        DepartmentId,
        LevelId,
        ReportName,
        StartDate,
        EndDate,
        GenderId,
        AsPdf,
        PageSize,
        PageNumber
      );
    }
  }, [PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Payments</PageTitle>
      <ItemsCenterJustifyBtw className="justify-end">
        <TextCenteredInput
          placeholder="Type Report Name to Export"
          alignText="left"
          name="paymentReportName"
          value={paymentReportName}
          onChangeHandler={handleOnChange}
        />
        {paymentReportName && (
          <SmallButton
            buttonText="Export"
            leftIcon={<PiExportBold />}
            loading={gettingDownloadablePayment}
            handleClick={handleExport}
            disabled={!userPermissions?.includes("export-payments")}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw>
        {/* <OutlinedSearchInput placeholder="Search Student" /> */}
        <FilterSection filterFunction={handleFetchPayments} />
      </ItemsCenterJustifyBtw>
      <SummarySection />
      <AllPaymentsTable />
    </>
  );
};

export default Payments;
