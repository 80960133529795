/* eslint-disable react-hooks/exhaustive-deps */
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { IoMdAdd } from "react-icons/io";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import { toast } from "react-toastify";
import {
  createPaymentFee,
  getPaymentFees,
} from "../../../Apis/Private/payment";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../../Redux/features/paymentSlice";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import FeeScheduleTable from "../../../Tables/AllPayments/FeeScheduleTable";
import { canPayInstallmentOptions } from "./util";

const FeeMapping = () => {
  const [createFeeSchedule, setCreateFeeSchedule] = useState(false);
  const { creatingFeePayment, feeScheduleName, canPayInstallment } =
    useAppSelector(selectPayment);
  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPaymentsFormDetails({ name, value }));
  };

  const handleFeeCreation = () => {
    if (feeScheduleName && canPayInstallment) {
      createPaymentFee(feeScheduleName, canPayInstallment);
    } else {
      toast.error("Please fill all fields");
    }
  };

  useEffect(() => {
    getPaymentFees();
  }, []);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Fee Schedule</PageTitle>
        {!createFeeSchedule && (
          <SmallButton
            buttonText="Add"
            leftIcon={<IoMdAdd />}
            handleClick={() => {
              setCreateFeeSchedule(true);
            }}
          />
        )}
      </ItemsCenterJustifyBtw>

      {createFeeSchedule && (
        <ItemsCenterJustifyBtw className="justify-end">
          <UnderlinedSelector
            title={"Can Be Paid by Installment?."}
            name="canPayInstallment"
            value={canPayInstallment}
            handleDropdownSelect={handleSelect}
            dropdownItems={canPayInstallmentOptions}
          />
          <TextCenteredInput
            type="text"
            placeholder={"Fee Schedule Name "}
            name="feeScheduleName"
            value={feeScheduleName}
            onChangeHandler={handleOnChange}
            alignText="left"
          />
          <SmallButton
            buttonText="Create"
            leftIcon={<IoMdAdd />}
            loading={creatingFeePayment}
            handleClick={handleFeeCreation}
          />
        </ItemsCenterJustifyBtw>
      )}
      <ItemsCenterJustifyBtw>
        <span></span>
        {/* <FilterSection
          resetFilterFunction={() => dispatch(resetPaymentParams())}
          filterFunction={handleGetFeeMappings}
        /> */}
      </ItemsCenterJustifyBtw>
      <FeeScheduleTable />
    </>
  );
};

export default FeeMapping;
