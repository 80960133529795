import styled from "styled-components";

export const OverlayContainer = styled.div`
  position: fixed;
  background: #fff;
  opacity: 0.85;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  gap: 20px;

  div {
    margin-top: 50px;
    font-size: 20px;
  }
`;
