import { FC } from "react";
import { styled } from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import { media } from "../../../Screens";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  ${media.tablet} {
    width: 352px;
  }
  svg {
    font-size: 16px;
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  outline: none;
`;

interface SearchInputProps {
  placeholder: string;
  name?: string;
  value?: any;
  onChangeHandler?: any;
  searchFunction?: any;
}

const OutlinedSearchInput: FC<SearchInputProps> = ({
  placeholder,
  name,
  onChangeHandler,
  searchFunction,
  value,
}) => {
  // const [searchValue, setSearchValue] = useState(value);
  // const dispatch = useAppDispatch();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchValue(e.target.value); // Update local state
    onChangeHandler(e); // Call the parent handler
  };

  const handleSearch = () => {
    searchFunction();
  };

  const searchOnEnter = (e: any) => {
    if (e.which === 13) {
      searchFunction();
    }
  };

  return (
    <Container>
      <Input
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={handleInput}
        onKeyDown={searchOnEnter}
      />
      <AiOutlineSearch
        data-testid="search-icon"
        onClick={handleSearch}
        cursor="pointer"
      />
    </Container>
  );
};

export default OutlinedSearchInput;
