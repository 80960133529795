/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import AllStudentsTable from "../../../Tables/AllStudents";
import SummarySection from "./SummarySection";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import {
  getActiveStudentDownload,
  getAllStudents,
} from "../../../Apis/Private/students";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import { PiExportBold } from "react-icons/pi";

import {
  getReportsFormDetails,
  selectReport,
} from "../../../Redux/features/reportSlice";
import { toast } from "react-toastify";
import { selectUser } from "../../../Redux/features/userSlice";
import FilterSection from "../../../Components/Filters/StudentsFilterSection";
const Students = () => {
  const {
    SessionId,
    FacultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    StudentType,
    SearchPrams,
    LevelId,
  } = useAppSelector(selectStudents);
  const { studentReportName } = useAppSelector(selectReport);
  const { gettingActiveStudentsDownloadable } = useAppSelector(selectStudents);
  const { userPermissions } = useAppSelector(selectUser);
  const [getSummary, setGetSummary] = useState(false);

  const dispatch = useAppDispatch();

  const handleOnChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleExport = (e: any) => {
    e.preventDefault();
    if (studentReportName) {
      getActiveStudentDownload(
        studentReportName,
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        PageSize,
        PageNumber,
        StudentType === 1 ? "GetAdmitedStudents" : "ActiveStudents"
      );
    } else {
      toast.error("Enter the report name");
    }
  };

  const handleGettingStudents = () => {
    setGetSummary(!getSummary);
    dispatch(getStudentsFormDetails({ name: "allStudents", value: null }));
    // if (SessionId) {
    getAllStudents(
      SessionId,
      LevelId,
      FacultyId,
      DepartmentId,
      SearchPrams,
      StudentType,
      PageSize,
      PageNumber
    );
    // }
  };

  useEffect(() => {
    if (SearchPrams) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        StudentType,
        PageSize,
        PageNumber
      );
    }
    if (SessionId) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        StudentType,
        PageSize,
        PageNumber
      );
    }
    if (PageNumber || PageSize) {
      getAllStudents(
        SessionId,
        LevelId,
        FacultyId,
        DepartmentId,
        SearchPrams,
        StudentType,
        PageSize,
        PageNumber
      );
    }
  }, [PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Students</PageTitle>
      <ItemsCenterJustifyBtw className="justify-end">
        <TextCenteredInput
          placeholder="Type Report Name to Export"
          alignText="left"
          name="studentReportName"
          value={studentReportName}
          onChangeHandler={handleOnChange}
        />
        {studentReportName && (
          <SmallButton
            buttonText="Export"
            leftIcon={<PiExportBold />}
            loading={gettingActiveStudentsDownloadable}
            handleClick={handleExport}
            disabled={!userPermissions?.includes("export-students")}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="justify-between">
        <OutlinedSearchInput
          placeholder="Search by name or email"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={getStudentsFormDetails}
        />
        <FilterSection filterFunction={handleGettingStudents} />
      </ItemsCenterJustifyBtw>
      <SummarySection getSummary={getSummary} />
      <AllStudentsTable />
    </>
  );
};
export default Students;
