import { toast } from "react-toastify";
import { store } from "../../Redux/app/store";
import {
  setLoggedInUser,
  setLoginUser,
  setResettingPassword,
  setUser,
  setUserPermission,
} from "../../Redux/features/userSlice";
import api from "../baseUri";

export const SendOTP = async (username: string, navigate: any, to: string) => {
  store.dispatch(setLoginUser(true));
  const params = {
    userName: username,
  };
  await api
    .get("/Account/LogIn2FA", { params })
    .then(() => {
      toast.success("OTP sent to your email");
      store.dispatch(setLoginUser(false));
      navigate(to);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLoginUser(false));
    });
};
export const loginUser = async (
  username: string,
  password: string,
  otp: string
) => {
  store.dispatch(setLoginUser(true));
  const body = {
    username,
    password,
    otp,
  };
  await api
    .post("/Account/AdminLogin", body)
    .then((res) => {
      window.location.replace("/");
      if (
        res?.data?.response?.roleId !== 2 &&
        res?.data?.response?.roleId !== 3 &&
        res?.data?.response?.roleId !== 10 &&
        res?.data?.response?.roleId !== 15
      ) {
        store.dispatch(setUser(res?.data?.response));
        store.dispatch(setUserPermission(res?.data?.permissions));
        store.dispatch(setLoggedInUser(res?.data));
        store.dispatch(setLoginUser(false));
      } else {
        toast.error("This role cannot login to the app");
        store.dispatch(setLoginUser(false));
      }
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLoginUser(false));
    });
};

export const resetPassword = async (
  username: string,
  password: string,
  newPassword: string,
  otp: string
) => {
  store.dispatch(setResettingPassword(true));
  const body = {
    username,
    password,
    newPassword,
    otp,
  };
  await api
    .post("Account/ForgetPasswordAdmin", body)
    .then((res) => {
      store.dispatch(setResettingPassword(false));
      toast.success(`Password reset Successful`);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setResettingPassword(false));
    });
};

export const adminResetPassword = async (
  username: string,
  password: string,
  newPassword: string,
  otp: string
) => {
  store.dispatch(setResettingPassword(true));
  const body = {
    username,
    password,
    newPassword,
    otp,
  };
  await api
    .post("Account/ForgetPasswordAdmin", body)
    .then((res) => {
      store.dispatch(setResettingPassword(false));
      toast.success(`Password reset Successful`);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setResettingPassword(false));
    });
};
