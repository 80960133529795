import React from "react";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { filter } from "../util";
import {
  getStudentsFormDetails,
} from "../../../Redux/features/studentsSlice";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import ReactSuiteDropdown from "../../../Components/Form/Dropdowns/NewDropdown";

const ReportFilter = () => {
  const { sessions } = useAppSelector(selectUtil);
  const { SessionId } = useAppSelector(selectPayment);

  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  return (
    <div>
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
    </div>
  );
};

export default ReportFilter;
