import {
  createAsyncThunk,
  createSlice /*PayloadAction*/,
} from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface navState {
  modalId: string;
  showSidebar: boolean;

  // modal states
  activeModal: string;
}

const initialState: navState = {
  modalId: "",
  showSidebar: false,

  // modal states
  activeModal: "",
};

export const incrementAsync = createAsyncThunk(
  "nav/fetchCount",
  async (amount: number) => {
    // const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    // return response.data;
  }
);

export const navSlice = createSlice({
  name: "nav",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentModal: (state, action) => {
      state.modalId = action.payload;
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    handleActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const { setCurrentModal, toggleSidebar, handleActiveModal } = navSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.nav.value)`
export const selectNav = (state: RootState) => state.nav;

export default navSlice.reducer;
