import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useEffect, useState } from "react";
import { createNewHostel, getHostels } from "../../../Apis/Private/hostel";
import AllHostelsTable from "../../../Tables/AllHostels";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { IoMdAdd } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import { genderOptions } from "./util";
import { toast } from "react-toastify";

const Hostels = () => {
  const [createHostel, setCreateHostel] = useState(false);
  const {
    hostelNumberOfBlocks,
    hostelName,
    hostelGenderId,
    creatingHostel,
    settingHostelSession,
  } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getHostelsFormDetails({ name, value }));
  };
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };

  const handleCreateHostel = () => {
    if (hostelNumberOfBlocks && hostelName && hostelGenderId) {
      createNewHostel(hostelName, hostelNumberOfBlocks, hostelGenderId).then(
        () => {
          setCreateHostel(false);
        }
      );
    } else {
      toast.error("Please fill all fields");
    }
  };

  useEffect(() => {
    getHostels();
  }, [creatingHostel, settingHostelSession]);

  return (
    <>
      <ItemsCenterJustifyBtw>
        <PageTitle>Hostels</PageTitle>
        {!createHostel && (
          <SmallButton
            buttonText="Add"
            leftIcon={<IoMdAdd />}
            handleClick={() => {
              setCreateHostel(true);
            }}
          />
        )}
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="justify-end">
        {createHostel && (
          <>
            <TextCenteredInput
              placeholder="Hostel Name"
              alignText="left"
              name="hostelName"
              onChangeHandler={handleOnChange}
              value={hostelName}
            />
            <UnderlinedSelector
              title={"Hostel Type"}
              name="hostelGenderId"
              value={hostelGenderId}
              handleDropdownSelect={handleSelect}
              dropdownItems={genderOptions}
            />
            <TextCenteredInput
              placeholder="Number of Blocks"
              alignText="left"
              name="hostelNumberOfBlocks"
              onChangeHandler={handleOnChange}
              value={hostelNumberOfBlocks}
              type="number"
            />
            <SmallButton
              buttonText="Create"
              leftIcon={<IoMdAdd />}
              loading={creatingHostel}
              handleClick={handleCreateHostel}
              alignWithSiblings={true}
            />
          </>
        )}
      </ItemsCenterJustifyBtw>
      <AllHostelsTable />
    </>
  );
};

export default Hostels;
