import React from "react";
import HashingLoader from "./HashLoader";
import { OverlayContainer } from "./style";

const Overlay = () => {
  return (
    <OverlayContainer>
      <HashingLoader size={50} color="#4361ee" />
      <div>Confirming Payment...</div>
    </OverlayContainer>
  );
};

export default Overlay;
