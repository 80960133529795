import React from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import { useAppDispatch } from "../../../Redux/app/hooks";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";
import { getApplicationDetails } from "../../../Redux/features/Application/ApplicationFormSlice";

const ProgramDetails = () => {
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };
  return (
    <AuthPageLayout authText="Program Details">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <CustomRegularDropdown
            title="Admission Type"
            name="admissionType"
            handleDropdownSelect={handleOnChange}
            dropdownItems={[{ label: "JAMB UTME", value: "JAMB UTME" }]}
          />
          <CustomRegularDropdown
            title="Faculty"
            name="admissionType"
            handleDropdownSelect={handleOnChange}
            dropdownItems={[{ label: "JAMB UTME", value: "JAMB UTME" }]}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          // handleClick={handleProceed}
          // loading={sendingApplicationForm}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default ProgramDetails;