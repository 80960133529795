import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface admissionState {
  gettingPendingAdmissions: boolean;
  pendingAdmissions: any;
  gettingApprovedAdmissions: boolean;
  approvedAdmissions: any;
  gettingInitiatedAdmissions: boolean;
  initiatedAdmissions: any;
  grantingAdmission: boolean;
  rejectingAdmission: boolean;
  gettingSinglePendingAdmission: boolean;
  singlePendingAdmission: any;
  downloadingPendingAdmissionREport: boolean;

  // admission state
  reason: string | null;
  personId: number | null;
  admissionMode: number | null;
}

const initialState: admissionState = {
  gettingPendingAdmissions: false,
  pendingAdmissions: null,
  gettingApprovedAdmissions: false,
  approvedAdmissions: null,
  gettingInitiatedAdmissions: false,
  initiatedAdmissions: null,
  grantingAdmission: false,
  rejectingAdmission: false,
  gettingSinglePendingAdmission: false,
  singlePendingAdmission: null,
  downloadingPendingAdmissionREport: false,

  // admission mode
  reason: "",
  personId: null,
  admissionMode: null,
};

export const admissionSlice = createSlice({
  name: "admission",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingSinglePendingAdmission: (state, action) => {
      state.gettingSinglePendingAdmission = action.payload;
    },
    setSinglePendingAdmission: (state, action) => {
      state.singlePendingAdmission = action.payload;
    },
    setGettingPendingAdmissions: (state, action) => {
      state.gettingPendingAdmissions = action.payload;
    },
    setPendingAdmissions: (state, action) => {
      state.pendingAdmissions = action.payload;
    },
    setGettingApprovedAdmissions: (state, action) => {
      state.gettingApprovedAdmissions = action.payload;
    },
    setApprovedAdmissions: (state, action) => {
      state.approvedAdmissions = action.payload;
    },
    setGettingInitiatedAdmissions: (state, action) => {
      state.gettingInitiatedAdmissions = action.payload;
    },
    setInitiatedAdmissions: (state, action) => {
      state.initiatedAdmissions = action.payload;
    },
    setGrantingAdmission: (state, action) => {
      state.grantingAdmission = action.payload;
    },
    setRejectingAdmission: (state, action) => {
      state.rejectingAdmission = action.payload;
    },
    setDownloadingPendingAdmissionsReport: (state, action) => {
      state.downloadingPendingAdmissionREport = action.payload;
    },
    resetAdmissions: (state) => {
      state.gettingPendingAdmissions = false;
      state.pendingAdmissions = null;
      state.gettingApprovedAdmissions = false;
      state.approvedAdmissions = null;
      state.grantingAdmission = false;
      state.rejectingAdmission = false;
      state.gettingSinglePendingAdmission = false;
      state.singlePendingAdmission = null;
      state.gettingInitiatedAdmissions = false;
      state.initiatedAdmissions = null;

      // admission mode
      state.reason = "";
      state.personId = null;
      state.admissionMode = null;
    },
    getAdmissionFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const {
  setApprovedAdmissions,
  setGettingApprovedAdmissions,
  setGettingPendingAdmissions,
  setPendingAdmissions,
  setGrantingAdmission,
  setRejectingAdmission,
  getAdmissionFormDetails,
  setGettingSinglePendingAdmission,
  setSinglePendingAdmission,
  resetAdmissions,
  setGettingInitiatedAdmissions,
  setInitiatedAdmissions,
  setDownloadingPendingAdmissionsReport,
} = admissionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.admission.value)`
export const selectAdmission = (state: RootState) => state.admissions;

export default admissionSlice.reducer;
