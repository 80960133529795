import React, { useEffect } from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { useNavigate } from "react-router-dom";
import { filter } from "./utils";
import AddJambResult from "./AddJambResult";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { selectApplicationUtils } from "../../../Redux/features/Application/utilSlice";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";
import CustomRegularDropdown from "../../../Components/ApplicationComponents/Form/Selectors/RegularDropdown";

const JambResult = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { allExamYears, departments } = useAppSelector(selectApplicationUtils);
  const { department } = useAppSelector(selectapplicationForm);
  const {
    regNumber,
    score,
    year,
    firstSubjectScore,
    seconeSubjectScore,
    thirdSubjectScore,
    forthSubjectScore,
    firstSubjectId,
    secondSubjectId,
    thirdSubjectId,
    forthSubjectId,
  } = useAppSelector(selectapplicationForm);
  const examYearsFilter = filter(allExamYears, "text", "value");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const checkIfPassed = async () => {
    const res = await departments?.filter(
      (item: any) => item?.departmentId === department
    )[0];

    if (
      (res?.departmentId === 2035 && score < 200) ||
      (res?.departmentId === 1032 && score < 220) ||
      (res?.departmentId === 2036 && score < 180) ||
      (res?.departmentId === 1004 && score < 180)
    ) {
      toast.error(
        "Your score is lower that the minimum pass score for the selected department"
      );
    } else if (score < 160) {
      toast.error("Your Score did not meet up the minimum score");
    } else {
      return "passed";
    }
  };

  const handleSubmit = () => {
    checkIfPassed().then((res) => {
      if (res === "passed") {
        if (
          regNumber &&
          score &&
          year &&
          firstSubjectScore &&
          seconeSubjectScore &&
          thirdSubjectScore &&
          forthSubjectScore &&
          firstSubjectId &&
          secondSubjectId &&
          thirdSubjectId &&
          forthSubjectId
        ) {
          navigate("/edit/declaration");
        } else {
          toast.error("please fill all fields");
        }
      }
    });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  useEffect(() => {
    const sum =
      Number(firstSubjectScore) +
      Number(seconeSubjectScore) +
      Number(thirdSubjectScore) +
      Number(forthSubjectScore);
    dispatch(getApplicationDetails({ name: "score", value: sum }));
  }, [
    firstSubjectScore,
    seconeSubjectScore,
    thirdSubjectScore,
    forthSubjectScore,
    dispatch,
  ]);

  return (
    <AuthPageLayout authText="Jamb Result">
      <FormContainer>
        <ItemsCenterJustifyBtw>
          <CustomRegularDropdown
            title="Exam Year"
            name="year"
            handleDropdownSelect={handleSelect}
            dropdownItems={examYearsFilter}
            value={year}
          />
          <TextInputWithIcon
            placeholder="Reg Number"
            name="regNumber"
            value={regNumber}
            type="text"
            handleChange={handleOnChange}
          />
          <TextInputWithIcon
            placeholder="Jamb Score"
            name="score"
            value={score}
            type="number"
          />
        </ItemsCenterJustifyBtw>
        <AddJambResult />
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default JambResult;
