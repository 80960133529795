import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import {
  setActiveStudents,
  setActiveStudentsNumberBySession,
  setAdminStudents,
  setAdmittedStudents,
  setAdmittedStudentsDetails,
  setAdmittedStudentsNumberBySession,
  setAllStudents,
  setChangingStudentLevel,
  setClearanceList,
  setCompletingCourseReg,
  setDifferedStudent,
  setDifferingStudent,
  setEditingStudentInfo,
  setGettingActiveStudents,
  setGettingActiveStudentsDownloadable,
  setGettingActiveStudentsNumberBySession,
  setGettingAdminStudents,
  setGettingAdmittedStudent,
  setGettingAdmittedStudentDetails,
  setGettingAllStudents,
  setGettingClearance,
  setGettingStudentDetails,
  setGettingStudentLevel,
  setRegisteringStudent,
  setStudentDetails,
  setStudentLevel,
  setUpdatingClearance,
  setUpdatingSession,
} from "../../Redux/features/studentsSlice";
import { removeEmptyValues } from "../../helpers";

export const getAllStudents = async (
  SessionId: number | null,
  LevelId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  SearchPrams: string | null,
  StudentType: number | null,
  PageSize: number | null,
  PageNumber: number | null
) => {
  store.dispatch(setGettingAllStudents(true));
  const params: any = {
    DepartmentId,
    FacultyId,
    PageNumber,
    PageSize,
    SessionId,
    LevelId,
    SearchPrams,
    StudentType,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Reports/GetStudents`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllStudents(data));
      store.dispatch(setGettingAllStudents(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllStudents(false));
    });
};

export const getAdminStudents = async (
  SessionId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  PageSize: number,
  PageNumber: number,
  studentType: string
) => {
  store.dispatch(setGettingAdminStudents(true));
  store.dispatch(setAdminStudents(null));
  const params: any = {
    DepartmentId,
    FacultyId,
    PageNumber,
    PageSize,
    SessionId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`/Reports/${studentType}Students`, { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdminStudents(data));
      store.dispatch(setGettingAdminStudents(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdminStudents(false));
    });
};

export const getAdmmitedStudentDetails = async (PersonId: number) => {
  store.dispatch(setGettingAdmittedStudentDetails(true));

  await api
    .get(`/Reports/GetAdmitedStudentsDetails/${PersonId}`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmittedStudentsDetails(data));
      store.dispatch(setStudentDetails(data));
      store.dispatch(setGettingAdmittedStudentDetails(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdmittedStudentDetails(false));
    });
};

export const getConvertedStudentDetails = async (PersonId: number) => {
  store.dispatch(setGettingAdmittedStudentDetails(true));

  await api
    .get(`/Reports/StudentDetails/${PersonId}`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmittedStudentsDetails(data));
      store.dispatch(setStudentDetails(data));
      store.dispatch(setGettingAdmittedStudentDetails(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdmittedStudentDetails(false));
    });
};

export const getAdmmitedStudent = async (
  SessionId?: number | null,
  FacultyId?: number | null,
  DepartmentId?: number | null,
  PageSize?: number | null,
  PageNumber?: number | null
) => {
  store.dispatch(setGettingAdmittedStudent(true));
  const params: any = {
    DepartmentId,
    FacultyId,
    PageNumber,
    PageSize,
    SessionId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/GetAdmitedStudents", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmittedStudents(data));
      store.dispatch(setGettingAdmittedStudent(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdmittedStudent(false));
    });
};
export const getActiveStudents = async (
  SessionId?: number | null,
  FacultyId?: number | null,
  DepartmentId?: number | null,
  PageSize?: number | null,
  pageNumber?: number | null
) => {
  store.dispatch(setGettingActiveStudents(true));
  const params: any = {
    DepartmentId,
    FacultyId,
    PageSize,
    SessionId,
    pageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/ActiveStudents", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setActiveStudents(data));
      store.dispatch(setGettingActiveStudents(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveStudents(false));
    });
};

export const getActiveStudentDownload = async (
  ReportName: string,
  SessionId: number | null,
  LevelId: number | null,
  FacultyId: number | null,
  DepartmentId: number | null,
  PageSize: number | null,
  PageNumber: number | null,
  studentTypeApi?: string
) => {
  store.dispatch(setGettingActiveStudentsDownloadable(true));
  const params: any = {
    ReportName,
    DepartmentId,
    FacultyId,
    PageSize,
    SessionId,
    LevelId,
    PageNumber,
  };
  Object.keys(params)?.forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  await api
    .get(`/Reports/${studentTypeApi}/downloadable`, { params })
    .then((res) => {
      toast.success("Report generated");
      store.dispatch(setGettingActiveStudentsDownloadable(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveStudentsDownloadable(false));
    });
};

export const getStudentDetails = async (studentId: number) => {
  store.dispatch(setGettingStudentDetails(true));

  await api
    .get(`/Reports/StudentDetails/${studentId}`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentDetails(data));
      store.dispatch(setGettingStudentDetails(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentDetails(false));
    });
};

export const getAdmitedStudentsNumberBySession = async (session: number) => {
  store.dispatch(setGettingActiveStudentsNumberBySession(true));
  const params = {
    session,
  };
  await api
    .get("/Reports/GetAdmitedStudentsNumberBySession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmittedStudentsNumberBySession(data.response));
      store.dispatch(setGettingActiveStudentsNumberBySession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveStudentsNumberBySession(false));
    });
};

export const getActiveStudentsNumberBySession = async (session: number) => {
  store.dispatch(setActiveStudentsNumberBySession(true));
  const params = {
    session,
  };
  await api
    .get("/Reports/GetActiveStudentsNumberBySession", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setActiveStudentsNumberBySession(data?.response));
      store.dispatch(setActiveStudentsNumberBySession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setActiveStudentsNumberBySession(false));
    });
};

export const registerStudent = async (
  firstName: string,
  lastName: string,
  otherName: string,
  phoneNumber: string,
  email: string
) => {
  store.dispatch(setRegisteringStudent(true));
  const body: any = {
    firstName,
    lastName,
    otherName,
    phoneNumber,
    email,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Reports/StudentRegistration", body)
    .then((res) => {
      store.dispatch(setRegisteringStudent(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setRegisteringStudent(false));
    });
};

export const editStudentInfo = async (
  personId: number,
  departmentName: string | null,
  facultyName: string | null,
  stateName: string | null,
  religionName: string | null,
  localGovernmentName: string | null,
  admissionsId: number,
  prospectiveStudentId: number,
  programmeId: number,
  programmeName: string | null,
  sessionId: number,
  sessionName: string | null,
  admitted: boolean,
  refererName: string | null,
  refferalId: number,
  advertMeans: string | null,
  departmentId: number,
  facultyId: number,
  appicationNumber: string | null,
  firstName: string | null,
  lastName: string | null,
  mobilePhone: string | null,
  contactAddress: string | null,
  emailAddress: string | null,
  parentName: string | null,
  stateId: string | null,
  religionId: number,
  localGovernmentId: string | null,
  genderId: number,
  disabilityId: number,
  parentNumber: string | null,
  dateOfBirth: string | null,
  nextOfKinAddress: string | null,
  nextOfKinPhoneNo: string | null,
  nextOfKinFullname: string | null,
  tribeId: number | null,
  tribeName: string | null,
  matricNumber: string | null
) => {
  store.dispatch(setEditingStudentInfo(true));
  const body: any = {
    personId,
    departmentName,
    facultyName,
    stateName,
    religionName,
    localGovernmentName,
    admissionsId,
    prospectiveStudentId,
    programmeId,
    programmeName,
    sessionId,
    sessionName,
    admitted,
    refererName,
    refferalId,
    advertMeans,
    departmentId,
    facultyId,
    appicationNumber,
    firstName,
    lastName,
    mobilePhone,
    contactAddress,
    emailAddress,
    parentName,
    stateId,
    religionId,
    localGovernmentId,
    genderId,
    disabilityId,
    parentNumber,
    dateOfBirth,
    nextOfKinAddress,
    nextOfKinPhoneNo,
    nextOfKinFullname,
    tribeId,
    tribeName,
    matricNumber,
  };
  const filtered = removeEmptyValues(body);

  await api
    .post("/Reports/EditStudentInfo/Admitted", filtered)
    .then((res) => {
      store.dispatch(setStudentDetails(res?.data));
      store.dispatch(setEditingStudentInfo(false));
      toast.success("Student Details Updated successfully");
      // window.location.href = "/Students";
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingStudentInfo(false));
    });
};

export const editConvertedStudentInfo = async (
  personId: number,
  departmentName: string | null,
  facultyName: string | null,
  stateName: string | null,
  religionName: string | null,
  localGovernmentName: string | null,
  admissionsId: number,
  prospectiveStudentId: number,
  programmeId: number,
  programmeName: string | null,
  sessionId: number,
  sessionName: string | null,
  admitted: boolean,
  refererName: string | null,
  refferalId: number,
  advertMeans: string | null,
  departmentId: number,
  facultyId: number,
  appicationNumber: string | null,
  firstName: string | null,
  lastName: string | null,
  mobilePhone: string | null,
  contactAddress: string | null,
  emailAddress: string | null,
  parentName: string | null,
  stateId: string | null,
  religionId: number,
  localGovernmentId: string | null,
  genderId: number,
  disabilityId: number,
  parentNumber: string | null,
  dateOfBirth: string | null,
  nextOfKinAddress: string | null,
  nextOfKinPhoneNo: string | null,
  nextOfKinFullname: string | null,
  tribeId: number | null,
  tribeName: string | null,
  matricNumber: string | null
) => {
  store.dispatch(setEditingStudentInfo(true));
  const body: any = {
    personId,
    departmentName,
    facultyName,
    stateName,
    religionName,
    localGovernmentName,
    admissionsId,
    prospectiveStudentId,
    programmeId,
    programmeName,
    sessionId,
    sessionName,
    admitted,
    refererName,
    refferalId,
    advertMeans,
    departmentId,
    facultyId,
    appicationNumber,
    firstName,
    lastName,
    mobilePhone,
    contactAddress,
    emailAddress,
    parentName,
    stateId,
    religionId,
    localGovernmentId,
    genderId,
    disabilityId,
    parentNumber,
    dateOfBirth,
    nextOfKinAddress,
    nextOfKinPhoneNo,
    nextOfKinFullname,
    tribeId,
    tribeName,
    matricNumber,
  };
  const filtered = removeEmptyValues(body);
  await api
    .post("/Reports/EditStudentInfo", filtered)
    .then((res) => {
      store.dispatch(setStudentDetails(res?.data));
      store.dispatch(setEditingStudentInfo(false));
      toast.success("Student Details Updated successfully");
      // window.location.href = "/Students";
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingStudentInfo(false));
    });
};

export const defferStudent = async (matric: string | null) => {
  store.dispatch(setDifferingStudent(true));
  const params = {
    matric,
  };
  await api
    .get("/Reports/DeferStudent", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setDifferedStudent(data));
      store.dispatch(setDifferingStudent(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDifferingStudent(false));
    });
};

export const getStudentsLevel = async (matric: string | null) => {
  store.dispatch(setGettingStudentLevel(true));
  const params = {
    matric,
  };
  await api
    .get("/Reports/GetStudentLevel", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentLevel(data));
      store.dispatch(setGettingStudentLevel(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingStudentLevel(false));
    });
};

export const changeStudentLevel = async (
  studentId: any,
  sessionId: number | null,
  levelId: number | null
) => {
  store.dispatch(setChangingStudentLevel(true));
  const params: any = {
    studentId,
    sessionId,
    levelId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/ChangeStudentLevel", { params })
    .then((res) => {
      store.dispatch(setChangingStudentLevel(false));
      toast.success("Update Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setChangingStudentLevel(false));
    });
};

export const getClearance = async (sessionId: number | null) => {
  store.dispatch(setGettingClearance(true));
  const params: any = {
    sessionId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Reports/ExamClearance/get", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setClearanceList(data));
      store.dispatch(setGettingClearance(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingClearance(false));
    });
};

export const editClearance = async (
  examCleaanceSettingsId: number | null,
  sessionId: number | null,
  semesterId: number | null,
  startDate: string | null,
  endDate: string | null
) => {
  store.dispatch(setUpdatingClearance(true));
  const body: any = {
    sessionId,
    examCleaanceSettingsId,
    semesterId,
    startDate,
    endDate,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Reports/ExamClearance/edit", body)
    .then((res) => {
      store.dispatch(setUpdatingClearance(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingClearance(false));
    });
};

export const createExamClearance = async (
  sessionId: number | null,
  semester: number | null,
  startDate: string | null,
  endDate: string | null
) => {
  store.dispatch(setUpdatingClearance(true));
  const body: any = {
    sessionId,
    semester,
    startDate,
    endDate,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Reports/ExamClearance/set", body)
    .then((res) => {
      store.dispatch(setUpdatingClearance(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingClearance(false));
    });
};

export const addSession = async (sessionName: string | null) => {
  store.dispatch(setUpdatingSession(true));
  const body = {
    sessionName,
  };
  await api
    .post("/Payments/Session/Add", body)
    .then((res) => {
      store.dispatch(setUpdatingSession(false));
      toast.success("Session added successfully");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingSession(false));
    });
};

export const editSession = async (
  id: number | null,
  activated: boolean,
  adminting: boolean
) => {
  store.dispatch(setUpdatingSession(true));
  const body = {
    id,
    activated,
    adminting,
  };
  await api
    .patch("/Payments/Session/Edit", body)
    .then(() => {
      store.dispatch(setUpdatingSession(false));
      toast.success("Session edited successfully");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingSession(false));
    });
};

export const completeCourseReg = async (
  semesterId: number | null,
  studentId: number | null,
  sessionId: any
) => {
  store.dispatch(setCompletingCourseReg(true));
  const params = {
    semesterId,
    studentId,
    sessionId,
  };
  await api
    .get("/Student/UnderGraduateCourseRegistration/Admin", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setCompletingCourseReg(false));
      window.open(`${data}`, "_blank");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCompletingCourseReg(false));
    });
};
