import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  getAdmissionType,
  getExamType,
  getExamYear,
  getLGA,
  getState,
  getSubject,
  getSubjectGrade,
  getTribes,
  getReligion,
  getCountries,
  getDepartments,
  getFaculties,
} from "../Apis/Application/util";
// import Header from "../Components/ApplicationComponents/Header";
import Home from "../Pages/AuthPages/Applications/Home";
import ProgramDetails from "../Pages/AuthPages/Applications/ProgramDetails";
import NextOfKinDetails from "../Pages/AuthPages/Applications/NextOfKinDetails";
import OLevelResultPage from "../Pages/AuthPages/Applications/OLevelResult";
import JambResult from "../Pages/AuthPages/Applications/JambResult";
import AddressInfo from "../Pages/AuthPages/Applications/AddressInfo";
import OtherInformation from "../Pages/AuthPages/Applications/OtherInformation";
import Declaration from "../Pages/AuthPages/Applications/Declaration";
import PersonalDetails from "../Pages/AuthPages/Applications/PersonalDetails";

import EditProgramDetails from "../Pages/AuthPages/EditApplication/ProgramDetails";
import EditNextOfKinDetails from "../Pages/AuthPages/EditApplication/NextOfKinDetails";
import EditOLevelResultPage from "../Pages/AuthPages/EditApplication/OLevelResult";
import EditJambResult from "../Pages/AuthPages/EditApplication/JambResult";
import EditAddressInfo from "../Pages/AuthPages/EditApplication/AddressInfo";
import EditOtherInformation from "../Pages/AuthPages/EditApplication/OtherInformation";
import EditDeclaration from "../Pages/AuthPages/EditApplication/Declaration";
import EditPersonalDetails from "../Pages/AuthPages/EditApplication/PersonalDetails";
import styled from "styled-components";
import { useAppSelector } from "../Redux/app/hooks";
import { selectapplicationForm } from "../Redux/features/Application/ApplicationFormSlice";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ApplicationRouter = () => {
  const { faculty, state } = useAppSelector(selectapplicationForm);

  useEffect(() => {
    getSubject();
    getAdmissionType();
    getSubjectGrade();
    getReligion();
    getExamType();
    getExamYear();
    getTribes();
    getFaculties();
    getCountries();
  }, []);

  useEffect(() => {
    getState(1);
  }, []);

  useEffect(() => {
    getLGA(state);
  }, [state]);

  useEffect(() => {
    getDepartments(faculty);
  }, [faculty]);

  return (
    <Wrapper>
      {/* <Header /> */}
      <Routes>
        <Route path="/application" element={<Home />} />
        <Route path="/program-details" element={<ProgramDetails />} />
        <Route path="/next-of-kin-details" element={<NextOfKinDetails />} />
        <Route path="/O-level-result-details" element={<OLevelResultPage />} />
        <Route path="/Jamb-result-details" element={<JambResult />} />
        <Route path="/address-info" element={<AddressInfo />} />
        <Route path="/other-information" element={<OtherInformation />} />
        <Route path="/declaration" element={<Declaration />} />
        <Route path="/continue-application/:id" element={<PersonalDetails />} />

        {/* editing */}
        <Route path="/edit/program-details" element={<EditProgramDetails />} />
        <Route path="/edit/next-of-kin-details" element={<EditNextOfKinDetails />} />
        <Route path="/edit/O-level-result-details" element={<EditOLevelResultPage />} />
        <Route path="/edit/Jamb-result-details" element={<EditJambResult />} />
        <Route path="/edit/address-info" element={<EditAddressInfo />} />
        <Route path="/edit/other-information" element={<EditOtherInformation />} />
        <Route path="/edit/declaration" element={<EditDeclaration />} />
        <Route path="/edit/continue-application/:id" element={<EditPersonalDetails />} />
      </Routes>
    </Wrapper>
  );
};

export default ApplicationRouter;
