import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { addSession, editSession } from "../../../Apis/Private/students";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { toast } from "react-toastify";
import { sessionStatusOptions } from "../Hostels/util";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
const SetSession = () => {
  const {
    updatingSession,
    sessionActivated,
    sessionAdmitting,
    sessionId,
    sessionName,
  } = useAppSelector(selectStudents);
  const { sessions } = useAppSelector(selectUtil);
  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleAdd = () => {
    if (sessionName) {
      addSession(sessionName);
    } else {
      toast.error("Please enter student session number");
    }
  };
  const handleEdit = () => {
    editSession(sessionId, sessionActivated, sessionAdmitting);
  };

  return (
    <>
      <PageTitle>Add Session</PageTitle>
      <ItemsCenterJustifyBtw>
        <TextCenteredInput
          alignText="left"
          placeholder="Session Name"
          name="sessionName"
          value={sessionName}
          onChangeHandler={handleOnChange}
        />
      </ItemsCenterJustifyBtw>
      <BigButton
        buttonText="Add"
        loading={updatingSession}
        handleClick={handleAdd}
      />
      <PageTitle>Edit Session</PageTitle>
      <ItemsCenterJustifyBtw>
        <UnderlinedSelector
          title="Sessions"
          name="sessionId"
          value={sessionId}
          handleDropdownSelect={handleSelect}
          dropdownItems={sessionFilter}
        />
        <UnderlinedSelector
          title="Activated"
          name="sessionActivated"
          value={sessionActivated}
          handleDropdownSelect={handleSelect}
          dropdownItems={sessionStatusOptions}
        />
        <UnderlinedSelector
          title="Admintting"
          name="sessionAdmitting"
          value={sessionAdmitting}
          handleDropdownSelect={handleSelect}
          dropdownItems={sessionStatusOptions}
        />
      </ItemsCenterJustifyBtw>
      <BigButton
        buttonText="Edit"
        loading={updatingSession}
        handleClick={handleEdit}
      />
    </>
  );
};

export default SetSession;
