import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { defferStudent } from "../../../Apis/Private/students";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import { toast } from "react-toastify";
const DefferedStudents = () => {
  const { matricNumber, deferringStudent } = useAppSelector(selectStudents);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleDefer = () => {
    if (matricNumber) {
      defferStudent(matricNumber);
    } else {
        toast.error("Please enter student matric number")
    }
};

  return (
    <>
      <PageTitle>Defer Students</PageTitle>
      <ItemsCenterJustifyBtw>
        <TextCenteredInput
          alignText="left"
          placeholder="Enter Student Reg. No./Matric. No."
          name="matricNumber"
          value={matricNumber}
          onChangeHandler={handleOnChange}
        />
      </ItemsCenterJustifyBtw>
      <BigButton
        buttonText="Defer"
        loading={deferringStudent}
        handleClick={handleDefer}
      />
    </>
  );
};

export default DefferedStudents;
