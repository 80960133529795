import React from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import ReactSuiteDropdown from "../../../Components/Form/Dropdowns/NewDropdown";

const ExamClearanceFilter = () => {
  const { sessions } = useAppSelector(selectUtil);
  const { SessionId } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };
  return (
    <div className="flex-centered-items flex-wrap">
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
    </div>
  );
};

export default ExamClearanceFilter;
