export const transactionOptions = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    stackType: "100%",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "top",
          horizontalAlign: "center",
          fontSize: "10px",
        },
      },
    },
  ],
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "center",
    fontSize: "10px",
  },
  colors: [`#4361ee`],
  dataLabels: {
    enabled: false,
  },
};
