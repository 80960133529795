import { ImageCell } from "./UsersTableCells";
import StudentsMoreDropdown from "../../Components/Form/Dropdowns/StudentsMoreDropdown";
import moment from "moment";
import { Link } from "react-router-dom";
import { store } from "../../Redux/app/store";
import {
  getStudentsFormDetails,
  setClickedButtonIndex,
} from "../../Redux/features/studentsSlice";
import SmallButton from "../../Components/Form/Buttons/SmallButton";
import { completeCourseReg, editClearance } from "../../Apis/Private/students";
import { AiFillEdit } from "react-icons/ai";
import TextCenteredInput from "../../Components/Form/Inputs/TextCenteredInput";
import { FaCheck } from "react-icons/fa";
import RegularSelector from "../../Components/Form/Dropdowns/RegularSelector";
import PendingStudentsDropdown from "../../Components/Form/Dropdowns/PendingStudentsMoreDropdown";
import AdmittedStudentsMoreDropdown from "../../Components/Form/Dropdowns/AdmittedMoreDropdown";
import { levelOptions } from "../../Pages/PrivatePages/Hostels/util";

const semesterOptions = [
  { label: "First Semester", value: 1 },
  { label: "Second Semester", value: 2 },
];

const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  const { name, value } = e.target;
  store?.dispatch(getStudentsFormDetails({ name, value }));
};

// Users table data
export const courseRegCols = [
  { label: "FullName", field: "fullname" },
  { label: "Reg. No.", field: "matricNo" },
  { label: "Faculty", field: "faculty" },
  { label: "Department", field: "department" },
  // { label: "Current Level", field: "level" },
  { label: "Semester", field: "semester" },
  { label: "Session", field: "courseRegSession" },
  { label: "", field: "action2" },
];

// Users table data
export const studentsCols = [
  { label: "Name", field: "name" },
  { label: "Reg/Application No.", field: "matricNumber" },
  { label: "Email Address", field: "email" },
  { label: "Phone Number", field: "phone" },
  { label: "Faculty", field: "faculty" },
  { label: "Department", field: "department" },
  { label: "Current Level", field: "level" },
  { label: "Session", field: "studentSession" },
  { label: "Action", field: "action" },
];

export const pendingStudentsCols = [
  { label: "Name", field: "name" },
  { label: "Reg/Application No.", field: "matricNumber" },
  // { label: "Email Address", field: "email" },
  { label: "Phone Number", field: "phone" },
  { label: "Faculty", field: "faculty" },
  { label: "Department", field: "pendingDepartment" },
  { label: "Session", field: "studentSession" },
  { label: "", field: "pendingAction" },
];

export const admittedStudentsCols = [
  { label: "Name", field: "name" },
  { label: "Reg/Application No.", field: "matricNumber" },
  // { label: "Email Address", field: "email" },
  { label: "Phone Number", field: "phone" },
  { label: "Faculty", field: "faculty" },
  { label: "Department", field: "department" },
  { label: "Session", field: "studentSession" },
  { label: "", field: "admittedAction" },
];

export const initiatedAdmissionCols = [
  { label: "Name", field: "name" },
  { label: "Reg/Application No.", field: "matricNumber" },
  { label: "Phone Number", field: "phone" },
  { label: "Faculty", field: "faculty" },
  { label: "Session", field: "studentSession" },
];

export const StudentRows = (
  data: any,
  sessions?: any,
  departments?: any,
  studentType?: any
) => {
  const res = data?.records?.map((student: any, index: number) => {
    let dataId;
    let sessionIdValue = student?.sessionId;
    let semesterIdValue = student?.semesterId;
    return {
      name: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={student?.firstName}
          lastName={student?.lastName}
          otherName={student?.otherName}
          userName={student?.admissionsId}
        />
      ),
      fullname: <span>{student?.fullname}</span>,
      matricNumber: (
        <span>{student?.matricNumber || student?.appicationNumber}</span>
      ),
      email: <span>{student?.emailAddress}</span>,
      phone: <span>{student?.mobilePhone},</span>,
      faculty: <span>{student?.facultyName}</span>,
      department: <span>{student?.departmentName}</span>,
      pendingDepartment: (
        <span>
          {
            departments?.filter(
              (x: any) => x?.departmentId === student?.departmentId
            )[0]?.departmentName
          }
        </span>
      ),
      level: (
        <span>
          {levelOptions?.filter(
            (x: any) => x?.value === student?.levelId
          )[0]?.label || "N/A"}
        </span>
      ),
      studentSession: <span>{student?.sessionName}</span>,
      matricNo: <span>{student?.matricNumber}</span>,
      semester: (
        <RegularSelector
          title={"Select Semester"}
          dropdownItems={semesterOptions}
          handleDropdownSelect={(e: any) => {
            semesterIdValue = e.target.value;
          }}
          value={semesterIdValue}
        />
      ),
      courseRegSession: (
        <RegularSelector
          title={"Select Session"}
          dropdownItems={sessions}
          handleDropdownSelect={(e: any) => {
            sessionIdValue = e.target.value;
          }}
          // value={sessionIdValue}
        />
      ),
      action: (
        <StudentsMoreDropdown
          studentId={student?.personId}
          studentType={studentType}
        />
      ),
      admittedAction: (
        <AdmittedStudentsMoreDropdown studentId={student?.personId} />
      ),
      action2: (
        <SmallButton
          id={index}
          buttonText="complete"
          leftIcon={<FaCheck />}
          handleClick={(e: any) => {
            dataId = Number(e.target.id);
            if (dataId === index) {
              completeCourseReg(
                semesterIdValue || 1,
                student?.personId,
                sessionIdValue
              );
              store?.dispatch(setClickedButtonIndex(index));
            }
          }}
          loading={
            store.getState().students.clickedButtonIndex === index &&
            store.getState().students.completingCourseReg
          }
        />
      ),
      pendingAction: <PendingStudentsDropdown studentId={student?.personId} />,
    };
  });
  return res;
};

// Users report table data
export const studentsExportCols = [
  { label: "Report Name", field: "name" },
  { label: "Report Type", field: "type" },
  { label: "Date Created", field: "date" },
  { label: "", field: "action" },
];
export const studentsExportRows = (data: any) => {
  const res = data?.records?.map((report: any) => {
    return {
      name: <span>{report?.reportName}</span>,
      type: (
        <span>
          {report?.reportType === 1 && "Admitted Students Report"}
          {report?.reportType === 2 && "Converted Students Report"}
          {report?.reportType === 3 && "Payment Report"}
          {report?.reportType === 4 && "Hostel Booking Report"}
        </span>
      ),
      date: <span>{moment(report?.createdAt).format("DD/MM/YYYY")},</span>,
      action: <Link to={report?.reportUrl}>Download</Link>,
    };
  });

  return res;
};

export const examClearanceCols = [
  { label: "Session", field: "session" },
  { label: "Semester", field: "semester" },
  { label: "Start Date", field: "startDate" },
  { label: "End Date", field: "endDate" },
  { label: "Activation", field: "activated" },
  // { label: "Admitting", field: "admitting" },
  { label: "Action", field: "action" },
];
export const examClearanceRows = (
  data: any,
  clearanceEndDate: any,
  clearanceStartDate: any,
  updatingClearance: boolean
) => {
  const res = data?.map((clearance: any, index: number) => {
    return {
      session: <span>{clearance?.session?.sessionName}</span>,
      semester: <span>{clearance?.semester?.semesterName},</span>,
      startDate: (
        <TextCenteredInput
          placeholder={"Start date"}
          name="clearanceStartDate"
          defaultValue={moment(clearance?.startDate).format("YYYY-MM-DD")}
          type="date"
          onChangeHandler={handleOnChange}
          alignText="left"
        />
      ),
      endDate: (
        <TextCenteredInput
          placeholder={"Start date"}
          name="clearanceEndDate"
          defaultValue={moment(clearance?.endDate).format("YYYY-MM-DD")}
          type="date"
          onChangeHandler={handleOnChange}
          alignText="left"
        />
      ),
      activated: <span>{clearance?.session?.activated}</span>,
      admitting: <span>{clearance?.session?.activated}</span>,
      action: (
        <SmallButton
          buttonText="Edit"
          leftIcon={<AiFillEdit />}
          handleClick={() => {
            editClearance(
              clearance?.examCleaanceSettingsId,
              clearance?.session?.sessionId,
              clearance?.semester?.semesterId,
              clearanceStartDate || clearance?.startDate,
              clearanceEndDate || clearance?.endDate
            );
            store.dispatch(setClickedButtonIndex(index));
          }}
          loading={
            store.getState().students.clickedButtonIndex === index &&
            updatingClearance
          }
        />
      ),
    };
  });

  return res;
};
