import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectAdmission } from "../../Redux/features/admissionSlice";
import {
  getStudentsFormDetails,
} from "../../Redux/features/studentsSlice";
import CustomTable2 from "../CustomTable2";
import { StudentRows, initiatedAdmissionCols } from "./utils";

const InitiatedAdmissions = () => {
 const { gettingInitiatedAdmissions, initiatedAdmissions } =
   useAppSelector(selectAdmission);
  const dispatch = useAppDispatch();
  const rows = StudentRows(initiatedAdmissions);

  const handlePagination = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getStudentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: initiatedAdmissionCols }}
      showPagination={true}
      showHeader={true}
      loading={gettingInitiatedAdmissions}
      noRecord={
        initiatedAdmissions?.records?.length === 0 || !initiatedAdmissions
      }
      page={initiatedAdmissions?.currentPage}
      pageCount={initiatedAdmissions?.totalRecords}
      pageSize={initiatedAdmissions?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
    />
  );
};

export default InitiatedAdmissions;
