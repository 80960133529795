import { styled } from "styled-components";
import { media } from "../../Screens";
import { Link } from "react-router-dom";

export const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: none;
  height: 100vh;
  /* overflow: hidden; */
  position: relative;

  &::before {
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    content: "";
    border-radius: 816px;
    opacity: 1;
    background: radial-gradient(
      67.22% 50% at 50% 50%,
      rgba(100, 101, 210, 0.1) 0%,
      rgba(245, 228, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    ${media.tablet} {
      width: 516px;
      height: 516px;
    }
  }
  &::after {
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    content: "";
    border-radius: 816px;
    opacity: 0.9;
    background: radial-gradient(
      67.22% 50% at 50% 50%,
      rgba(200, 200, 51, 0.15) 0%,
      rgba(245, 228, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;

    ${media.tablet} {
      width: 516px;
      height: 516px;
    }
  }
`;

export const MainBodyContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;
  position: relative;

  ${media.desktop} {
    height: calc(100vh - 65px);
  }
`;

export const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background: none;
  padding: 30px 10px;
  overflow-y: auto;
  position: relative;

  ${media.tablet} {
    width: 85%;
    padding: 30px;
  }
  ${media.desktop} {
    width: 85%;
    padding: 60px;
  }
`;


export const PageTitle = styled.p`
  display: flex;
  color: var(--clr-black);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;

  ${media.phone} {
    font-size: var(--fs-500);
  }

  ${media.tablet} {
    font-size: var(--fs-700);
  }
`;

export const NavigationTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-black);
  font-size: 10px;
  font-weight: var(--fw-regular);
  line-height: normal;

  svg {
    color: var(--clr-grey-200);
    font-size: 1rem;
  }

  a:last-child,
  span:last-child {
    color: var(--clr-black);
    font-weight: var(--fw-very-bold);
  }
`;

export const PageContainer = styled.div`
  width: 100%;
`;

export const ItemsCenterJustifyBtw = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  flex-direction: column;

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .remain-row {
    display: flex;
    flex-direction: row;
  }

  ${media.phone} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const GridDisplay = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;

  ${media.phone} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 23px;
  }
  ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const FormCardContainer = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
  border-radius: 10px;
  border: 1px solid var(--MWB-Sep-Stroke, #f2edff);
  background: rgba(255, 255, 255, 0.45);

  ${media.tablet} {
    padding: 30px;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid var(--MWB-Sep-Stroke, #f2edff);
  background: var(--MWB-White, #fff);
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--clr-black);
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;

  ${media.tablet} {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;
