import { FC } from "react";
import styled from "styled-components";

interface InputProps {
  alignText?: string;
}
const TextAreaInput = styled.textarea<InputProps>`
  align-self: stretch;
  background: var(--clr-white-100);
  outline: none;
  border: none;
  border-bottom: 1px solid var(--clr-grey-100);
  padding: 10px 0;
  text-align: ${(props) => props.alignText || `center`};
  transition: all 0.5s ease;
  max-width: 100%;

  &:focus {
    border-color: var(--clr-black);
    color: var(--clr-black);
  }
`;

interface TextInputProps {
  placeholder: string;
  alignText?: string;
  defaultValue?: string;
  name?: string;
  value?: any;
  onChange?: any;
}
const TextArea: FC<TextInputProps> = ({
  placeholder,
  alignText,
  defaultValue,
  value,
  onChange,
  name,
}) => {
  return (
    <TextAreaInput
      placeholder={placeholder}
      defaultValue={defaultValue}
      alignText={alignText}
      value={value}
      onChange={onChange}
      name={name}
    />
  );
};

export default TextArea;
