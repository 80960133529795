import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectUtil } from "../../Redux/features/utilsSlice";
import {
  getHostelsFormDetails,
  resetHostelParams,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import ReactSuiteDropdown from "../../Components/Form/Dropdowns/NewDropdown";
import { filter } from "../../Pages/PrivatePages/util";
import FilterButtons from "./FilterButtons";

interface FilterSectionProps {
  filterFunction?: any;
}

const BlockFilter: FC<FilterSectionProps> = ({ filterFunction }) => {
  const { hostels } = useAppSelector(selectUtil);

  const { HostelId } = useAppSelector(selectHostel);

  const dispatch = useAppDispatch();
  const hostelsFilter = filter(hostels, "hostelName", "hostelId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };
  return (
    <div className="flex-centered-items flex-wrap">
      <ReactSuiteDropdown
        dropdownItems={hostelsFilter}
        title={`Hostel`}
        handleDropdownSelect={handleSelect}
        name="HostelId"
        value={HostelId}
      />
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => dispatch(resetHostelParams())}
      />
    </div>
  );
};

export default BlockFilter;
