import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllStudentsPayment } from "../../../Apis/Private/payment";
import SingleStudentsPayments from "../../../Tables/AllPayments/SingleStudentPayment";
import { ItemsCenterJustifyBtw } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";

const StudentsPayment = () => {
  const { Id } = useParams();

  useEffect(() => {
    getAllStudentsPayment(Number(Id));
  }, [Id]);

  return (
    <>
      <NavigationLink to={`/Students`} className="flex-centered-items">
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <ItemsCenterJustifyBtw>
        <OutlinedSearchInput placeholder="Search Reference" />
      </ItemsCenterJustifyBtw>
      <SingleStudentsPayments />
    </>
  );
};

export default StudentsPayment;
