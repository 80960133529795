import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface paymentState {
  gettingStudentsPaymentInASession: boolean;
  confirmingPayment: boolean;
  uploadingOfflinePayment: boolean;
  studentsPaymentInASession: object | null;
  gettingStudentsPaymentInASemester: boolean;
  studentsPaymentInASemester: object | null;
  gettingAllPayments: boolean;
  allPayments: any;
  gettingDownloadablePayment: boolean;
  downloadablePayment: object | null;
  gettingFeePaymentSummary: boolean;
  feePaymentSummary: object | null;
  gettingPaymentSummary: boolean;
  paymentSummary: any;
  gettingPaymentSummaryChart: boolean;
  paymentSummaryChart: object | null;
  gettingPaymentById: boolean;
  paymentById: any;
  gettingAllStudentsPayment: boolean;
  allStudentsPayment: any;
  gettingStudentsInfo: boolean;
  studentsInfo: any;
  gettingPaymentFees: boolean;
  paymentFees: any;
  gettingFeeMappings: boolean;
  feeMappings: any;
  creatingFeePayment: boolean;
  updatingFeePayment: boolean;
  creatingFeeMapping: boolean;

  // payment Info
  SessionId: number | null;
  SemesterId: number | null;
  FeeScheduledId: number | null;
  PaymentMeans: number | null;
  FacultyId: number | null;
  DepartmentId: number | null;
  LevelId: number | null;
  ReportName: string | null;
  StartDate: string | null;
  EndDate: string | null;
  GenderId: number | null;
  AsPdf: boolean;
  PageSize: number | null;
  PageNumber: number | null;
  SearchPrams: string | null;

  // offline upload states
  feeDetailsId: number | null;
  personId: number | null;
  bankId: number | null;
  tellerNumber: string | null;
  accountNo: string | null;
  description: string | null;

  // get students Info states
  regNumber: string | null;

  // payment mapping states
  feeScheduleId: number | null;
  paymentInstallmentId: number | null;
  programmeId: number | null;
  departmentId: number | null;
  departmentIds: any[];
  levelId: number | null;
  sessionId: number | null;
  genderId: number | null;
  semesterId: number | null;
  admisionType: number | null;
  amount: number | null;

  // verify payment state
  reference: string | null;
  gateWay: number | null;

  // fee states
  feeScheduleName: string;
  canPayInstallment: boolean;
}

const initialState: paymentState = {
  gettingStudentsPaymentInASession: false,
  confirmingPayment: false,
  uploadingOfflinePayment: false,
  studentsPaymentInASession: null,
  gettingStudentsPaymentInASemester: false,
  studentsPaymentInASemester: null,
  gettingAllPayments: false,
  allPayments: null,
  gettingDownloadablePayment: false,
  downloadablePayment: null,
  gettingFeePaymentSummary: false,
  feePaymentSummary: null,
  gettingPaymentSummary: false,
  paymentSummary: null,
  gettingPaymentSummaryChart: false,
  paymentSummaryChart: null,
  gettingPaymentById: false,
  paymentById: null,
  gettingAllStudentsPayment: false,
  allStudentsPayment: null,
  gettingStudentsInfo: false,
  studentsInfo: null,
  gettingPaymentFees: false,
  paymentFees: null,
  updatingFeePayment: false,
  creatingFeePayment: false,
  gettingFeeMappings: false,
  feeMappings: null,
  creatingFeeMapping: false,

  // payment Info
  SessionId: null,
  SemesterId: null,
  FeeScheduledId: null,
  PaymentMeans: null,
  FacultyId: null,
  DepartmentId: null,
  LevelId: null,
  ReportName: null,
  StartDate: null,
  EndDate: null,
  GenderId: null,
  AsPdf: false,
  PageSize: 30,
  PageNumber: 1,
  SearchPrams: "",

  // offline upload states
  feeDetailsId: null,
  personId: null,
  bankId: null,
  tellerNumber: null,
  accountNo: null,
  description: null,

  // get students Info states
  regNumber: null,

  // payments mapping states
  feeScheduleId: null,
  paymentInstallmentId: null,
  programmeId: null,
  departmentId: null,
  departmentIds: [],
  levelId: null,
  sessionId: null,
  genderId: null,
  semesterId: null,
  admisionType: null,
  amount: null,

  // verify payment state
  reference: null,
  gateWay: null,

  // fee states
  feeScheduleName: "",
  canPayInstallment: true,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setGettingStudentsPaymentInASession: (state, action) => {
      state.gettingStudentsPaymentInASession = action.payload;
    },
    setStudentsPaymentInASession: (state, action) => {
      state.studentsPaymentInASession = action.payload;
    },
    setUploadingOfflinePayment: (state, action) => {
      state.uploadingOfflinePayment = action.payload;
    },
    setConfirmingPayment: (state, action) => {
      state.confirmingPayment = action.payload;
    },
    setGettingStudentsPaymentInASemester: (state, action) => {
      state.gettingStudentsPaymentInASemester = action.payload;
    },
    setStudentsPaymentInASemester: (state, action) => {
      state.studentsPaymentInASemester = action.payload;
    },
    setGettingDownloadablePayment: (state, action) => {
      state.gettingDownloadablePayment = action.payload;
    },
    setDownloadablePayment: (state, action) => {
      state.gettingDownloadablePayment = action.payload;
    },
    setGettingAllPayment: (state, action) => {
      state.gettingAllPayments = action.payload;
    },
    setAllPayment: (state, action) => {
      state.allPayments = action.payload;
    },
    setGettingFeePaymentSummary: (state, action) => {
      state.gettingFeePaymentSummary = action.payload;
    },
    setFeePaymentSummary: (state, action) => {
      state.feePaymentSummary = action.payload;
    },
    setGettingPaymentSummary: (state, action) => {
      state.gettingPaymentSummary = action.payload;
    },
    setPaymentSummary: (state, action) => {
      state.paymentSummary = action.payload;
    },
    setGettingPaymentSummaryChart: (state, action) => {
      state.gettingPaymentSummaryChart = action.payload;
    },
    setPaymentSummaryChart: (state, action) => {
      state.paymentSummaryChart = action.payload;
    },
    setGettingPaymentById: (state, action) => {
      state.gettingPaymentById = action.payload;
    },
    setPaymentById: (state, action) => {
      state.paymentById = action.payload;
    },
    setGettingAllStudentsPayment: (state, action) => {
      state.gettingAllStudentsPayment = action.payload;
    },
    setAllStudentsPayment: (state, action) => {
      state.allStudentsPayment = action.payload;
    },
    setGettingStudentInfo: (state, action) => {
      state.gettingStudentsInfo = action.payload;
    },
    setStudentInfo: (state, action) => {
      state.studentsInfo = action.payload;
    },
    setGettingPaymentFees: (state, action) => {
      state.gettingPaymentFees = action.payload;
    },
    setPaymentFees: (state, action) => {
      state.paymentFees = action.payload;
    },
    setGettingFeeMappings: (state, action) => {
      state.gettingFeeMappings = action.payload;
    },
    setFeeMappings: (state, action) => {
      state.feeMappings = action.payload;
    },
    setUpdatingFeePayment: (state, action) => {
      state.updatingFeePayment = action.payload;
    },
    setCreatingFeePayment: (state, action) => {
      state.creatingFeePayment = action.payload;
    },
    setCreatingFeeMapping: (state, action) => {
      state.creatingFeeMapping = action.payload;
    },
    resetPaymentParams: (state) => {
      state.gettingStudentsPaymentInASession = false;
      state.confirmingPayment = false;
      state.uploadingOfflinePayment = false;
      state.studentsPaymentInASession = null;
      state.gettingStudentsPaymentInASemester = false;
      state.studentsPaymentInASemester = null;
      state.gettingAllPayments = false;
      state.allPayments = null;
      state.gettingDownloadablePayment = false;
      state.downloadablePayment = null;
      state.gettingFeePaymentSummary = false;
      state.feePaymentSummary = null;
      state.gettingPaymentSummary = false;
      state.paymentSummary = null;
      state.gettingPaymentSummaryChart = false;
      state.paymentSummaryChart = null;
      state.gettingPaymentById = false;
      state.paymentById = null;
      state.gettingAllStudentsPayment = false;
      state.allStudentsPayment = null;
      state.gettingStudentsInfo = false;
      state.studentsInfo = null;
      state.gettingPaymentFees = false;
      state.paymentFees = null;
      state.updatingFeePayment = false;
      state.gettingFeeMappings = false;
      state.feeMappings = null;
      state.creatingFeeMapping = false;

      // payment Info
      state.SessionId = null;
      state.SemesterId = null;
      state.FeeScheduledId = null;
      state.PaymentMeans = null;
      state.FacultyId = null;
      state.DepartmentId = null;
      state.LevelId = null;
      state.ReportName = null;
      state.StartDate = null;
      state.EndDate = null;
      state.GenderId = null;
      state.AsPdf = false;
      state.PageSize = 30;
      state.PageNumber = 1;
      state.SearchPrams = "";

      // offline upload states
      state.feeDetailsId = null;
      state.personId = null;
      state.bankId = null;
      state.tellerNumber = null;
      state.description = null;
      state.accountNo = null;

      // get students Info states
      state.regNumber = null;

      // payments mapping states
      state.feeScheduleId = null;
      state.paymentInstallmentId = null;
      state.programmeId = null;
      state.departmentId = null;
      state.departmentIds = [];
      state.levelId = null;
      state.sessionId = null;
      state.genderId = null;
      state.semesterId = null;
      state.admisionType = null;
      state.amount = null;

      // verify payment state
      state.reference = null;
      state.gateWay = null;

      // fee states
      state.feeScheduleName = "";
      state.canPayInstallment = true;
    },
    getPaymentsFormDetails: (state, action) => {
      return {
        ...state,
        [action?.payload?.name]: action?.payload?.value,
      };
    },
  },
});

export const {
  setAllPayment,
  setDownloadablePayment,
  setGettingAllPayment,
  setGettingDownloadablePayment,
  setGettingStudentsPaymentInASemester,
  setGettingStudentsPaymentInASession,
  setStudentsPaymentInASemester,
  setStudentsPaymentInASession,
  setFeePaymentSummary,
  setGettingFeePaymentSummary,
  setGettingPaymentSummary,
  setGettingPaymentSummaryChart,
  setPaymentSummary,
  setPaymentSummaryChart,
  setGettingPaymentById,
  setPaymentById,
  setAllStudentsPayment,
  setGettingAllStudentsPayment,
  getPaymentsFormDetails,
  setConfirmingPayment,
  setUploadingOfflinePayment,
  setGettingStudentInfo,
  setStudentInfo,
  setGettingPaymentFees,
  setPaymentFees,
  setUpdatingFeePayment,
  setFeeMappings,
  setGettingFeeMappings,
  setCreatingFeeMapping,
  resetPaymentParams,
  setCreatingFeePayment,
} = paymentSlice.actions;
export const selectPayment = (state: RootState) => state.payment;

export default paymentSlice.reducer;
